import getAxios from 'apiRestConnector';

import { UserInterface } from 'components/models/UserInterfaces';
import { logoutFirebase } from 'utils/firebase';
import { History } from 'history';
import ROL from 'utils/Roles';
import STATUS from 'utils/Status';
import SwalAlert from 'components/ui/alerts/SwalAlert';

const { REACT_APP_BACKEND_URL, REACT_APP_GOOGLE_CLIENT_ID, REACT_APP_RECAPTCHA } = process.env;

interface IProps {
  getStore: () => any;
  getActions: () => any;
   
  setStore: ({}) => void;
}

const token: string = localStorage.getItem('token')
  ? (localStorage.getItem('token') as string)
  : '';
const user: any = localStorage.getItem('user')
  ? JSON.parse(localStorage.getItem('user') as string)
  : {};
const isAuth: boolean = localStorage.getItem('isAuth') === 'true';
const print: string = localStorage.getItem('print')
  ? (localStorage.getItem('print') as string)
  : '';

const getState = ({ getActions, getStore, setStore }: IProps) => {
  return {
    storeAuth: {
      user: {
        name: user.name,
        email: user.email,
        company: user.company,
        imageUrl: user.imageUrl,
        role: user.role,
        status: user.status,
        likeCandidates: user.likeCandidates,
        access_token: user.access_token,
        subscribed: user.subscribed,
        menteeId: user.menteeId,
        _id: user._id,
      },
      token: token,
      isAuth: isAuth,
      clientId: REACT_APP_GOOGLE_CLIENT_ID!,
      print: '0',
      recaptcha: REACT_APP_RECAPTCHA || '',
    },
    actionsAuth: {
      login: (token: string, tokenCaptcha: string, history: History, contextLogout: () => void) => {
        const body = {
          token,
          tokenCaptcha,
        };

        getAxios
          .instance('')
          .post(`${REACT_APP_BACKEND_URL}/users/login`, body)
          .then((response: any) => response.data)
          .then((data: UserInterface) => {
            const { role, status } = data;
            if (role[0] === ROL.USUARIO && status !== STATUS.VERIFICADO) {
              getActions().postMailUser(data);

              history.push('/StatusRequest');
            } else {
              setStore({
                isAuth: true,
                token: token,
                user: data,
              });
              localStorage.setItem('user', JSON.stringify(data));
              localStorage.setItem('isAuth', JSON.stringify(true));
              localStorage.setItem('token', token);
            }
          })
          .catch(({ response }) => {
            if (response) {
              if (response.status === 404) {
                setStore({
                  token: token,
                });
                localStorage.setItem('token', token);
                history.push('/register');
              }
            }
          });
      },
      register: (token: string, company: string, history: History, contextLogout: () => void) => {
        const body = {
          token,
          company,
        };
        getAxios
          .instance('')
          .post(`${REACT_APP_BACKEND_URL}/users/register`, body)
          .then((response: any) => response.data)
          .then((data: UserInterface) => {
            SwalAlert({
              title: 'Registro Exitoso',
              text: 'Hora de un café, debes esperar la autorización del administrador',
              confirmButtonText: 'Volver al inicio',
              icon: 'success',
              showConfirmButton: true,
            }).then((result) => {
              logoutFirebase(history, contextLogout); //esta linea de codigo no esta funcionando, deberia redirigir con esto y tengo que ocupar location.href
              window.location.reload();
            });
          });
      },
      logout: () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('switchCondition');
        localStorage.removeItem('skillControl');
        localStorage.removeItem('_grecaptcha');
        localStorage.setItem('isAuth', JSON.stringify(false));
        setStore({
          user: {
            name: '',
            email: '',
            company: '',
            imageUrl: '',
            role: [],
            status: '',
            likeCandidates: [],
            access_token: '',
            subscribed: '',
            menteeId: '',
            _id: '',
          },
          token: '',
          isAuth: false,
        });
      },
      postMailUser: (data: UserInterface) => {
        const { name, company, email, access_token } = data;
        const body = {
          userName: name,
          userCompany: company,
          userEmail: email,
        };
        getAxios
          .instance('')
          .post(`${REACT_APP_BACKEND_URL}/email/new-user`, body, {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          })
          .then((response: any) => response.data)
          .catch((error) => console.error(error));
      },
      isAuthenticated: () => {
        if (localStorage.getItem('user')) {
          setStore({
            user: JSON.parse(localStorage.getItem('user')!),
            isAuth: JSON.parse(localStorage.getItem('isAuth')!),
            token: localStorage.getItem('token')!,
          });
        }
      },
      updateUserState: () => {
        if (localStorage.getItem('user')) {
          setStore({
            user: JSON.parse(localStorage.getItem('user')!),
          });
        }
      },
      switchPrintOn: () => {
        setStore({
          print: print,
        });
        localStorage.setItem('print', JSON.stringify(1));
      },
      switchPrintOff: () => {
        setStore({
          print: print,
        });
        localStorage.setItem('print', JSON.stringify(0));
      },
    },
  };
};

export default getState;
