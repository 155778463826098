export const filterCardsByAgrupation = (array: any) => {
  const newArray = [];
  const dicPrioridad = {
    Baja: 1,
    Media: 2,
    Alta: 3,
    'Nuevo colaborador': 4,
    'Nuevo colaborador en nuevo cliente': 5,
    Reemplazo: 5,
  };

  for (let i = 0; i < array.length; i++) {
    const includeElementArray = newArray.some(({ element }) => {
      let compareStack;
      let compareRol;
      if (element.nivel === array[i].nivel && element.responsable === array[i].responsable) {
        compareStack =
          array[i].stack &&
          JSON.stringify(Array.isArray(element.stack) ? element.stack.sort() : element.stack) ===
            JSON.stringify(Array.isArray(array[i].stack) ? array[i].stack.sort() : array[i].stack);
        compareRol =
          array[i].rol &&
          JSON.stringify(Array.isArray(element.rol) ? element.rol.sort() : element.rol) ===
            JSON.stringify(Array.isArray(array[i].rol) ? array[i].rol.sort() : array[i].rol);
      }
      return compareStack === compareRol ? (compareStack === true ? true : false) : false;
    });

    if (!includeElementArray) {
      newArray.push({
        element: array[i],
        number: 1,
        prioridad: array[i].prioridad,
        responsable: array[i].responsable,
      });
    } else {
      newArray.forEach((elem) => {
        if (
          elem.element.nivel === array[i].nivel &&
          JSON.stringify(
            Array.isArray(elem.element.rol) ? elem.element.rol.sort() : elem.element.rol
          ) === JSON.stringify(Array.isArray(array[i].rol) ? array[i].rol.sort() : array[i].rol) &&
          elem.responsable === array[i].responsable
        ) {
          elem.number = elem.number + 1;

          if (dicPrioridad[elem.prioridad] < dicPrioridad[array[i].prioridad]) {
            elem.prioridad = array[i].prioridad;
          }
        }
      });
    }
  }
  return newArray;
};
