import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';
import { useCandidateFinder } from '../CandidateFinderContext';

interface ValueSelectProps {
  name: string;
  label: string;
}
const ValueSelect = ({ label, name }: ValueSelectProps) => {
  const { control, handleSubmit } = useFormContext();
  const { handleRequest } = useCandidateFinder();

  return (
    <FormControl sx={{ minWidth: 120 }} size='small'>
      <InputLabel>{label}</InputLabel>
      <Controller
        {...{ control, name }}
        render={({ field }) => (
          <Select
            sx={{ height: '32px' }}
            {...{ ...field, label }}
            onChange={(e) => {
              field.onChange(e);
              handleSubmit(handleRequest)();
            }}
          >
            {[0.5, 0.6, 0.7, 0.8, 0.9].map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  );
};

export default ValueSelect;
