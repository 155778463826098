import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  Box,
  IconButton,
  InputAdornment,
  Paper,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { useCandidateFinder } from '../CandidateFinderContext';
import { zodResolver } from '@hookform/resolvers/zod';
import { defaultValues, FormData, formSchema } from '../types';
import ValueSelect from './ValueSelect';

const SearchForm: React.FC = () => {
  const { handleRequest, jsonTagsDataSearch } = useCandidateFinder();
  const [showJsonData, setShowJsonData] = useState(false);

  const methods = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  const { handleSubmit, control } = methods;

  const onKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(handleRequest)();
    }
  };

  const handleToggleJsonView = () => {
    setShowJsonData((prev) => !prev);
  };

  return (
    <FormProvider {...methods}>
      <Box
        component='form'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          flexWrap: 'wrap',
          alignItems: 'center',
          maxWidth: 700,
          margin: '0 auto 2rem',
        }}
      >
        <Controller
          name='searchQuery'
          {...{ control }}
          render={({ field }) => (
            <TextField
              {...{ ...field, onKeyPress }}
              variant='outlined'
              placeholder='Buscar candidatos...'
              size='small'
              sx={{ width: '100%', maxWidth: 700 }}
              multiline
              rows={3}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton edge='end' onClick={handleSubmit(handleRequest)}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '1rem',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Switch
              checked={showJsonData}
              onChange={handleToggleJsonView}
              disabled={!jsonTagsDataSearch}
            />
            <Typography
              variant='body2'
              color={!jsonTagsDataSearch ? 'text.disabled' : 'text.primary'}
            >
              Ver búsqueda
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', gap: 2 }}>
            <ValueSelect name='match' label='Match' />
            <ValueSelect name='score' label='Score' />
          </Box>
        </Box>

        {showJsonData && jsonTagsDataSearch && (
          <Paper
            elevation={3}
            sx={{
              width: '100%',
              bgcolor: 'black',
              color: '#4caf50',
              p: 2,
              mt: 2,
              borderRadius: 1,
              overflow: 'auto',
              maxHeight: '300px',
              fontFamily: 'monospace',
              '&::-webkit-scrollbar': {
                width: '8px',
                height: '8px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                borderRadius: '4px',
              },
            }}
          >
            <pre style={{ margin: 0 }}>{JSON.stringify(jsonTagsDataSearch, null, 2)}</pre>
          </Paper>
        )}
      </Box>
    </FormProvider>
  );
};

export default SearchForm;
