import React, { useCallback, useState } from 'react';
import {
  Box,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { Description, LinkedIn, WhatsApp } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { RowData } from '../types';
import { useCandidateFinder } from '../CandidateFinderContext';
import { ActionColumnSC } from '../styles';
import IconButtonTooltip from '../../../ui/buttons/iconButtonTooltip/IconButtonTooltip';
import ContactModal, { ContactProps } from '../../../ui/phone/ContactModal';
import { Link as RouterLink } from 'react-router-dom';

const ResultsTable: React.FC = () => {
  const { data, order, orderBy, handleRequestSort, isLoading } = useCandidateFinder();
  const [contact, setContact] = useState<ContactProps>();

  const createSortHandler = (property: keyof RowData) => () => {
    handleRequestSort(property);
  };

  const handleContactClick = useCallback((contact: ContactProps) => {
    setContact({ name: contact.name, phone: contact.phone });
  }, []);

  const handleCloseContactModal = useCallback(() => {
    setContact(undefined);
  }, []);

  return (
    <>
      <TableContainer component={Paper} sx={{ bgcolor: '#1e1e2f' }}>
        <Table>
          <TableHead sx={{ backgroundColor: '#323248' }}>
            <TableRow>
              {['match', 'score', 'proporción', 'nombre', 'país', 'email'].map((headCell) => (
                <TableCell
                  key={headCell}
                  sx={{ color: '#fff' }}
                  sortDirection={orderBy === headCell ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell}
                    direction={orderBy === headCell ? order : 'asc'}
                    onClick={createSortHandler(headCell as keyof RowData)}
                  >
                    {headCell.charAt(0).toUpperCase() + headCell.slice(1)}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell sx={{ color: '#fff' }}>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              Array.from(new Array(5)).map((_, index) => (
                <TableRow key={index}>
                  {Array.from(new Array(10)).map((_, cellIndex) => (
                    <TableCell key={cellIndex}>
                      <Skeleton variant='text' width='100%' height={30} />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={10} sx={{ color: '#fff', textAlign: 'center' }}>
                  No se encontraron resultados.
                </TableCell>
              </TableRow>
            ) : (
              data.map((item, index) => (
                <TableRow key={index} hover sx={{ '&:hover': { backgroundColor: '#3b3b54' } }}>
                  <TableCell sx={{ color: '#fff' }}>{item.match}</TableCell>
                  <TableCell sx={{ color: '#fff' }}>{item.score}</TableCell>
                  <TableCell sx={{ color: '#fff' }}>{item.proporcion}</TableCell>
                  <TableCell sx={{ color: '#fff' }}>{item.nombre}</TableCell>
                  <TableCell sx={{ color: '#fff' }}>{item.pais}</TableCell>
                  <TableCell sx={{ color: '#fff' }}>{item.email}</TableCell>
                  <TableCell sx={{ color: '#fff' }}>
                    <ActionColumnSC>
                      <IconButtonTooltip
                        component={RouterLink}
                        to={`/candidate/${item.telefono}/edit`}
                        title='Perfil HunterApp'
                        icon={VisibilityIcon}
                      />
                      <IconButtonTooltip
                        href={item.cv}
                        target='_blank'
                        title='CV'
                        icon={Description}
                      />
                      {item.linkedin ? (
                        <IconButtonTooltip
                          href={item.linkedin}
                          target='_blank'
                          title='LinkedIn'
                          icon={LinkedIn}
                        />
                      ) : null}

                      <IconButtonTooltip
                        onClick={handleContactClick.bind(null, item.contact)}
                        title='WhatsApp'
                        icon={WhatsApp}
                      >
                        <Box
                          component='img'
                          src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${item.contact.countryCode}.svg`}
                          alt='País'
                          sx={{ width: '20px' }}
                        />
                      </IconButtonTooltip>
                    </ActionColumnSC>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <ContactModal
        open={contact !== undefined}
        onClose={handleCloseContactModal}
        name={contact?.name}
        phone={contact?.phone}
      />
    </>
  );
};

export default ResultsTable;
