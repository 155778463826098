import React, { useState, useEffect, FC, useContext, useCallback, useRef } from 'react';
import { DateTime } from 'luxon';
import { DatePicker } from '@mui/x-date-pickers';
import { WorkflowInterface } from 'components/models/WorkFlowInterfaces';
import {
  DatosInterface,
  DatosTemplateInterface,
} from 'components/models/PersonalInformationInterfaces';
import { TemplateInterface } from 'components/models/CandidateInterfaces';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import getAxios from 'apiRestConnector';
import RequestComponent from '../newCandidate/requestComponent/RequestComponent';
import {
  Autocomplete,
  Box,
  Chip,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField,
  Typography,
  FormGroup,
  Button,
  Checkbox,
  FormControlLabel,
  FormControl,
  IconButton,
} from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useTheme } from '@mui/material/styles';
import SwalAlert from 'components/ui/alerts/SwalAlert';
import { MuiTelInput } from 'mui-tel-input';

interface skillScore {
  skill: string;
  score: string;
}

interface IProps {
  setFilteredCandidates: (f: TemplateInterface[]) => void;
  setFilterCandidateStatus: (f: string[]) => void;
  filterCandidateStatus: string[];
  setLoading: (l: boolean) => void;
  filterActivo: string;
  setFilterActivo: (f: string) => void;
  filterCandidateDesde: Date;
  filterCandidateHasta: Date;
  setFilterCandidateDesde: (d: Date) => void;
  setFilterCandidateHasta: (d: Date) => void;
  rol: string[];
  setRol: (d: string[]) => void;
  nameFilter: string;
  setNameFilter: (n: string) => void;
  requestCandidate: number;
  requestsIds: number[];
  setRequestsIds: (d: number[]) => void;
  filterRequests: string[];
  setFilterRequests: (d: string[]) => void;
  switchConditionRequest: boolean;
  filterPhoneNumber: string;
  setPhoneNumber: (d: string) => void;
}

const ListCandidateFilter: FC<IProps> = ({
  setFilteredCandidates,
  setFilterCandidateStatus,
  filterCandidateStatus,
  setLoading,
  filterActivo,
  setFilterActivo,
  filterCandidateDesde,
  filterCandidateHasta,
  setFilterCandidateDesde,
  setFilterCandidateHasta,
  rol,
  setRol,
  nameFilter,
  setNameFilter,
  requestCandidate,
  requestsIds,
  setRequestsIds,
  filterRequests,
  setFilterRequests,
  switchConditionRequest,
  filterPhoneNumber,
  setPhoneNumber,
}) => {
  const { storeAuth } = useContext(AuthContext)!;
  const { access_token } = storeAuth.user;
  const [template, setTemplate] = useState<TemplateInterface>({});
  const [filterCandidateSkill, setFilterCandidateSkill] = useState<string[]>(Array);
  const [filterCandidateLevel, setFilterCandidateLevel] = useState<string[]>(Array);

  const [excluded, setExcluded] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(false);
  const [candidatesByRequest, setCandidatesByRequest] = useState<number[]>([]);

  const [recruiters, setRecruiters] = useState<string[]>(Array);
  const [filteredRecruiters, setFilteredRecruiters] = useState<string[]>(Array);
  const { REACT_APP_BACKEND_URL } = process.env;
  const [skillControl, setSkillControl] = useState<skillScore[]>([
    {
      skill: '',
      score: '',
    },
  ]);

  const [roles, setRoles] = useState<string[]>([]);

  const [switchCondition, setSwitchCondition] = useState<boolean>(true);

  const hSearch = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    localStorage.setItem('requestFilter', JSON.stringify(filterRequests));
  }, [filterRequests]);

  useEffect(() => {
    if (candidatesByRequest.length !== 0 && hSearch.current) {
      hSearch.current.click();
    }
  }, [candidatesByRequest]);

  useEffect(() => {
    if (nameFilter.length !== 0 && hSearch.current) {
      hSearch.current.click();
    }
  }, [nameFilter]);

  useEffect(() => {
    if (requestCandidate) {
      setLoading(true);
      getAxios
        .instance('')
        .get(`${REACT_APP_BACKEND_URL}/personas/asociacion/${requestCandidate}`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then((result: any) => {
          if (result.data.length === 0) {
            setFilteredCandidates([]);
            setLoading(false);
          } else {
            setCandidatesByRequest(Array.from(new Set(result.data)));
          }
        });
    }
  }, [requestCandidate, access_token, REACT_APP_BACKEND_URL, setFilteredCandidates, setLoading]);

  const handleCheckboxFilters = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value, name, id } = e.target;

    if (checked && id === 'activo') {
      setFilterActivo('activo');
      localStorage.setItem('filterActivo', 'activo');
    } else if (checked && id === 'inactivo') {
      setFilterActivo('inactivo');
      localStorage.setItem('filterActivo', 'inactivo');
    } else if (checked && id === 'todos') {
      setFilterActivo('todos');
      localStorage.setItem('filterActivo', 'todos');
    }

    if (name === 'excluidos') {
      if (checked) {
        setExcluded(true);
      } else {
        setExcluded(false);
      }
    }

    if (name === 'skill') {
      checked && setFilterCandidateSkill([...filterCandidateSkill, value]);
      !checked &&
        setFilterCandidateSkill(filterCandidateSkill.filter((filter) => filter !== value));
    }

    if (name === 'recrutier') {
      if (checked) {
        const list = [...filteredRecruiters, value];
        setFilteredRecruiters(list);

        localStorage.setItem('filteredRecruiters', JSON.stringify(list));
      } else {
        const list = filteredRecruiters.filter((filter) => filter !== value);
        setFilteredRecruiters(list);

        localStorage.setItem('filteredRecruiters', JSON.stringify(list));
      }
    }

    if (name === 'level') {
      if (checked) {
        const list = [...filterCandidateLevel, value];
        setFilterCandidateLevel(list);
        localStorage.setItem('filterCandidateLevel', JSON.stringify(list));
      } else {
        const list = filterCandidateLevel.filter((filter) => filter !== value);
        setFilterCandidateLevel(list);
        localStorage.setItem('filterCandidateLevel', JSON.stringify(list));
      }
    }

    if (name === 'estado') {
      const valueEstado = value;
      if (checked) {
        const list = [...filterCandidateStatus, valueEstado];
        setFilterCandidateStatus(list);
        localStorage.setItem('filterCandidateStatus', JSON.stringify(list));
      } else {
        const list = filterCandidateStatus.filter((filter) => filter !== valueEstado);
        setFilterCandidateStatus(list);
        localStorage.setItem('filterCandidateStatus', JSON.stringify(list));
      }
    }
  };

  const handleSearch = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setLoading(true);
    let url = '';
    const phoneNumber = filterPhoneNumber.replace(/[^0-9]/g, '');
    if (nameFilter) {
      url = `${REACT_APP_BACKEND_URL}/personas/filter?name=${nameFilter}`;
    } else if (candidatesByRequest.length !== 0) {
      url = `${REACT_APP_BACKEND_URL}/personas/filter?phones=${candidatesByRequest}`;
    } else {
      url = `${REACT_APP_BACKEND_URL}/personas/filter?active=${filterActivo}&state=${filterCandidateStatus.join(
        ','
      )}&dateStart=${filterCandidateDesde?.toISOString()}&dateEnd=${filterCandidateHasta?.toISOString()}&level=${filterCandidateLevel}&exclusion=${excluded}&recruiter=${filteredRecruiters}&role=${rol}&request=${requestsIds}&phoneNumber=${phoneNumber}`;
    }
    getAxios
      .instance('')
      .get(url, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((result: any) => result.data)
      .then((entries: any) => {
        setFilteredCandidates(entries);
        setCandidatesByRequest([]);
        setNameFilter('');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSkill = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (skillControl.every((sc: skillScore) => sc.skill !== '' && sc.score !== '')) {
      const skills = skillControl.map((sc: skillScore) => `skill=${sc.skill}&`);
      const score = skillControl.map((sc: skillScore) => `nota=${sc.score}&`);
      setLoading(true);
      let urlBackend = `${REACT_APP_BACKEND_URL}/personas/skills?${skillControl.map(
        (sc: skillScore) => `skill=${sc.skill}`
      )}&${skillControl.map((sc: skillScore) => `nota=${sc.score}`)}`;

      if (skills.length === 1) {
        urlBackend = `${REACT_APP_BACKEND_URL}/personas/skills?${skillControl.map(
          (sc: skillScore) => `skill=${sc.skill}`
        )}&${skillControl.map((sc: skillScore) => `nota=${sc.score}`)}`;
      } else {
        urlBackend = `${REACT_APP_BACKEND_URL}/personas/skills?${skills
          .toString()
          .replaceAll(',', '')}${score.toString().replaceAll(',', '')}operator=${
          switchCondition ? 'AND' : 'OR'
        }`;
      }

      getAxios
        .instance('')
        .get(urlBackend, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then((result: any) => result.data)
        .then((entry) => {
          setFilteredCandidates(entry);
          setLoading(false);
        })
        .catch((error) => {
          // Error 😨

          setFilteredCandidates([]);
          setLoading(false);
        });
    } else {
      SwalAlert({
        icon: 'warning',
        text: `Debe ingresar llenar todos los campos de habilidad y nota.`,

        confirmButtonText: 'Ok',
      });
    }
  };

  const generateFilterStructure = useCallback(() => {
    const isFilter = () => {
      if (localStorage.getItem('filteredRecruiters')) {
        setFilteredRecruiters(JSON.parse(localStorage.getItem('filteredRecruiters')!));
      }

      if (localStorage.getItem('filterCandidateStatus')) {
        setFilterCandidateStatus(JSON.parse(localStorage.getItem('filterCandidateStatus')!));
      }
      if (localStorage.getItem('filterCandidateLevel')) {
        setFilterCandidateLevel(JSON.parse(localStorage.getItem('filterCandidateLevel')!));
      }
      if (localStorage.getItem('filterActivo')) {
        setFilterActivo(localStorage.getItem('filterActivo')!);
      }
      if (localStorage.getItem('rol')) {
        setRol(JSON.parse(localStorage.getItem('rol')!));
      }
      if (localStorage.getItem('requestFilter')) {
        setFilterRequests(JSON.parse(localStorage.getItem('requestFilter')));
      }
    };

    if (JSON.stringify(template) === '{}') {
      const urlBackend = `${REACT_APP_BACKEND_URL}/templates/skills/`;
      getAxios
        .instance('')
        .get(urlBackend, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then((result: any) => result.data)
        .then((templatesData: any) => {
          setTemplate(templatesData);
          templatesData.datos.forEach((dato: DatosInterface) => {
            if (
              dato.nombre === 'Rol' &&
              Array.isArray(dato.valor) &&
              typeof dato.valor === 'object'
            ) {
              setRoles(dato.valor);
            }
          });
        })
        .then(() => {
          isFilter();
        })
        .then(() => setLoad(true));
    }
  }, [
    REACT_APP_BACKEND_URL,
    access_token,
    template,
    setFilterActivo,
    setFilterCandidateStatus,
    setRol,
    setFilterRequests,
  ]);

  useEffect(() => {
    generateFilterStructure();
  }, [generateFilterStructure]);

  const getRecruiters = useCallback(async () => {
    const allRecruiters: string[] = [];
    const urlBackend = `${REACT_APP_BACKEND_URL}/users`;

    await getAxios
      .instance('')
      .get(urlBackend, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((result: any) => result.data)
      .then((data) => {
        data.map((res: any) => allRecruiters.push(res.name));
      });
    //Se devuelve un arreglo de reclutadores
    return allRecruiters;
  }, [REACT_APP_BACKEND_URL, access_token]);

  useEffect(() => {
    async function saveRecruiters() {
      await getRecruiters().then((data) => {
        setRecruiters(data);
      });
    }

    saveRecruiters();
  }, [getRecruiters]);

  useEffect(() => {
    const formatDateForFilterCandidateDesde = () => {
      const date = new Date();
      date.setMonth(date.getMonth() - 3);
      const formatDateString = `${
        date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
      }/${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}/${date.getFullYear()}`;
      setFilterCandidateDesde(new Date(formatDateString));
    };
    formatDateForFilterCandidateDesde();
    document.title = `WiTI - LevelUp`;
  }, [setFilterCandidateDesde]);

  useEffect(() => {
    if (load && hSearch.current) {
      hSearch.current.click();
    }
  }, [load]);

  useEffect(() => {
    const skill = localStorage.getItem('skillControl');
    if (skill) {
      setSkillControl(JSON.parse(skill));
    }

    const switchh = localStorage.getItem('switchCondition');
    if (switchh) {
      setSwitchCondition(JSON.parse(switchh));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('skillControl', JSON.stringify(skillControl));
  }, [skillControl]);

  useEffect(() => {
    localStorage.setItem('switchCondition', JSON.stringify(switchCondition));
  }, [switchCondition]);

  const add = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setSkillControl((previusValue) => [...previusValue, { skill: '', score: '' }]);
  };

  const sustract = () => {
    const sc = skillControl.slice(0, skillControl.length - 1);

    setSkillControl(sc);
  };

  const theme = useTheme();

  return (
    <FormControl
      sx={{
        display: 'grid',
        margin: '0 1rem',
        gap: '1rem',
        gridTemplateColumns: 'repeat(1, 1fr)',
        gridTemplateAreas: `
        'filtroFecha'
        'filtroPhoneNumber'
        'filtroEstado'
        'filtroActividad'
        'filtroNivel'
        'filtroSolicitud'
        'filtroReclutador'
        'filtroRol'
        'buttonAplicarFiltros'
        'filtroHabilidad'
      `,
        [theme.breakpoints.up('md')]: {
          gridTemplateColumns: 'repeat(3, 1fr)',
          gridTemplateAreas: `
          'filtroFecha filtroPhoneNumber filtroEstado'
          'filtroActividad filtroNivel filtroNivel'
          'filtroSolicitud filtroReclutador filtroReclutador'
          'filtroRol buttonAplicarFiltros buttonAplicarFiltros'
          'buttonAplicarFiltros filtroHabilidad filtroHabilidad'
          'filtroHabilidad filtroHabilidad filtroHabilidad'
        `,
        },
        [theme.breakpoints.up('lg')]: {
          gridTemplateColumns: 'repeat(5, 1fr)',
          gridTemplateAreas: `
          'filtroFecha filtroPhoneNumber filtroEstado filtroActividad filtroNivel'
          'filtroSolicitud filtroSolicitud filtroReclutador filtroReclutador filtroRol'
          'buttonAplicarFiltros buttonAplicarFiltros buttonAplicarFiltros buttonAplicarFiltros buttonAplicarFiltros'
          'filtroHabilidad filtroHabilidad filtroHabilidad filtroHabilidad filtroHabilidad'
        `,
        },
      }}
    >
      <FormGroup sx={{ display: 'wrap', flexWrap: 'wrap', gap: '1rem', gridArea: 'filtroFecha' }}>
        <h6> Filtros por Fecha </h6>
        <DatePicker
          key='datePickerDesde'
          maxDate={
            filterCandidateHasta ? DateTime.fromJSDate(filterCandidateHasta) : DateTime.local()
          }
          value={DateTime.fromJSDate(filterCandidateDesde)}
          onChange={(date: DateTime) => {
            const dateInput = date.startOf('day').toJSDate();
            setFilterCandidateDesde(dateInput);
          }}
        />
        <DatePicker
          key='datePickerHasta'
          minDate={DateTime.fromJSDate(filterCandidateDesde)}
          maxDate={DateTime.local()}
          value={DateTime.fromJSDate(filterCandidateHasta)}
          onChange={(date: DateTime) => {
            const dateInput = date.endOf('day').toJSDate();
            setFilterCandidateHasta(dateInput);
          }}
        />
      </FormGroup>
      <FormGroup
        sx={{ display: 'wrap', flexWrap: 'wrap', gap: '1rem', gridArea: 'filtroPhoneNumber' }}
      >
        <h6>Filtro por Número de telefono</h6>
        <MuiTelInput
          sx={{ width: '100%' }}
          label='Teléfono'
          variant='outlined'
          value={filterPhoneNumber}
          onChange={(e: any) => {
            if (e.length > 0) {
              if (e[0] !== '+') {
                e = `+${e}`;
              }
            }
            setPhoneNumber(e);
          }}
        />
      </FormGroup>

      <FormGroup sx={{ display: 'wrap', flexWrap: 'wrap', gridArea: 'filtroEstado' }}>
        <h6> Filtro por Estado </h6>
        {template.workflow?.map(({ nombre }: WorkflowInterface, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={filterCandidateStatus.includes(nombre)}
                key={nombre}
                name='estado'
                value={nombre}
                onChange={handleCheckboxFilters}
              />
            }
            label={nombre}
          />
        ))}
      </FormGroup>
      <FormGroup sx={{ display: 'wrap', flexWrap: 'wrap', gridArea: 'filtroActividad' }}>
        <h6> Filtro por Actividad</h6>

        <RadioGroup
          aria-labelledby='radio-buttons-group-label'
          name='radio-buttons-group'
          value={filterActivo}
          onChange={(event) => setFilterActivo(event.target.value)}
        >
          <FormControlLabel value='activo' control={<Radio />} label='Activo' />
          <FormControlLabel value='inactivo' control={<Radio />} label='Inactivo' />
          <FormControlLabel value='todos' control={<Radio />} label='Todos' />
        </RadioGroup>
      </FormGroup>
      <FormGroup sx={{ display: 'wrap', flexWrap: 'wrap', gridArea: 'filtroNivel' }}>
        <h6> Filtro por Nivel </h6>
        {template.datos?.map((dato: DatosTemplateInterface, index: number) => (
          <Box sx={{ display: 'flex', flexDirection: 'column' }} key={index}>
            {dato.nombre === 'Nivel' &&
              dato.valor.map((nivel: string, index: number) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      onChange={handleCheckboxFilters}
                      id={`Level${index}`}
                      name='level'
                      value={nivel}
                      checked={filterCandidateLevel.includes(nivel)}
                    />
                  }
                  label={nivel}
                />
              ))}
          </Box>
        ))}
      </FormGroup>
      {false && (
        <FormGroup
          sx={{ display: 'wrap', flexWrap: 'wrap', gridArea: 'filtroExclusion', padding: '0 5rem' }}
        >
          <h6> Filtro por Exclusion</h6>
          {
            <FormControlLabel
              control={
                <Checkbox
                  key='excluded'
                  onChange={handleCheckboxFilters}
                  id='excluded'
                  name='excluidos'
                  value='Excluidos'
                  checked={excluded}
                />
              }
              label='Excluidos'
            />
          }
        </FormGroup>
      )}
      <FormGroup sx={{ gridArea: 'filtroSolicitud' }}>
        <RequestComponent
          estado={switchConditionRequest}
          solicitudCandidate={requestsIds}
          setSolicitudCandidate={setRequestsIds}
        />
      </FormGroup>
      <FormGroup sx={{ display: 'wrap', flexWrap: 'wrap', gridArea: 'filtroReclutador' }}>
        <Autocomplete
          multiple
          id='recruiters'
          options={recruiters.sort()}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          value={filteredRecruiters}
          filterSelectedOptions
          freeSolo
          onChange={(event, newValue) => {
            if (typeof newValue === 'string') {
              setFilteredRecruiters(newValue);
              localStorage.setItem('filteredRecruiters', newValue);
            } else if (newValue.length > 0) {
              setFilteredRecruiters(newValue.map((v: string) => v));
              localStorage.setItem('filteredRecruiters', JSON.stringify(newValue));
            } else {
              setFilteredRecruiters(newValue.map((v: any) => v));
              localStorage.setItem('filteredRecruiters', JSON.stringify(newValue));
            }
          }}
          renderInput={(params) => <TextField {...params} label='Filtro por reclutador' />}
          renderTags={(value: string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip {...getTagProps({ index })} key={option} variant='outlined' label={option} />
            ))
          }
        />
      </FormGroup>
      <FormGroup sx={{ display: 'wrap', flexWrap: 'wrap', gridArea: 'filtroRol' }}>
        <Autocomplete
          multiple
          id='rol'
          freeSolo
          options={roles}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          value={rol}
          filterSelectedOptions
          onChange={(event, newValue) => {
            if (typeof newValue === 'string') {
              setRol(newValue);
              localStorage.setItem('rol', newValue);
            } else if (newValue.length > 0) {
              setRol(newValue.map((v: string) => v));
              localStorage.setItem('rol', JSON.stringify(newValue));
            } else {
              setRol(newValue.map((v: any) => v));
              localStorage.setItem('rol', JSON.stringify(newValue));
            }
          }}
          renderInput={(params) => <TextField {...params} label='Filtro por rol' />}
          renderTags={(value: string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip {...getTagProps({ index })} key={option} variant='outlined' label={option} />
            ))
          }
        />
      </FormGroup>
      <Button
        sx={{ my: 1, gridArea: 'buttonAplicarFiltros', margin: '0 auto', display: 'block' }}
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          setNameFilter('');
          handleSearch(e);
        }}
        id='hsearch'
        ref={hSearch}
        variant='contained'
      >
        Aplicar filtros
      </Button>
      <FormGroup sx={{ display: 'flex', flexWrap: 'wrap', gridArea: 'filtroHabilidad' }}>
        <h6 style={{ textAlign: 'center' }}> Filtro por habilidad</h6>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <Stack direction='row' spacing={1} alignItems='center' key='stackKey1'>
            <Typography key='typographyKey1'>OR</Typography>
            <Switch
              key='switchKey1'
              onChange={() => setSwitchCondition(!switchCondition)}
              className='switch-subskill'
              checked={switchCondition}
            />
            <Typography key='typographyKey2'>AND</Typography>
          </Stack>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignSelf: 'center', gap: '0 1rem' }}>
            <IconButton color='primary' title='Agregar habilidad' onClick={add}>
              <AddCircleIcon />
            </IconButton>
            <IconButton color='primary' title='Eliminar última habilidad' onClick={sustract}>
              <RemoveCircleIcon />
            </IconButton>
          </Box>
          {skillControl &&
            skillControl.map((sc: skillScore, index: number) => {
              return (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    m: 1,
                  }}
                >
                  <Stack
                    key={`skillControl-${index}`}
                    direction='row'
                    spacing={2}
                    alignItems='center'
                  >
                    <TextField
                      id={`skillCand${index}`}
                      value={sc.skill}
                      onChange={(e) => {
                        const sk = skillControl.map((s: skillScore, is: number) => {
                          if (index === is) {
                            return { skill: e.target.value, score: s.score } as skillScore;
                          }
                          return { skill: s.skill, score: s.score } as skillScore;
                        });
                        setSkillControl(sk);
                      }}
                      label='Ingrese habilidad'
                      variant='outlined'
                      size='small'
                    />
                    <TextField
                      id={`scoreCand${index}`}
                      value={sc.score}
                      onChange={(e) => {
                        const sk = skillControl.map((s: skillScore, is: number) => {
                          if (index === is) {
                            return { skill: s.skill, score: e.target.value } as skillScore;
                          }
                          return { skill: s.skill, score: s.score } as skillScore;
                        });
                        setSkillControl(sk);
                      }}
                      label='Ingrese nota'
                      variant='outlined'
                      size='small'
                    />
                  </Stack>
                </Box>
              );
            })}
        </Box>
        <Button onClick={handleSkill} sx={{ my: 1, alignSelf: 'center' }} variant='contained'>
          Aplicar filtro por habilidad
        </Button>
      </FormGroup>
    </FormControl>
  );
};

export default ListCandidateFilter;
