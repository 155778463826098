import getAxios from 'apiRestConnector';
import { CandidateInterface } from 'components/models/CandidateInterfaces';
import { DatosInterface } from 'components/models/PersonalInformationInterfaces';
import { AlertColor } from '@mui/material';

import React, { useContext, useEffect, useState, useRef } from 'react';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import PersonalInfoHeadItem from './personalInfoItem/PersonalInfoHeadItem';
import PersonalFieldForm from './personalFieldForm/PersonalFieldForm';
import { getDifference } from '../../../../helpers/helpers';
import { Box } from '@mui/material';

interface Props {
  dataCandidate: CandidateInterface;
  solicitudCandidate: number[];
  celular: number;
  onChangeData: (data: CandidateInterface) => void;
  setTelefono: (telefono: string) => void;
  setEmail: (email: string) => void;
  setNombre: (nombre: string) => void;
}

const { REACT_APP_BACKEND_URL } = process.env;
const URL_BACKEND = `${REACT_APP_BACKEND_URL}/templates/skills/`;
const PersonalInfoComp: React.FC<Props> = ({ dataCandidate, celular, onChangeData }) => {
  const { storeAuth } = useContext(AuthContext)!;
  const { access_token } = storeAuth.user;
  const [skeleton, setSkeleton] = useState<CandidateInterface>(Object);
  const initialData = useRef(dataCandidate);
  const [message] = useState<{
    message: string;
    severity: AlertColor;
  }>({
    message: '',
    severity: 'error',
  });
  const [changeValidate, setChangeValidate] = React.useState([
    {
      name: '',
      validate: false,
    },
  ]);
  const [uploadFields, setUploadFields] = useState<{}>({});

  useEffect(() => {
    getAxios
      .instance('')
      .get(URL_BACKEND, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((result: any) => result.data)
      .then((templatesData: any) => {
        setSkeleton(templatesData);
      })
      .catch((error) => {
        console.error('error:', error.message);
      });
  }, [access_token]);

  useEffect(() => {
    if (skeleton.datos?.length) {
      const differentElements = getDifference(skeleton.datos, initialData.current.datos, 'nombre');
      const result = structuredClone(initialData.current);
      onChangeData({ ...result, datos: [...result.datos, ...differentElements] });
    }
  }, [skeleton.datos, onChangeData]);

  const inputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
    const result = structuredClone(dataCandidate);
    result.datos = dataCandidate.datos?.map(({ nombre, privado, valor }: any) => {
      if (!privado) {
        privado = false;
      }
      const type = skeleton.datos?.find(({ nombre: n }: DatosInterface) => n === nombre)?.type;
      if (name === nombre && !['Nombre', 'Correo'].includes(nombre)) {
        return { nombre, privado, valor: value, ...(type && { type }) };
      }
      return { nombre, privado, valor, ...(type && { type }) };
    });
    console.log(result);

    onChangeData(result);
  };

  const onAutocompleteChange = (nombre: string) => (_event, newValue) => {
    //en cada campo muestra [] para que no se desplieguen todas las opciones al no estar seleccionadas.
    if (changeValidate.some((valid) => valid.name === nombre)) {
      setChangeValidate((prevState) =>
        prevState.map((validate) => {
          if (validate.name === nombre) {
            return { ...validate, validate: true };
          }
          return validate;
        })
      );
    } else {
      setChangeValidate((prevState) => [...prevState, { name: nombre, validate: true }]);
    }

    const result = structuredClone(dataCandidate);
    result.datos = result.datos.map((data: DatosInterface) => {
      if (
        nombre === data.nombre &&
        (nombre === 'Moneda Renta Actual' || nombre === 'Moneda Pretenciones de Renta')
      ) {
        return {
          nombre: data.nombre,
          privado: data.privado,
          valor: newValue.typeCoin,
        } as DatosInterface;
      }

      if (data.nombre === nombre) {
        return {
          nombre: data.nombre,
          privado: data.privado,
          valor: newValue,
        } as DatosInterface;
      }

      return data;
    });
    onChangeData(result);
  };

  const handleFiles = async ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const name = target.name;
      const auxTemplate = structuredClone(dataCandidate);

      if (typeof target.files !== 'object' || target.files === null) {
        return;
      }

      const files = target.files;
      if (!files.length) return;
      if (
        ![
          'application/pdf',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ].includes(files[0].type)
      ) {
        alert('El archivo debe ser un PDF o un documento de Word.');
        return;
      }

      const baseUrl = `${REACT_APP_BACKEND_URL}/personas/${celular}/file/${name}`;
      const file = new FormData();
      file.append('file', files[0]);
      const response = await fetch(baseUrl, {
        method: 'POST',
        body: file,
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      const result = await response.json();

      auxTemplate.datos = dataCandidate.datos.map((data: DatosInterface) => {
        if (data.nombre === name) {
          const type = skeleton.datos?.find(
            ({ nombre: n }: DatosInterface) => n === data.nombre
          )?.type;
          setUploadFields((prevState) => ({ ...prevState, [name]: result.url }));
          return { ...data, valor: result.url, ...(type && { type }) };
        }
        return data;
      });
      onChangeData(auxTemplate);
    } catch (error) {}
  };

  //Función para el archivo adjunto
  const handleLink = (urls: string[]) => {
    const urlString = urls.toString();
    if (urlString === '') return;

    try {
      const url = new URL(urlString);
      window.open(url.href);
    } catch (error) {
      console.error('La URL proporcionada no es válida:', urlString);
    }
  };

  return (
    <Box>
      {skeleton.datos?.map((datos: DatosInterface, index: number) => (
        <PersonalInfoHeadItem key={index + datos.nombre} isPrivate={datos?.privado ?? false}>
          <PersonalFieldForm
            {...{ index, handleFiles, handleLink, celular }}
            message={message}
            onChangeInput={inputChange}
            data={datos}
            uploadFields={uploadFields}
            initial={initialData.current.datos}
            changeValidate={changeValidate}
            templateData={dataCandidate.datos}
            onAutocompleteChange={onAutocompleteChange(datos.nombre)}
          />
        </PersonalInfoHeadItem>
      ))}
    </Box>
  );
};

export default PersonalInfoComp;
