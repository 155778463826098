import { useQuery } from '@tanstack/react-query';
import getAxios from 'apiRestConnector';
import { SkillsInterface } from 'components/models/SkillsInterfaces';
import { DatosInterface } from 'components/models/PersonalInformationInterfaces';

interface Candidate {
  nombre: string;
  telefono: string;
  datos: DatosInterface[];
  skills: SkillsInterface[];
  competencias: any[];
  footer: any[];
  profileImgUrl?: string;
}

export const usePublicView = (hashCandidate: string) => {

  const axiosInstance = getAxios.instance("access_token");

  const candidateQuery = useQuery({
    queryKey: ['candidate', hashCandidate],
    queryFn: async () => {
      const { data } = await axiosInstance.get(`/personas/public/view/${hashCandidate}`);
      const transformedData = {
        ...data,
        datos: data.datos.map((dato: any) => ({
          ...dato,
          privado: false
        }))
      };
      return transformedData as Candidate;
    }
  });

  return {
    candidate: candidateQuery.data,
    isLoading: candidateQuery.isLoading,
    error: candidateQuery.error
  };
}; 