import 'animate.css/animate.min.css';
import React, { FC, useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { SkillsInterface, innerSkillsInterface } from 'components/models/SkillsInterfaces';
import PersonIcon from '@mui/icons-material/Person';
import getAxios from 'apiRestConnector';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import { DatosInterface } from 'components/models/PersonalInformationInterfaces';
import trainee from '../../../../assets/trainee.svg';
import junior from '../../../../assets/junior.svg';
import senior from '../../../../assets/senior.svg';
import semiSenior from '../../../../assets/semi-senior.svg';
import especialista from '../../../../assets/especialista.svg';
import backend from '../../../../assets/backend.svg';
import dataeng from '../../../../assets/dataeng.svg';
import devops from '../../../../assets/devops.svg';
import frontend from '../../../../assets/frontend.svg';
import general from '../../../../assets/general.svg';
import correo from '../../../../assets/correo.svg';
import kandio from '../../../../assets/kandio.svg';
import linkedin from '../../../../assets/linkedin.svg';
import Pdf from '../../../../assets/Pdf.svg';
import habilidades from '../../../../assets/habilidades.svg';
import mlops from '../../../../assets/mlops.svg';
import { flagFunction } from 'helpers/flags';
import SVGLike from 'components/ui/icons/SVGLike';
import { Link } from 'react-router-dom';
import { EditCandidate, Like, TitleGroupCard } from './style';
import ROL from 'utils/Roles';
import {
  ButtonCustom,
  CandidateInfo,
  CardDark,
  ContainerButtonCustom,
  ContainerInfoCard,
  DarkContainer,
  DarkContainerAfter,
  DarkContainerInfo,
  DarkContainerInfoText,
  DarkContainerInfoTitle,
  DarkSkillsContainer,
  DetailInfoAnchor,
  FakeImagenCandidate,
  FirstTitleName,
  ImagenCandidate,
  ImagenFlag,
  InfoText,
  ListLikesCard,
  RolText,
  SecondTitleName,
  SkillCard,
  SkillCardTitle,
  Text,
} from 'components/pages/showRoomCandidate/style';
import { Box, Tooltip, Typography, IconButton } from '@mui/material';
import { getCountryNameByCode } from 'data/countryDataSpanish';
import { ButtonsInfoInterface } from 'components/models/ButtonsInfoInterface';
import DETAIL_INFO from 'utils/DetailInfo';
import { AnchorsInfoInterface } from 'components/models/AnchorsInfoInterface';
import HelpIcon from '@mui/icons-material/Help';
import { useTrackPageView } from 'utils/firebase';
import ShareIcon from '@mui/icons-material/Share';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import Swal from 'sweetalert2';
import { useShareLink } from 'hooks/useShareLink';

interface Props {
  photo?: string;
  name?: string;
  residence?: string;
  phone?: number;
  datos?: DatosInterface[];
  skills?: SkillsInterface[];
  isABot?: boolean;
  widthCard?: string;
  validPrivateData?: boolean;
}

const ShowroomCard: FC<Props> = ({
  photo,
  name,
  phone = 0,
  datos,
  skills,
  isABot,
  widthCard,
  validPrivateData,
  residence,
}) => {
  useTrackPageView();

  const { REACT_APP_BACKEND_URL } = process.env;
  const { storeAuth } = useContext(AuthContext)!;
  const { access_token, email } = storeAuth.user;
  const { user } = storeAuth;
  const [like, setLike] = useState<boolean>(false);
  const [, setLoading] = useState<boolean>(false);
  const [infoLikes, setInfoLikes] = useState<boolean>(false);
  const [likeList, setLikeList] = useState<string[]>(Array);
  const [selectedRole, setSelectedRole] = useState<string>();
  const [buttonsInfo, setButtonsInfo] = useState<ButtonsInfoInterface[]>();
  const [anchorsDetailInfo, setAnchorsDetailInfo] = useState<AnchorsInfoInterface[]>();
  
  const { shareLink, isShareActive, getShareLink, handleCopyToClipboard } = useShareLink({phone});

  const filterDatos = useCallback(
    (comparator: string) => {
      if (!datos || datos.length === 0) return;

      const foundData = datos.find(({ nombre }: DatosInterface) => nombre === comparator);

      if (!foundData) return;

      return Array.isArray(foundData.valor) ? foundData.valor.join(' | ') : foundData.valor;
    },
    [datos]
  );

  useEffect(() => {
    const buttonsDetailInfo = [];
    datos?.forEach((dato) => {
      if (dato.nombre === DETAIL_INFO.CORREO) {
        buttonsDetailInfo.push({
          name: 'Correo',
          msg: 'Copiar correo',
          img: correo,
          action: () => navigator.clipboard.writeText(filterDatos('Correo').toString()),
        });
      }
    });

    setButtonsInfo(buttonsDetailInfo);
  }, [datos, filterDatos]);

  function isEmpty(data: string) {
    if (data !== '' && data !== '""') {
      return false;
    }
    return true;
  }

  useEffect(() => {
    const detailsInfo = [];

    const pushDetail = (img, url, description) => {
      if (typeof url === 'string' && !isEmpty(url)) {
        detailsInfo.push({ img, url, description });
      }
    };

    const detailMappingLegacy = {
      [DETAIL_INFO.LINKEDIN.toLowerCase()]: { img: linkedin, description: 'LinkedIn' },
      [DETAIL_INFO.TEST_KANDIO.toLowerCase()]: { img: kandio, description: 'Test' },
      [DETAIL_INFO.CV.toLowerCase()]: { img: Pdf, description: 'CV' },
    };
    const capitalizeWord = (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    };
    datos?.forEach((dato) => {
      const dataTitle = capitalizeWord(dato.nombre).toLowerCase();
      const dataType = dato?.type?.toLowerCase();
      const detailInfoLegacy = detailMappingLegacy[dataTitle];
      if (detailInfoLegacy) {
        pushDetail(detailInfoLegacy.img, dato.valor, detailInfoLegacy.description);
      } else if (dataType) {
        pushDetail(Pdf, dato.valor, dataTitle);
      }
    });
    setAnchorsDetailInfo(detailsInfo);
  }, [datos]);

  const calculateAverage = (skills: innerSkillsInterface[], factor: number) => {
    let addition = 0;
    let length = 0;
    skills.map((skill: innerSkillsInterface) => {
      let toNumber = 0;
      if (skill.nota !== null && (skill.estado === true || skill.estado === undefined)) {
        toNumber = parseInt(skill.nota);
        length += 1;
      }
      addition += toNumber;
      return null;
    });

    return parseFloat(((addition / length) * factor).toFixed(1));
  };

  const deleteLike = () => {
    getAxios
      .instance('')
      .delete(`${REACT_APP_BACKEND_URL}/likes/email/${email}/telefono/${phone}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response: any) => response.data)
      .then(() => {
        setLike(false);
        setLoading(false);
      });
  };

  const postLike = () => {
    const body = {
      email,
      nombreUsuario: user.name,
      telefono: phone,
      nombrePersona: name,
      isLike: true,
    };
    getAxios
      .instance('')
      .post(`${REACT_APP_BACKEND_URL}/likes`, body, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response: any) => response.data)
      .then(() => {
        setLike(true);
        setLoading(false);
      });
  };

  //todo Review this function is equal to the one in the candidate card(DetailCandidate.tsx)
  const getLikesByCandidate = () => {
    const urlBackend = `${REACT_APP_BACKEND_URL}/likes/users-by-candidate/${phone}`;
    getAxios
      .instance('')
      .get(urlBackend, {
        headers: {
          Authorization: `Bearer ${storeAuth.user.access_token}`,
        },
      })
      .then((response: any) => response.data)
      .then((data: string[]) => {
        setLikeList(data);
      });
  };

  const showInfoLikes = () => {
    setInfoLikes(true);
    getLikesByCandidate();
  };

  const manageLike = () => {
    setLoading(true);
    if (!like) {
      postLike();
    } else {
      deleteLike();
    }
  };

  useEffect(() => {
    const getLike = () => {
      getAxios
        .instance('')
        .get(`${REACT_APP_BACKEND_URL}/likes/email/${email}/telefono/${phone}`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then((response: any) => response.data)
        .then((data: any) => {
          setLike(data);
        });
    };
    getLike();
  }, [REACT_APP_BACKEND_URL, access_token, email, phone]);

  useEffect(() => {
    const user = window.localStorage.getItem('user');
    if (user) {
      const parsedUser = JSON.parse(user);
      const role = parsedUser.role[0];
      setSelectedRole(role);
    }
  }, [selectedRole, setSelectedRole, user]);

  const iso2 = useMemo(
    () => (residence?.length ? residence.toUpperCase().trim() : flagFunction(phone.toString())),
    [residence, phone]
  );

  const selectLevel = [
    { name: RegExp('^general', 'i'), img: general },
    { name: RegExp('^trainee', 'i'), img: trainee },
    { name: RegExp('^junior', 'i'), img: junior },
    { name: RegExp('^semi[- ]?senior', 'i'), img: semiSenior },
    { name: RegExp('^senior', 'i'), img: senior },
    { name: RegExp('^habilidad(es)? blanda(s)?', 'i'), img: habilidades },
    { name: RegExp('^frontend', 'i'), img: frontend },
    { name: RegExp('^backend', 'i'), img: backend },
    { name: RegExp('^devops', 'i'), img: devops },
    { name: RegExp('^mlops', 'i'), img: mlops },
    { name: RegExp('^data engineer', 'i'), img: dataeng },
    { name: RegExp('^especialista', 'i'), img: especialista },
  ];

  const formatToUsd = (value: string) => {
    return Number(value) ? parseInt(value).toLocaleString('es-CL') : 'No disponible';
  };

  const handleCopyToClipboardWithNotification = async () => {
    await handleCopyToClipboard();
    Swal.fire({
      title: '¡Copiado!',
      text: 'El enlace ha sido copiado al portapapeles',
      icon: 'success',
      timer: 2000,
      showConfirmButton: false
    });
  };

  return (
    <CardDark sx={{ width: widthCard }}>
      <ContainerInfoCard>
        <SecondTitleName title={'Ir al candidato'}>
          <Link to={isABot ? '/' : `/candidate/${phone}/view`} style={{ textDecoration: 'none' }}>
            <TitleGroupCard>
              <Text sx={{ color: 'white', fontSize: '20px' }}>{name}</Text>
              {filterDatos('Nivel') ? (
                <img
                  style={{ height: '30px !important' }}
                  src={
                    selectLevel.find((item) => item.name.test(filterDatos('Nivel').toString()))?.img
                  }
                  alt='icon-level'
                />
              ) : (
                <div style={{ display: 'inline-flex' }}>
                  <HelpIcon sx={{ color: '#637DAF', fontSize: '22px' }} />
                  <InfoText>Sin nivel</InfoText>
                </div>
              )}
            </TitleGroupCard>
            <RolText style={{ color: 'black' }}>{filterDatos('Rol')}</RolText>
          </Link>
        </SecondTitleName>
        <CandidateInfo>
          {selectedRole &&
            !isABot &&
            (selectedRole === ROL.ADMINISTRADOR || selectedRole === ROL.RECLUTADOR) && (
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', position: 'absolute', right: '20px', top: '20px' }}>
                {isShareActive ? (
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 1,
                    backgroundColor: 'rgba(49, 207, 242, 0.08)',
                    borderRadius: '4px',
                    padding: '4px 8px',
                    maxWidth: '300px'
                  }}>
                    <Typography
                      onClick={handleCopyToClipboardWithNotification}
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        color: '#31CFF2',
                        fontSize: '0.875rem',
                        cursor: 'pointer',
                        '&:hover': {
                          textDecoration: 'underline'
                        }
                      }}
                    >
                      {shareLink}
                    </Typography>
                    <IconButton
                      onClick={handleCopyToClipboardWithNotification}
                      size="small"
                      sx={{
                        color: '#31CFF2',
                        padding: '4px',
                        '&:hover': {
                          backgroundColor: 'rgba(49, 207, 242, 0.16)'
                        }
                      }}
                    >
                      <ContentCopyIcon sx={{ fontSize: '18px' }} />
                    </IconButton>
                  </Box>
                ) : (
                  <Tooltip title="Compartir">
                    <IconButton
                      onClick={getShareLink}
                      sx={{
                        color: '#31CFF2',
                        '&:hover': {
                          backgroundColor: 'rgba(49, 207, 242, 0.08)'
                        }
                      }}
                    >
                      <ShareIcon sx={{ fontSize: '20px' }} />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Editar">
                  <IconButton
                    component={Link}
                    to={isABot ? '/' : `/candidate/${phone}/edit`}
                    target="_blank"
                    sx={{
                      color: '#31CFF2',
                      '&:hover': {
                        backgroundColor: 'rgba(49, 207, 242, 0.08)'
                      }
                    }}
                  >
                    <EditIcon sx={{ fontSize: '20px' }} />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          {!photo ? (
            <FakeImagenCandidate>
              <PersonIcon sx={{ fontSize: 150, color: '#637DAF' }} />
            </FakeImagenCandidate>
          ) : (
            <ImagenCandidate src={photo} alt='Candidato' />
          )}
        </CandidateInfo>
        <CandidateInfo sx={{ width: '100%' }}>
          <FirstTitleName>
            <Link to={isABot ? '/' : `/candidate/${phone}/view`} style={{ textDecoration: 'none' }}>
              <TitleGroupCard>
                <Text sx={{ color: 'white', fontSize: '20px' }}>{name}</Text>
                {filterDatos('Nivel') ? (
                  <img
                    style={{ height: '30px' }}
                    src={
                      selectLevel.find((item) => item.name.test(filterDatos('Nivel').toString()))
                        ?.img
                    }
                    alt='icon-level'
                  />
                ) : (
                  <div style={{ display: 'inline-flex' }}>
                    <HelpIcon sx={{ color: '#637DAF', fontSize: '22px' }} />
                    <InfoText>Sin nivel</InfoText>
                  </div>
                )}
              </TitleGroupCard>
              <RolText>{filterDatos('Rol')}</RolText>
            </Link>
          </FirstTitleName>
          <ContainerButtonCustom>
            {buttonsInfo &&
              buttonsInfo.map(({ name, action, img, msg }) => (
                <Tooltip title={msg || ''} key={name + 'img'} disableHoverListener={!msg}>
                  <ButtonCustom onClick={() => action()}>
                    {img ? (
                      <img
                        style={{ display: 'flex', marginTop: '3px', width: '16px' }}
                        src={img}
                        alt={name + '-ImagenCandidate'}
                      />
                    ) : null}
                    {name}
                  </ButtonCustom>
                </Tooltip>
              ))}
            {anchorsDetailInfo &&
              anchorsDetailInfo.map(({ description, url, img }) => (
                <DetailInfoAnchor key={description} href={url} target='_blank'>
                  <img src={img} alt={`link ${description}`} />
                  {description}
                </DetailInfoAnchor>
              ))}
          </ContainerButtonCustom>
          <DarkContainer>
            {Boolean(getCountryNameByCode(iso2.toLowerCase())) && (
              <DarkContainerInfo className={'dark-container-info'}>
                <DarkContainerAfter>
                  <DarkContainerInfoTitle>Residencia</DarkContainerInfoTitle>
                  <Box sx={{ display: 'flex', gap: '4px' }}>
                    <ImagenFlag
                      src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${iso2}.svg`}
                      alt='Pais'
                    />
                    <DarkContainerInfoText sx={{ width: 'unset' }}>
                      {getCountryNameByCode(iso2.toLowerCase())}
                    </DarkContainerInfoText>
                  </Box>
                </DarkContainerAfter>
              </DarkContainerInfo>
            )}
            {Boolean(filterDatos('Modalidad')) && (
              <DarkContainerInfo className={'dark-container-info'}>
                <DarkContainerAfter>
                  <DarkContainerInfoTitle>Modalidad</DarkContainerInfoTitle>
                  <Tooltip title={filterDatos('Modalidad')}>
                    <DarkContainerInfoText>{filterDatos('Modalidad')}</DarkContainerInfoText>
                  </Tooltip>
                </DarkContainerAfter>
              </DarkContainerInfo>
            )}
            {Boolean(filterDatos('Disponibilidad')) && (
              <DarkContainerInfo className={'dark-container-info'}>
                <DarkContainerAfter>
                  <DarkContainerInfoTitle>Disponibilidad</DarkContainerInfoTitle>
                  <Tooltip title={filterDatos('Disponibilidad')}>
                    <DarkContainerInfoText>{filterDatos('Disponibilidad')}</DarkContainerInfoText>
                  </Tooltip>
                </DarkContainerAfter>
              </DarkContainerInfo>
            )}
            {Boolean(filterDatos('Años de Experiencia')) && (
              <DarkContainerInfo className={'dark-container-info'}>
                <DarkContainerAfter>
                  <DarkContainerInfoTitle>Experiencia</DarkContainerInfoTitle>
                  <Tooltip title={filterDatos('Años de Experiencia')}>
                    <DarkContainerInfoText>
                      {filterDatos('Años de Experiencia')}
                    </DarkContainerInfoText>
                  </Tooltip>
                </DarkContainerAfter>
              </DarkContainerInfo>
            )}
            {Boolean(filterDatos('Edad')) && (
              <DarkContainerInfo className={'dark-container-info'}>
                <DarkContainerAfter>
                  <DarkContainerInfoTitle>Edad</DarkContainerInfoTitle>
                  <Tooltip title={filterDatos('Edad')}>
                    <DarkContainerInfoText>{filterDatos('Edad')}</DarkContainerInfoText>
                  </Tooltip>
                </DarkContainerAfter>
              </DarkContainerInfo>
            )}
          </DarkContainer>
          <DarkSkillsContainer>
            {skills &&
              skills.length > 0 &&
              skills?.map(({ nombre, estado, skills }: SkillsInterface) => {
                if ((estado || estado === undefined) && calculateAverage(skills, 1) !== 0) {
                  return (
                    <SkillCard key={`ShowroomSkill${nombre}${skills}`}>
                      <SkillCardTitle>{nombre}</SkillCardTitle>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <img
                          style={{ height: '20px' }}
                          src={selectLevel.find((item) => item.name.test(nombre))?.img}
                          alt='icon-level'
                        />
                        <Text sx={{ color: 'white', fontSize: '16px' }}>
                          {calculateAverage(skills, 1)}
                        </Text>
                      </Box>
                    </SkillCard>
                  );
                }
                return null;
              })}
          </DarkSkillsContainer>
        </CandidateInfo>
      </ContainerInfoCard>
      {validPrivateData &&
        (selectedRole === ROL.ADMINISTRADOR || selectedRole === ROL.RECLUTADOR) && (
          <DarkContainer>
            {Boolean(filterDatos('Fuente')) && (
              <DarkContainerInfo sx={{ padding: 0 }} className={'dark-container-info'}>
                <DarkContainerAfter>
                  <DarkContainerInfoTitle>Fuente</DarkContainerInfoTitle>
                  <Tooltip
                    title={filterDatos('Fuente') || ''}
                    disableHoverListener={filterDatos('Fuente') ? false : true}
                  >
                    <DarkContainerInfoText>{filterDatos('Fuente')} </DarkContainerInfoText>
                  </Tooltip>
                </DarkContainerAfter>
              </DarkContainerInfo>
            )}
            {Boolean(filterDatos('Que Rol deseas Desempeñar en 1 a 2 años')) && (
              <DarkContainerInfo className={'dark-container-info'}>
                <DarkContainerAfter>
                  <DarkContainerInfoTitle>Rol deseado</DarkContainerInfoTitle>
                  <Tooltip
                    title={filterDatos('Que Rol deseas Desempeñar en 1 a 2 años') || ''}
                    disableHoverListener={
                      filterDatos('Que Rol deseas Desempeñar en 1 a 2 años') ? false : true
                    }
                  >
                    <DarkContainerInfoText>
                      {filterDatos('Que Rol deseas Desempeñar en 1 a 2 años')}
                    </DarkContainerInfoText>
                  </Tooltip>
                </DarkContainerAfter>
              </DarkContainerInfo>
            )}
            {Boolean(filterDatos('Razón por la cual te deseas Cambiar')) && (
              <DarkContainerInfo className={'dark-container-info'}>
                <DarkContainerAfter>
                  <DarkContainerInfoTitle>Razon de cambio</DarkContainerInfoTitle>
                  <Tooltip
                    title={filterDatos('Razón por la cual te deseas Cambiar') || ''}
                    disableHoverListener={
                      filterDatos('Razón por la cual te deseas Cambiar') ? false : true
                    }
                  >
                    <DarkContainerInfoText>
                      {filterDatos('Razón por la cual te deseas Cambiar')}
                    </DarkContainerInfoText>
                  </Tooltip>
                </DarkContainerAfter>
              </DarkContainerInfo>
            )}
            {Boolean(filterDatos('Renta Actual') as string) && (
              <DarkContainerInfo className={'dark-container-info'}>
                <DarkContainerAfter>
                  <DarkContainerInfoTitle>Renta Actual</DarkContainerInfoTitle>
                  <Tooltip
                    title={formatToUsd(filterDatos('Renta Actual') as string) || ''}
                    disableHoverListener={filterDatos('Renta Actual') ? false : true}
                  >
                    <DarkContainerInfoText sx={{ color: '#31CFF2' }}>
                      {formatToUsd(filterDatos('Renta Actual') as string)}{' '}
                      {filterDatos('Moneda Renta Actual')?.toString() ?? ''}
                    </DarkContainerInfoText>
                  </Tooltip>
                </DarkContainerAfter>
              </DarkContainerInfo>
            )}
            {Boolean(filterDatos('Pretenciones de Renta') as string) && (
              <DarkContainerInfo className={'dark-container-info'}>
                <DarkContainerAfter>
                  <DarkContainerInfoTitle>Pretenciones de Renta</DarkContainerInfoTitle>
                  <Tooltip
                    title={formatToUsd(filterDatos('Pretenciones de Renta') as string) || ''}
                    disableHoverListener={filterDatos('Pretenciones de Renta') ? false : true}
                  >
                    <DarkContainerInfoText sx={{ color: '#31CFF2' }}>
                      {formatToUsd(filterDatos('Pretenciones de Renta') as string)}{' '}
                      {filterDatos('Moneda Pretenciones de Renta')?.toString() ?? ''}
                    </DarkContainerInfoText>
                  </Tooltip>
                </DarkContainerAfter>
              </DarkContainerInfo>
            )}
          </DarkContainer>
        )}
    </CardDark>
  );
};

export default ShowroomCard;
