import { z } from 'zod';

export const formSchema = z.object({
  searchQuery: z.string().optional(),
  match: z.number(),
  score: z.number(),
});

export type FormData = z.infer<typeof formSchema>;

export const defaultValues: FormData = {
  searchQuery: '',
  match: 0.5,
  score: 0.5,
};

const ContactSchema = z.object({
  name: z.string(),
  phone: z.string(),
  countryCode: z.string(),
});

const RowDataSchema = z.object({
  match: z.string(),
  score: z.string(),
  proporcion: z.string(),
  pais: z.string(),
  nombre: z.string(),
  email: z.string(),
  perfil: z.string(),
  cv: z.string(),
  linkedin: z.string(),
  telefono: z.string(),
  contact: ContactSchema,
});

// Esquema para ApiResponse
const ResultSearchSchema = z.object({
  match: z.number(),
  score: z.number(),
  proporcion: z.number(),
  nombre: z.string(),
  telefono: z.string(),
  email: z.string(),
  pais: z.string(),
  perfil: z.string(),
  cv: z.string(),
  linkedin: z.string(),
});

const JsonTagsDataSearchSchema = z.object({
  puestos: z.record(z.unknown()),
  empresas: z.array(z.string()),
  sectores: z.array(z.string()),
  pais: z.string(),
  nivel_academico: z.string(),
  idiomas: z.array(z.string()),
  skills: z.record(z.number()),
});

const ApiResponseSchema = z.object({
  result_search: z.array(ResultSearchSchema),
  json_tags_data_search: JsonTagsDataSearchSchema,
});

export type RowData = z.infer<typeof RowDataSchema>;
export type ApiResponse = z.infer<typeof ApiResponseSchema>;
