import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import Loading from 'components/ui/loading/Loading';
import { StyledTableDesktop, StyledTableMobile } from './style';
import TableCardRow from '../tableCardRow/TableCardRow';
import TableHeadRow from '../tableHeadRow/TableHeadRow';
import TableHeadInvitations from '../tableHead/TableHeadInvitations';
import TableRowInvitations from '../tableRow/TableRowInvitations';
import getAxios from 'apiRestConnector';
import { AuthContext } from 'services/authContext/AuthContextProvider';

import Spinner from 'components/ui/spinner/Spinner';
import SwalAlert from 'components/ui/alerts/SwalAlert';

interface Props {
  contents: any;
  loading?: boolean;
  getSortedCanditatesByParamenter: (key: string) => void;
  orderedCandidate: boolean;
  refrescaCandidatos: boolean;
  setRefrescaCandidatos: (r: boolean) => void;
}

interface Contents {
  name: any;
  phone: any;
  options: any;
  state: any;
  workflow: any;
  date: any;
  personalInfo: any;
}

const TableInvitations: FC<Props> = ({
  contents,
  loading = false,
  getSortedCanditatesByParamenter,
  orderedCandidate,
  refrescaCandidatos,
  setRefrescaCandidatos,
}) => {
  const { storeAuth } = useContext(AuthContext)!;
  const { access_token } = storeAuth.user;
  const { REACT_APP_BACKEND_URL } = process.env;
  const [todos, setTodos] = useState(false);
  const [invitacionesCandidatos, setInvitacionesCandidatos] = useState<string[]>([]);

  const callbackInvitacionesTodos = useCallback(() => {
    return todos;
  }, [todos]);

  const callbackRefrescaCandidatos = useCallback(() => {
    return refrescaCandidatos;
  }, [refrescaCandidatos]);

  useEffect(() => {
    if (callbackInvitacionesTodos() && invitacionesCandidatos.length !== 0) {
      let res = '';
      SwalAlert({
        title: 'Invitar postulante/s',
        text: 'Seleccione una plantilla',
        input: 'select',
        inputOptions: {
          '1': '1',
        },
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
        showConfirmButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          res = result.value as string;

          let data = contents.map((cont: Contents) => {
            if (invitacionesCandidatos.includes(cont.personalInfo.fono)) {
              return cont.personalInfo.data
                .filter((d: any) => d.nombre === 'Correo')
                .map((p: any) => {
                  return {
                    telefono: cont.personalInfo.fono,
                    correo: p.valor,
                    nombre: cont.name.data,
                  };
                })[0];
            }
            return '';
          });
          data = data.filter((d: any) => d !== '');

          const obj = {
            template: res,
            datos: [data],
          };

          SwalAlert({
            title: 'Enviando invitaciones',
            text: 'Espere un momento',
          });

          getAxios
            .instance('')
            .put(`${REACT_APP_BACKEND_URL}/invitaciones`, obj, {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            })
            .then(() => {
              SwalAlert({
                title: 'Envio exitoso',
                text: 'Espere un momento',
                icon: 'success',
                confirmButtonText: 'Entendido',
                showConfirmButton: true,
              });
              setRefrescaCandidatos(true);
            })
            .catch((error) => {
              console.error('error:', error.message);
              SwalAlert({
                title: 'Error en la consulta',
                icon: 'error',
                confirmButtonText: 'Entendido',
                showConfirmButton: true,
              });
            });
        }
        setInvitacionesCandidatos([]);
      });
    }
    setTodos(false);
  }, [
    callbackInvitacionesTodos,
    REACT_APP_BACKEND_URL,
    access_token,
    contents,
    callbackRefrescaCandidatos,
    setRefrescaCandidatos,
    invitacionesCandidatos,
  ]);

  return (
    <>
      <StyledTableDesktop responsive>
        <thead>
          <TableHeadInvitations
            {...{ getSortedCanditatesByParamenter, orderedCandidate, todos, setTodos, loading }}
          />
        </thead>
        {!loading && (
          <tbody>
            <TableRowInvitations
              {...{
                contents,
                refrescaCandidatos,
                setRefrescaCandidatos,
                invitacionesCandidatos,
                setInvitacionesCandidatos,
              }}
            />
          </tbody>
        )}
      </StyledTableDesktop>

      <StyledTableMobile>
        <TableHeadRow {...{ getSortedCanditatesByParamenter, orderedCandidate }} />
        <TableCardRow {...{ contents }} />
      </StyledTableMobile>

      {loading && (
        <div className='d-flex justify-content-center'>
          <Loading>
            <Spinner color='primary' />
          </Loading>
        </div>
      )}
    </>
  );
};

export default TableInvitations;
