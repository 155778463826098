import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react';
import deburr from 'lodash/deburr';
import { TemplateInterface } from 'components/models/CandidateInterfaces';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import TaskCard from 'components/ui/cards/taskCard/TaskCard';
import { DateTime } from 'luxon';
import { flagFunction } from 'helpers/flags';
import ListCandidateFilter from './ListCandidateFilter';
import { firebaseAnalytics } from 'utils/firebase';
import { DatosTemplateInterface } from 'components/models/PersonalInformationInterfaces';
import { FILTER_TYPE } from 'utils/CandidateFilters';
import PageHeader from '../../ui/PageHeader';
import CandidatesTable from '../../ui/tables/CandidatesTable';
import ContactModal, { ContactProps } from '../../ui/phone/ContactModal';
import {
  AccordionSummary,
  Typography,
  AccordionDetails,
  Box,
  Accordion,
  Chip,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface FilterOptions {
  filterOption: string;
  filterOrder: boolean;
}

export interface CandidateRowActions {
  edit: {
    href: string;
  };
  view: {
    href: string;
  };
  contact: {
    name: string;
    phone: string;
    countryCode: string;
  };
}

export interface CandidateTableData {
  name: any;
  telefono: any;
  state: any;
  date: any;
  personalInfo: any;
  actions: CandidateRowActions;
  tagged: boolean;
}

const ORDER_ESTADO_MAP = {
  INICIAL: 1,
  'SOFT SKILLS': 2,
  TÉCNICA: 3,
  CLIENTE: 4,
  CONTRATADO: 5,
} as const;

const getCandidateDate = (candidate: TemplateInterface) => {
  if (candidate && DateTime.fromISO(candidate.workflow?.[0].fecha).isValid) {
    return DateTime.fromISO(candidate.workflow?.[0].fecha);
  }
  return DateTime.now();
};

const orderFilterCandidates = (filterOptions: FilterOptions) => {
  const previousFilter = JSON.parse(window.localStorage.getItem('filterCandidates') || '{}');
  const updatedFilterCandidates = {
    ...previousFilter,
    [filterOptions.filterOption]: filterOptions.filterOrder,
  };

  const filterOrder = Object.keys(updatedFilterCandidates);
  const index = filterOrder.indexOf(filterOptions.filterOption);
  if (index !== -1) {
    filterOrder.splice(index, 1);
    filterOrder.push(filterOptions.filterOption);
  }

  const reorganizedFilterCandidates = {};
  filterOrder.forEach((filter) => {
    reorganizedFilterCandidates[filter] = updatedFilterCandidates[filter];
  });

  window.localStorage.setItem('filterCandidates', JSON.stringify(reorganizedFilterCandidates));
};

const ListCandidate = () => {
  useEffect(() => {
    firebaseAnalytics.logEvent(`ADMIN_LOGIN: ${storeAuth.user.name}`);
  });

  const { storeAuth } = useContext(AuthContext)!;
  const [loading, setLoading] = useState(true);
  const [orderedCandidate, setOrderedCandidate] = useState<boolean>(true);
  const [filteredCandidates, setFilteredCandidates] = useState<TemplateInterface[]>([]);
  const [filterCandidateStatus, setFilterCandidateStatus] = useState<string[]>(Array);
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [requestsIds, setRequestsIds] = useState<number[]>([]);
  const [filterRequests, setFilterRequests] = useState<string[]>([]);
  const [filterPhoneNumber, setPhoneNumber] = useState<string>('');
  const [filterActivo, setFilterActivo] = useState<string>('activo');
  const [filterCandidateDesde, setFilterCandidateDesde] = useState<Date>(new Date());
  const [filterCandidateHasta, setFilterCandidateHasta] = useState<Date>(new Date());
  const [rol, setRol] = useState<string[]>([]);
  const [requestCandidate, setRequestCandidate] = useState(0);
  const [nameFilter, setNameFilter] = useState('');
  const [openContactModal, setOpenContactModal] = useState(false);
  const [contact, setContact] = useState<ContactProps>();
  const [switchConditionRequest] = useState<boolean>(true);

  const handleContactClick = useCallback((contact: ContactProps) => {
    setContact({ name: contact.name, phone: contact.phone });
    setOpenContactModal(true);
  }, []);

  const getPersona = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (event.key === 'Enter') {
      setLoading(true);

      const value: string = (event.target as HTMLInputElement).value;
      const valueWithoutDiacritics: string = deburr(value);

      setNameFilter(valueWithoutDiacritics);
    }
  }, []);

  const candidateTableData: CandidateTableData[] = useMemo(() => {
    return filteredCandidates.map((candidate: TemplateInterface) => {
      const countryCodeIso2 = flagFunction(candidate.telefono.toString());

      return {
        name: {
          data: candidate.nombre,
          href: `/candidate/${candidate.telefono}/edit`,
        },
        telefono: '+' + candidate.telefono,
        state: {
          data: candidate.estado,
          workflow: candidate.workflow,
        },
        score: '1',
        date: {
          data: candidate.timestamp,
        },
        personalInfo: {
          data: candidate.datos,
          skills: candidate.skills,
          state: candidate.activo,
          softSkills: candidate.competencias,
          footer: candidate.footer,
        },
        actions: {
          edit: {
            href: `/candidate/${candidate.telefono}/edit`,
          },
          view: {
            href: `/candidate/${candidate.telefono}/view`,
          },
          contact: {
            name: candidate.nombre,
            phone: candidate.telefono.toString(),
            countryCode: countryCodeIso2,
          },
        },
        tagged: Boolean(candidate.tagged),
      };
    });
  }, [filteredCandidates]);

  const getSortedCanditatesByParamenter = useCallback(
    (key: string, savedOrder?: boolean) => {
      const candidateOrder = savedOrder ?? orderedCandidate;
      if (key === FILTER_TYPE.ESTADO) {
        filteredCandidates.sort(
          (firstCanditate: TemplateInterface, secondCanditate: TemplateInterface) => {
            const firstKey = ORDER_ESTADO_MAP[firstCanditate[key]!.toUpperCase().trim()] || 6;
            const secondKey = ORDER_ESTADO_MAP[secondCanditate[key]!.toUpperCase().trim()] || 6;
            const result = !candidateOrder ? secondKey - firstKey : firstKey - secondKey;
            return result === 0 ? 1 : result;
          }
        );
      }

      if (key === FILTER_TYPE.TIME) {
        filteredCandidates.sort(
          (firstCanditate: TemplateInterface, secondCanditate: TemplateInterface) => {
            const result = !candidateOrder
              ? firstCanditate[key]! > secondCanditate[key]!
              : firstCanditate[key]! < secondCanditate[key]!;
            return result ? 1 : -1;
          }
        );
      }

      if (key === FILTER_TYPE.WORKFLOW) {
        filteredCandidates.sort(
          (firstCanditate: TemplateInterface, secondCanditate: TemplateInterface) => {
            if (
              getCandidateDate(firstCanditate).valueOf() >
              getCandidateDate(secondCanditate).valueOf()
            ) {
              return candidateOrder ? -1 : 1;
            }
            return candidateOrder ? 1 : -1;
          }
        );
      }

      if (key === FILTER_TYPE.RESPONSABLE) {
        filteredCandidates.sort(
          (firstCanditate: TemplateInterface, secondCanditate: TemplateInterface) => {
            const firstResponsable = firstCanditate.workflow?.[0].responsable ?? null;
            const secondResponsable = secondCanditate.workflow?.[0].responsable ?? null;

            if (!firstResponsable || !secondResponsable) {
              return 0;
            }

            if (!candidateOrder) {
              return firstResponsable > secondResponsable ? 1 : -1;
            }
            return firstResponsable < secondResponsable ? 1 : -1;
          }
        );
      }

      if (key === FILTER_TYPE.ROL || key === FILTER_TYPE.NIVEL) {
        filteredCandidates.sort(
          (firstCanditate: TemplateInterface, secondCanditate: TemplateInterface) => {
            const firstCandidateValues = firstCanditate?.datos?.map(
              (candidateData: DatosTemplateInterface) => {
                return candidateData.nombre === key ? candidateData.valor : '';
              }
            );
            const secondCanditateValues = secondCanditate?.datos?.map(
              (candidateData: DatosTemplateInterface) => {
                return candidateData.nombre === key ? candidateData.valor : '';
              }
            );

            if (!firstCandidateValues || !secondCanditateValues) {
              return 0;
            }

            let result =
              firstCandidateValues.filter((value: any) => value !== '') >
              secondCanditateValues.filter((value: any) => value !== '');
            result = !candidateOrder ? result : !result;

            return result ? -1 : 1;
          }
        );
      }

      const filterOptions: FilterOptions = {
        filterOption: key,
        filterOrder: !candidateOrder,
      };

      setOrderedCandidate(!candidateOrder);
      orderFilterCandidates(filterOptions);
    },
    [filteredCandidates, orderedCandidate]
  );

  useEffect(() => {
    if (firstRender && filteredCandidates.length > 0) {
      const filterCandidates = JSON.parse(window.localStorage.getItem('filterCandidates') || '{}');
      const filters = Object.entries(filterCandidates);
      if (filters.length !== 0) {
        filters.forEach(([filterOption, savedOrder]) => {
          if (filterOption !== null) {
            getSortedCanditatesByParamenter(filterOption, Boolean(!savedOrder));
          }
        });
        setFirstRender(false);
      }
    }
  }, [filteredCandidates, firstRender, getSortedCanditatesByParamenter]);

  return (
    <>
      <PageHeader>Candidatos</PageHeader>
      <Box sx={{ display: 'flex', gap: '1rem', flexDirection: 'column', margin: '1rem 0' }}>
        <Box>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography>Tareas</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TaskCard setRequestCandidate={setRequestCandidate} />
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box>
          <Box>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <Typography>Filtros</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ListCandidateFilter
                  setLoading={setLoading}
                  setFilteredCandidates={setFilteredCandidates}
                  setFilterCandidateStatus={setFilterCandidateStatus}
                  filterCandidateStatus={filterCandidateStatus}
                  filterActivo={filterActivo}
                  setFilterActivo={setFilterActivo}
                  filterCandidateDesde={filterCandidateDesde}
                  filterCandidateHasta={filterCandidateHasta}
                  setFilterCandidateDesde={setFilterCandidateDesde}
                  setFilterCandidateHasta={setFilterCandidateHasta}
                  rol={rol}
                  setRol={setRol}
                  nameFilter={nameFilter}
                  setNameFilter={setNameFilter}
                  requestCandidate={requestCandidate}
                  requestsIds={requestsIds}
                  setRequestsIds={setRequestsIds}
                  filterRequests={filterRequests}
                  setFilterRequests={setFilterRequests}
                  switchConditionRequest={switchConditionRequest}
                  filterPhoneNumber={filterPhoneNumber}
                  setPhoneNumber={setPhoneNumber}
                />
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </Box>

      {filteredCandidates.length > 0 && (
        <Chip
          label={`Se han encontrado ${filteredCandidates.length} registros.`}
          sx={{
            display: 'flex',
            margin: '15px auto',
            textAlign: 'center',
            padding: '10px',
            borderRadius: '5px',
            width: '400px',
            backgroundColor: 'default',
          }}
          variant='filled'
        />
      )}

      <CandidatesTable
        data={candidateTableData}
        filterCallback={getPersona}
        onContactClick={handleContactClick}
        loading={loading}
      />

      <ContactModal
        open={openContactModal}
        onClose={() => setOpenContactModal(false)}
        name={contact?.name}
        phone={contact?.phone}
      />
    </>
  );
};

export default ListCandidate;
