import React, { useEffect } from 'react';
import FullscreenLoading from '../loading/FullscreenLoading';
import { StyledDetailsContainer, StyledDetailsSkillsTitle, StyledDetailsTitleCardContainer, StyledDetailsTitleSkillsContainer } from 'components/pages/detailsCandidate/style';
import ShowroomCard from '../cards/showroomCard/ShowroomCard';
import NewSoftSkillsComp from 'components/pages/detailsCandidate/softSkillsComponent/NewSoftSkillComp';
import { SkillsComponent } from 'components/pages/detailsCandidate/skillsComponent/SkillsComponent';
import { NewSummary } from 'components/pages/detailsCandidate/summaryComponent/NewSummary';

import { usePublicView } from 'hooks/usePublicView';
import { useParams } from 'react-router-dom';

export const PublicView = () => {
  const { hashCandidate } = useParams<any>();
  console.log({hashCandidate});
  const { candidate, isLoading } = usePublicView(hashCandidate);

  useEffect(() => {
    if (candidate?.nombre) {
      document.title = `WiTI - ${candidate.nombre}`;
    }
  }, [candidate?.nombre]);

  if (isLoading || !candidate) return <FullscreenLoading />;

  return (
    <StyledDetailsContainer>
      <div style={{ maxWidth: '1158px' }} className='' id='content'>
        <div>
          <StyledDetailsTitleCardContainer>
            <ShowroomCard
              key={candidate.telefono}
              name={candidate.nombre}
              phone={+candidate.telefono}
              datos={candidate.datos}
              residence={
                candidate.datos.find((dato) => dato.nombre === 'Residencia')?.valor as string
              }
              skills={candidate.skills}
              photo={candidate.profileImgUrl}
              isABot={true}
              validPrivateData
            />
          </StyledDetailsTitleCardContainer>
        </div>
        <div>
          <NewSoftSkillsComp softSkills={candidate.competencias} />
        </div>

        <StyledDetailsTitleSkillsContainer>
          <StyledDetailsSkillsTitle>Habilidades</StyledDetailsSkillsTitle>
          <SkillsComponent skills={candidate.skills} />
        </StyledDetailsTitleSkillsContainer>
        <div>
          <NewSummary softSkills={candidate.footer} />
        </div>

      </div>
    </StyledDetailsContainer>
  );
};