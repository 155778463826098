import { darken, lighten, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const getBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

const BASE = 'workflow-status';
const SAFE = `${BASE}--safe`;
const WARNING = `${BASE}--warning`;
const DANGER = `${BASE}--danger`;

export const ROW_CLASSES = { BASE, SAFE, WARNING, DANGER };

export const StyledTableBodyRows = styled(Box)(({ theme }) => ({
  [`.${BASE}`]: {
    cursor: 'pointer',
  },
  [`.${SAFE}`]: {
    backgroundColor: `${getBackgroundColor(
      theme.palette.info.main,
      theme.palette.mode
    )} !important`,
    '&:hover td': {
      backgroundColor: getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
    },
    '&.Mui-selected': {
      backgroundColor: `${getSelectedBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode
      )} !important`,
      '&:hover td': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode
        ),
      },
    },
  },
  [`.${WARNING}`]: {
    backgroundColor: `${getBackgroundColor(
      theme.palette.warning.main,
      theme.palette.mode
    )} !important`,
    '&:hover td': {
      backgroundColor: getHoverBackgroundColor(theme.palette.warning.main, theme.palette.mode),
    },
    '&.Mui-selected': {
      backgroundColor: `${getSelectedBackgroundColor(
        theme.palette.warning.main,
        theme.palette.mode
      )} !important`,
      '&:hover td': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.warning.main,
          theme.palette.mode
        ),
      },
    },
  },
  [`.${DANGER}`]: {
    backgroundColor: `${getBackgroundColor(
      theme.palette.error.main,
      theme.palette.mode
    )} !important`,
    '&:hover td': {
      backgroundColor: getHoverBackgroundColor(theme.palette.error.main, theme.palette.mode),
    },
    '&.Mui-selected': {
      backgroundColor: `${getSelectedBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode
      )} !important`,
      '&:hover td': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode
        ),
      },
    },
  },
}));
