import React, { useEffect, useState, useContext, useCallback } from 'react';
import getAxios from 'apiRestConnector';
import { CardContainerShowRoomSC } from './style';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import ShowroomCard from 'components/ui/cards/showroomCard/ShowroomCard';
import { CandidateInterface } from 'components/models/CandidateInterfaces';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import FullscreenLoading from '../../ui/loading/FullscreenLoading';
import ShowRoomFilter from './ShowRoomFilter';
// import { useTrackPageView } from 'utils/firebase';

const ShowRoomCandidate = () => {
  const [isABot, setIsABot] = useState(false);
  const [loading, setLoading] = useState(true);
  const { storeAuth } = useContext(AuthContext)!;
  const { access_token } = storeAuth.user;
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [selectedSeniorities, setSelectedSeniorities] = useState<string[]>([]);
  const [allCandidates, setAllCandidates] = useState<CandidateInterface[]>(Array);
  const [candidates, setCandidates] = useState<CandidateInterface[]>(Array);
  const [uniqueRoles, setUniqueRoles] = useState([]);
  const [uniqueSeniorities, setUniqueSeniorities] = useState([]);

  useEffect(() => {
    const test = () => {
      getAxios
        .instance(access_token)
        .get('/personas/vitrina')
        .then((result: any) => {
          return result.data;
        })
        .then((entries: any) => {
          setAllCandidates(entries);
          setCandidates(entries);
          setLoading(false);
        });
    };
    test();
  }, [access_token]);

  function capitalizeWords(str) {
    return str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  useEffect(() => {
    const rolesSet = new Set<string>();
    const senioritiesSet = new Set<string>();

    allCandidates.forEach((candidate) => {
      const roleData = candidate.datos.find((dato) => dato.nombre === 'Rol')?.valor;
      const seniorityData = candidate.datos.find((dato) => dato.nombre === 'Nivel')?.valor;

      if (Array.isArray(roleData)) {
        roleData.forEach((role) => rolesSet.add(capitalizeWords(role)));
      } else if (typeof roleData === 'string') {
        rolesSet.add(capitalizeWords(roleData));
      }

      if (Array.isArray(seniorityData)) {
        seniorityData.forEach((seniority) => senioritiesSet.add(capitalizeWords(seniority)));
      } else if (typeof seniorityData === 'string') {
        senioritiesSet.add(capitalizeWords(seniorityData));
      }
    });

    setUniqueRoles(Array.from(rolesSet));
    setUniqueSeniorities(Array.from(senioritiesSet));
  }, [allCandidates]);

  useEffect(() => {
    const rolesSet = new Set<string>();
    const senioritiesSet = new Set<string>();

    const normalize = (array) => {
      const normalized = array
        .map((role) => role.toLowerCase())
        .filter((value, index, self) => self.indexOf(value) === index);
      return normalized.map((role) => role.charAt(0).toUpperCase() + role.slice(1));
    };

    allCandidates.forEach((candidate) => {
      const roleData = candidate.datos.find((dato) => dato.nombre === 'Rol')?.valor;
      const seniorityData = candidate.datos.find((dato) => dato.nombre === 'Nivel')?.valor;

      if (Array.isArray(roleData)) {
        roleData.forEach((role) => rolesSet.add(role));
      } else if (typeof roleData === 'string') {
        rolesSet.add(roleData);
      }

      if (Array.isArray(seniorityData)) {
        seniorityData.forEach((seniority) => senioritiesSet.add(seniority));
      } else if (typeof seniorityData === 'string') {
        senioritiesSet.add(seniorityData);
      }
    });

    setUniqueSeniorities(normalize(Array.from(senioritiesSet)));
    setUniqueRoles(Array.from(rolesSet));
  }, [allCandidates]);

  const onVerify = useCallback(
    (token: string) => {
      const captcha = () => {
        if (token !== '' && access_token) {
          getAxios
            .instance(access_token)
            .get(`/personas/captcha/${token}`)
            .then((result: any) => result.data)
            .then((entries: any) => {
              setIsABot(entries);
            })
            .catch((error) => {
              setIsABot(true);
            });
        }
      };
      captcha();
    },
    [access_token]
  );
  const handleRoleChange = (newSelectedRoles) => {
    setSelectedRoles(newSelectedRoles);
  };

  const handleSeniorityChange = (newSelectedSeniorities) => {
    setSelectedSeniorities(newSelectedSeniorities);
  };

  useEffect(() => {
    const filterCandidates = () => {
      if (selectedRoles.length === 0 && selectedSeniorities.length === 0) {
        setCandidates(allCandidates);
      } else {
        const filtered = allCandidates.filter((candidate) => {
          // Obtener el valor de 'Rol', que puede ser un string o un array de strings
          const roleData = candidate.datos.find((dato) => dato.nombre === 'Rol')?.valor;
          let roleValues = [];
          // Verificar si roleData es un array o un string y preparar roleValues como un array de strings
          if (Array.isArray(roleData)) {
            roleValues = roleData.map((role) => role.toLowerCase());
          } else if (typeof roleData === 'string') {
            roleValues = [roleData.toLowerCase()];
          }

          const seniorityValue = candidate.datos
            .find((dato) => dato.nombre === 'Nivel')
            ?.valor.toString()
            .toLowerCase();

          // Verificar si al menos un rol del candidato coincide con los roles seleccionados
          const matchesRole =
            selectedRoles.length === 0 ||
            selectedRoles.some((role) => roleValues.includes(role.toLowerCase()));
          const matchesSeniority =
            selectedSeniorities.length === 0 ||
            selectedSeniorities
              .map((seniority) => seniority.toLowerCase())
              .includes(seniorityValue);

          return matchesRole && matchesSeniority;
        });

        setCandidates(filtered);
      }
    };
    filterCandidates();
  }, [selectedRoles, selectedSeniorities, allCandidates]);

  if (loading) return <FullscreenLoading />;

  return (
    <CardContainerShowRoomSC>
      <ShowRoomFilter
        selectedRoles={selectedRoles}
        selectedSeniorities={selectedSeniorities}
        onRoleChange={handleRoleChange}
        onSeniorityChange={handleSeniorityChange}
        uniqueRoles={uniqueRoles}
        uniqueSeniorities={uniqueSeniorities}
      />
      {candidates.length > 0 &&
        candidates.map((candidate: CandidateInterface) => {
          return (
            <ShowroomCard
              widthCard='686px'
              key={candidate.telefono}
              name={candidate.nombre}
              phone={candidate.telefono}
              residence={
                candidate.datos.find((dato) => dato.nombre === 'Residencia')?.valor as string
              }
              datos={candidate.datos}
              skills={candidate.skills}
              photo={candidate.profileImgUrl}
              isABot={isABot}
              validPrivateData={false}
            />
          );
        })}
      <GoogleReCaptcha onVerify={onVerify} />
    </CardContainerShowRoomSC>
  );
};

export default ShowRoomCandidate;
