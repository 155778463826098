import { useInfiniteQuery } from '@tanstack/react-query';
import getAxios from 'apiRestConnector';
import { WorkflowEvent } from 'components/pages/newCandidate/workflowEvent/types';
import { useContext } from 'react';
import { AuthContext } from 'services/authContext/AuthContextProvider';

export type WorkflowEventWithUser = WorkflowEvent & {
  user: string;
  telefono: string;
};

type EventsPagination = {
  page: number;
  size: number;
};

type EventsResponse = {
  data: WorkflowEventWithUser[];
  metadata: {
    totalPages: number;
    total: number;
    page: number;
    size: number;
  };
};

const fetchLatestEvents = async (
  accessToken: string,
  { page, size }: EventsPagination = { page: 1, size: 10 }
) => {
  const { data } = await getAxios.instance(accessToken).get<EventsResponse>('/workflow-event', {
    params: {
      page,
      size,
    },
  });

  return data;
};

export const useEventNotifications = () => {
  const { storeAuth } = useContext(AuthContext);
  const { access_token } = storeAuth.user;

  const { data, isLoading, error, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
       
      queryKey: ['workflow-event-notifications'],
      queryFn: ({ pageParam = 1 }) =>
        fetchLatestEvents(access_token, { page: pageParam, size: 10 }),
      getNextPageParam: (lastPage) =>
        lastPage.metadata.page < lastPage.metadata.totalPages
          ? lastPage.metadata.page + 1
          : undefined,
      initialPageParam: 1,
    });

  const events = data?.pages.flatMap((page) => page.data) ?? [];

  return { events, isLoading, error, fetchNextPage, isFetchingNextPage, hasNextPage };
};
