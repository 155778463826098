import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';
import React from 'react';
import { IconButtonProps } from '@mui/material/IconButton/IconButton';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

interface IIconButtonTooltip extends IconButtonProps {
  icon: React.ComponentType<SvgIconProps>;
  title: string;
  href?: string;
  to?: string;
  target?: string;
}
const IconButtonTooltip = ({ title, icon: Icon, children, ...props }: IIconButtonTooltip) => {
  return (
    <Tooltip {...{ title }} placement='bottom-start'>
      <IconButton {...props} rel='noreferrer' size='small' sx={{ gap: 1, display: 'flex' }}>
        <Icon sx={{ color: '#00b4d8' }} />
        {children}
      </IconButton>
    </Tooltip>
  );
};

export default IconButtonTooltip;
