import { WorkflowInterface } from 'components/models/WorkFlowInterfaces';
import { Check, X } from 'react-bootstrap-icons';
import React, { FC, Fragment } from 'react';
import {
  StyledIcon,
  StyledContainer,
  StyledLinkHeader,
  StyledIconSpan,
  StyledCard,
  StyledCardText,
  StyledCardHeader,
} from './style';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
//import { exclamationCandidate } from 'helpers/Exclamation';
import { DateTime } from 'luxon';

interface Props {
  contents: Array<Contents>;
}

interface Contents {
  name: any;
  phone: any;
  options: any;
  state: any;
  workflow: any;
  date: any;
  personalInfo: any;
}

const TableCardRow: FC<Props> = ({ contents }) => {
  const isRechazado = (workflow: WorkflowInterface[]) => {
    let isRechazado = false;

    workflow.forEach((estado) => {
      estado.motivo &&
        estado.motivo.forEach((motivoElement) => {
          if (motivoElement.selected) isRechazado = true;
        });
    });
    return isRechazado;
  };

  const formatDate = (dateString: Date) => {
    return DateTime.fromISO(dateString).toRelative();
  };

  const rowsColor = (content: Contents) => {
    const contratado = content.state.workflow.some((w: WorkflowInterface) => {
      if (content.state.data === 'Contratado') {
        return true;
      } else {
        return false;
      }
    });

    if (contratado === true) {
      return '#d8ffb7';
    }

    if (content.personalInfo.state === 'activo') {
      const restaFecha = Math.round(
        Math.abs(
          parseInt(DateTime.now().valueOf()) -
            parseInt(DateTime.fromISO(content.date.data).valueOf())
        ) /
          (1000 * 60 * 60 * 24)
      );
      if (restaFecha >= 4 && restaFecha <= 7) {
        return '#FFFAC0';
      } else if (restaFecha > 7) {
        return '#FBBCAF';
      } else {
        return 'white';
      }
    } else {
      return content.state.workflow.some((workf: any) => {
        return workf.motivo?.some((motiv: any) => {
          if (
            (motiv.nombre === 'No Contactar' || motiv.nombre === 'Actitud Incompatible') &&
            motiv.selected === true
          ) {
            return true;
          } else {
            return false;
          }
        });
      })
        ? 'gray'
        : 'white';
    }
  };

  /*{content.personalInfo.state === "activo" ? <h4 style={{ color: "red", display: exclamationCandidate(content) }}>!</h4> : ""}*/

  return (
    <StyledContainer>
      {contents.length > 0 &&
        contents.map((content, i) => (
          <Fragment key={`tableRow${i}`}>
            <StyledCard>
              <StyledCardHeader>
                <StyledLinkHeader to={`${content.name.href}`}>{content.name.data}</StyledLinkHeader>
              </StyledCardHeader>

              <Card.Body style={{ background: rowsColor(content) }}>
                <StyledCardText>
                  {content.personalInfo.data.map((datos: any) => {
                    if (datos.nombre === 'Rol') {
                      if (Array.isArray(datos.valor)) return datos.valor.join(' & ');
                      else return datos.valor;
                    } else {
                      return false;
                    }
                  })}
                </StyledCardText>
                <StyledCardText>
                  {content.personalInfo.data.map((datos: any) => {
                    if (datos.nombre === 'Nivel') {
                      if (Array.isArray(datos.valor)) return datos.valor.join(' & ');
                      else return datos.valor;
                    } else {
                      return false;
                    }
                  })}
                </StyledCardText>
                <StyledCardText>
                  <small>
                    <strong>Etapa</strong>
                  </small>
                  {isRechazado(content.state.workflow) ? (
                    <div>
                      <X color='red' size={25} />
                      {content.state.data}
                    </div>
                  ) : (
                    <div>
                      <Check color='green' size={25} />
                      {content.state.data}
                    </div>
                  )}
                </StyledCardText>

                <StyledCardText>
                  <small>
                    <strong>Fecha</strong>
                  </small>
                  {formatDate(new Date(content.date.data))}
                </StyledCardText>
                <StyledCardText>
                  <small>
                    <strong>Fecha inicial</strong>
                  </small>
                  {content.state.workflow[0].fecha
                    ? DateTime.fromISO(content.state.workflow[0].fecha).toLocaleString()
                    : DateTime.fromISO(content.date.data).toLocaleString()}
                </StyledCardText>

                <StyledCardText>
                  <div>
                    {content.options.map((option: any) => {
                      if (option.href !== '' && option.href !== 'x') {
                        return (
                          <StyledIcon key={`option${option.href}`}>
                            {option.href ? (
                              <Link to={option.href}>{option.icon}</Link>
                            ) : (
                              <span>{option.icon}</span>
                            )}
                          </StyledIcon>
                        );
                      } else if (option.href === 'x') {
                        return (
                          <StyledIconSpan key={1} onClick={option.action}>
                            {option.icon}
                          </StyledIconSpan>
                        );
                      } else {
                        return (
                          <StyledIconSpan key={`option${option.href}`} onClick={option.action}>
                            {option.icon}
                          </StyledIconSpan>
                        );
                      }
                    })}
                  </div>
                </StyledCardText>
              </Card.Body>
            </StyledCard>
          </Fragment>
        ))}
    </StyledContainer>
  );
};

export default TableCardRow;
