import React, { useContext } from 'react';
import MentoringTable from '../../ui/tables/MentoringTable';
import PageHeader from '../../ui/PageHeader';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import { GetFirstElement } from 'helpers/currentPathSearch';
import ROL from 'utils/Roles';

const ListMentoring = () => {
  const { storeAuth } = useContext(AuthContext)!;
  const moduleName = () => {
    const isRoleAllowed = (): boolean => {
      const role = storeAuth.user.role?.[0];
      return role === ROL.MENTOR || role === ROL.KAM || role === ROL.ADMINISTRADOR;
    };
    if (isRoleAllowed()) {
      if (GetFirstElement() === 'mentor') {
        return 'MENTOR';
      } else if (GetFirstElement() === 'kam') {
        return 'KAM';
      }
    }
  };
  return (
    <>
      <PageHeader>Mentoreados {moduleName()}</PageHeader>
      <MentoringTable />
    </>
  );
};

export default ListMentoring;
