import type { IStack } from '../../CreateTeam.types';

export function filterStacksByNameOrTag(input: string, stacks: IStack[]) {
  const filteredStacks: IStack[] = [];

  if (!input) return stacks;

  stacks.forEach((stack) => {
    let found = false;

    if (stack.nombre.toLowerCase().includes(input.toLowerCase())) {
      filteredStacks.push(stack);
      found = true;
    }

    if (!found) {
      stack.tags.forEach((tag) => {
        if (tag.toLowerCase().includes(input.toLowerCase())) {
          filteredStacks.push(stack);
        }
      });
    }
  });

  return filteredStacks;
}

export function searchCorrectLevel(stack: IStack) {
  const correctLevel = stack.nivel.find((level) => {
    return level.valor;
  });

  return correctLevel;
}

type FilterExistingStacks = {
  searchTerm: string;
  allAvailableStacks: IStack[];
  assignedStacks: IStack[];
};

export function filterAvailableStacks({
  searchTerm,
  allAvailableStacks,
  assignedStacks,
}: FilterExistingStacks) {
  const filteredStacks = filterStacksByNameOrTag(searchTerm, allAvailableStacks);
  return filteredStacks.filter(
    (stack) => !assignedStacks.some((assignedStack) => assignedStack.nombre === stack.nombre)
  );
}
