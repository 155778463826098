import React, { FC, useCallback, useEffect, useReducer, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Divider,
  Grid,
  Slider,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import {
  SkillsInterface,
  innerSkillsInterface,
  IPreguntas,
  IRespuestas,
} from 'components/models/SkillsInterfaces';
import QuestionsComp from '../questions/QuestionsComp';
import { CandidateInterface } from 'components/models/CandidateInterfaces';
import SendIcon from '@mui/icons-material/Send';
import Swal from 'sweetalert2';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface Props {
  key: string;
  skill: SkillsInterface;
  setTemplate: (e: CandidateInterface) => void;
  template: CandidateInterface;
}

const reducer = (state: CandidateInterface) => {
  return state;
};

const InnerSkill: FC<Props> = ({ skill, setTemplate, template }) => {
  const [skills, setSkills] = useState<SkillsInterface>(Object);
  const [add, setAdd] = useState<IPreguntas>(Object);
  const [displayInput, setDisplayInput] = useState<boolean>(false);
  const [state] = useReducer(reducer, template);
  const [changeSwitchSlider, setChangeSwitchSlider] = useState<boolean>(false);
  const [dato, setDato] = useState({
    nombreSubSkill: '',
    nombreCambiado: '',
  });

  useEffect(() => {
    setSkills(skill);
  }, [skill]);

  const addNota = useCallback(() => {
    let totalSoloCheck = false; //tipo respuestas "check" se evaluan manualmente, true es = a mantener evaluacion manual
    let permiteCalculo = true;
    let validaNuevoJson = false; //donde la nota es 1.1 (version JSON)

    let suma = 0;
    let addBool = 0;
    let totalBool = 0;

    if (Object.keys(skill).length !== 0) {
      validaNuevoJson = skill.nota === 1.1 ? true : false;

      const asignaNota = skill;
      asignaNota.skills = skill.skills.map((sk: innerSkillsInterface) => {
        let changeSwitchPadre = false;
        permiteCalculo = sk.preguntas?.some((preg: IPreguntas) => {
          return preg.nombre === add.nombre ? true : false;
        })
          ? true
          : false;
        if (!permiteCalculo) {
          return sk;
        }
        sk.preguntas?.map((preg: IPreguntas) => {
          preg.respuestas.map((res: IRespuestas) => {
            if (preg.tipo === 'radio') {
              changeSwitchPadre = true;
              if (res.valor) {
                suma = suma + (res.puntaje === undefined ? 0 : res.puntaje);
              }
            } else if (preg.tipo === 'checkbox') {
              changeSwitchPadre = true;
              if (res.valor) {
                suma = suma + (res.puntaje === undefined ? 0 : res.puntaje);
              }
            } else if (preg.tipo === 'check') {
              changeSwitchPadre = true;
              totalSoloCheck = true;
            }
            return res;
          });
          if (preg.tipo === 'checkbox' && !validaNuevoJson) {
            if (totalBool === addBool) {
              suma = suma + preg.puntajeMaximo;
            } else if (addBool >= totalBool / 2) {
              suma = suma + preg.puntajeMaximo / 2;
            }
          }
          addBool = 0;
          totalBool = 0;
          return preg;
        });
        if (!totalSoloCheck) {
          sk.nota = suma;
        }
        totalSoloCheck = false;
        suma = 0;
        if (changeSwitchPadre) {
          asignaNota.estado = true;
        }
        return sk;
      });
      return asignaNota;
    } else {
      return skill;
    }
  }, [skill, add.nombre]);

  const cambiaSwitchPadre = useCallback(
    (sk) => {
      const skill = state.skills?.map((s: SkillsInterface) => {
        if (sk.nombre === s.nombre) {
          return sk;
        }
        return s;
      });
      return { ...state, skills: skill };
    },
    [state]
  );

  useEffect(() => {
    const sk = addNota();
    const s = cambiaSwitchPadre(sk);
    setSkills({ ...sk });
    setTemplate(s);
  }, [addNota, add, cambiaSwitchPadre, setTemplate]);

  useEffect(() => {
    if (changeSwitchSlider) {
      const s = state.skills?.map((sk: SkillsInterface) => {
        if (skill.nombre === sk.nombre) {
          sk.estado = true;
        }
        return sk;
      });
      setTemplate({ ...state, skills: s });
    }
  }, [changeSwitchSlider, skill, state, setTemplate]);

  const handleInputChange = (e: string, nombre: string) => {
    setDato({ nombreSubSkill: nombre, nombreCambiado: e });
  };

  const cambioNombreSkill = (event: any) => {
    event.preventDefault();
    try {
      const sk = skill.skills.map((inner: innerSkillsInterface) => {
        if (inner.nombre === dato.nombreSubSkill) {
          inner.nombre = dato.nombreCambiado;
        }
        return inner;
      });
      setSkills({ ...skills, skills: sk });
      setDisplayInput(!displayInput);
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'No se pudo guardar la edicion del nombre en esta habilidad',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
    }
  };

  return (
    <>
      {skill &&
        skill.skills?.map((innerSkill: innerSkillsInterface, i: number) => {
          return (
            <Card
              key={`card${innerSkill.nombre}${i}`}
              sx={{
                width: 'calc(100vw - 80px)',
                maxWidth: 920,
                padding: 0,
                margin: 0,
                boxShadow: '0',
              }}
            >
              <CardContent>
                <Grid
                  container
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Accordion sx={{ width: '100%' }} defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel1a-content'
                      id='panel1a-header'
                    >
                      <Grid item xs={1}>
                        <Switch
                          sx={{ marginTop: '1rem' }}
                          defaultChecked={innerSkill.estado !== false}
                          onChange={(e) => {
                            if (innerSkill.estado !== false) {
                              innerSkill.estado = false;
                            } else {
                              innerSkill.estado = true;
                            }
                            setSkills({ ...skills, skills: skills.skills });
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={9}
                        container
                        spacing={2}
                        sx={{ marginTop: '10px', fontSize: 15, display: 'flex' }}
                      >
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                          {displayInput ? (
                            <form onSubmit={cambioNombreSkill}>
                              <TextField
                                sx={{ marginRight: '12px', size: 'small' }}
                                id={`nom-skill${innerSkill.nombre}`}
                                defaultValue={innerSkill.nombre}
                                size='small'
                                variant='outlined'
                                type='text'
                                name='nombreSkill'
                                onChange={(e) =>
                                  handleInputChange(e.target.value, innerSkill.nombre)
                                }
                              />
                              <Button variant='contained' endIcon={<SendIcon />} type='submit'>
                                Guardar
                              </Button>
                            </form>
                          ) : (
                            <Typography
                              sx={{ margin: '15px', size: 'small' }}
                              color='text.primary'
                              gutterBottom
                            >
                              {innerSkill.nombre}
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={2}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
                          <Checkbox
                            id={`${i}`}
                            checked={displayInput}
                            sx={{ marginTop: '-10px' }}
                            onChange={() => setDisplayInput(!displayInput)}
                          />
                          <Typography color='text.primary'>Editar</Typography>
                        </Box>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Divider sx={{ marginBottom: 2 }} />

                      <Typography color='text.secondary' gutterBottom>
                        Nota
                      </Typography>
                      <Slider
                        sx={{
                          marginBottom: '8px',
                        }}
                        onChange={(e: Event, value: number | number[]) => {
                          setChangeSwitchSlider(true);
                          innerSkill.nota = value;
                          setSkills({ ...skills, skills: skill.skills });
                        }}
                        id={`nota`}
                        value={parseFloat(innerSkill.nota.toFixed(1))}
                        step={0.1}
                        min={0}
                        max={10}
                        valueLabelDisplay='auto'
                      />

                      <CardActions>
                        <Box component='div' sx={{ display: 'flex', width: '100%' }}>
                          <QuestionsComp innerSkills={innerSkill} setAdd={setAdd} />
                        </Box>
                      </CardActions>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </CardContent>
            </Card>
          );
        })}
    </>
  );
};

export default InnerSkill;
