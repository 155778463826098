import React, { useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useParams, useHistory } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import QueueIcon from '@mui/icons-material/Queue';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PsychologyIcon from '@mui/icons-material/Psychology';
import MarkdownEditor from '../../ui/markdownEditor';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import getAxios from 'apiRestConnector';
import FullscreenLoading from '../../ui/loading/FullscreenLoading';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import FullscreenAlert from '../../ui/alerts/FullscreenAlert';
import {
  IRoadmap,
  IRoadmapActivity,
  IRoadmapObjective,
  RoadmapStatus,
  IRoadmapResponse,
} from './RoadmapManager.types';
import PageHeader from '../../ui/PageHeader';
import RecommendationsProgress from './RecommendationsProgress';
import SwalAlert from 'components/ui/alerts/SwalAlert';

// TODO: Datos hardcodeados para los select, a futuro se deberían traer desde otra fuente.
const activityPlatforms = [
  'Udemy',
  'Platzi',
  'YouTube',
  'Frontend Masters',
  'Whizlabs',
  'Coursera',
  'Codely',
  'Crehana',
  'GitHub',
];
const activityTypes = ['Curso', 'Bootcamp', 'YouTube', 'Taller', 'Video', 'Proyecto', 'Evaluación'];
const activityStatus = ['Pendiente', 'En progreso', 'Finalizado'];

const expectedDurationInSeconds = 210;

async function fetchRoadmapRecommendations(idMentee: string, accessToken: string) {
  const menteeDataUrl = `/mentee/${idMentee}/roadmap/generate/preinfo`;
  const roadmapGenerationUrl: string = process.env.REACT_APP_API_URL_ROADMAP || null;

  if (!roadmapGenerationUrl) {
    throw new Error('No se ha configurado la URL para la generación de Roadmaps');
  }
  let menteeData;

  try {
    menteeData = await getAxios.instance(accessToken).get(menteeDataUrl);
  } catch (error) {
    console.error(error);
    throw new Error('No se pudieron obtener los datos del mentee para generar las recomendaciones');
  }

  try {
    const response = await getAxios
      .instance(accessToken)
      .post(roadmapGenerationUrl, menteeData.data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error('Error al generar las recomendaciones, intenta de nuevo más tarde');
  }
}

const ErrorAlert = () => (
  <FullscreenAlert severity='error' alertTitle='Error'>
    El perfil Mentee no existe.
  </FullscreenAlert>
);

interface ParamType {
  idMentee: string;
}

function RoadmapManager() {
  const { idMentee } = useParams<ParamType>();
  const { storeAuth } = useContext(AuthContext)!;
  const { access_token } = storeAuth.user;
  const queryClient = useQueryClient();
  const history = useHistory();
  const [backUri, setBackUri] = useState<string>('');
  const [roadmapData, setRoadmapData] = useState<IRoadmap>();
  const roadmapPath = `/mentee/${idMentee}/roadmap`;

  const { data: fetchedData, isError } = useQuery<IRoadmap>({
    queryKey: [roadmapPath, access_token],
  });
  const showSuccesAlert = () => {
    SwalAlert({
      title: 'Guardado',
      text: `El Roadmap del Mentee ha sido guardado con éxito.`,
      icon: 'success',
      showConfirmButton: true,
      confirmButtonText: 'Volver',
      showCancelButton: true,
      cancelButtonText: 'Cerrar',
    }).then((result) => {
      if (result.isConfirmed) {
        history.push(backUri);
      }
    });
  };
  const showErrorAlert = () => {
    SwalAlert({
      title: 'Error',
      text: `Error al guardar el Roadmap del Mentee`,
      icon: 'error',
      showConfirmButton: true,
      confirmButtonText: 'Volver',
      showCancelButton: true,
      cancelButtonText: 'Cerrar',
    }).then((result) => {
      if (result.isConfirmed) {
        history.push(backUri);
      }
    });
  };

  useEffect(() => {
    if (fetchedData) {
      const updatedRoadmapData = structuredClone(fetchedData);
      for (const objective of updatedRoadmapData.objetivos) {
        if (!objective.dedicacion_semanal) {
          objective.dedicacion_semanal = '';
        }
        for (const activity of objective.actividades) {
          if (!activity.dedicacion_semanal) {
            activity.dedicacion_semanal = '';
          }
        }
      }
      setRoadmapData(updatedRoadmapData);
    }
  }, [fetchedData]);

  useEffect(() => {
    const currentMenteeUri = localStorage.getItem('currentMenteeUri');
    if (currentMenteeUri) {
      setBackUri(currentMenteeUri);
    }
  }, [setBackUri]);

  const submitRecommendations = useMutation({
    mutationFn: async () => {
      const response = await getAxios.instance(access_token).put('/mentee/roadmap', roadmapData);
      return response.data;
    },
    onSuccess: () => {
      const employeePath = `/employee/${idMentee}`;
      queryClient.resetQueries({ queryKey: [employeePath] });
      queryClient.refetchQueries({ queryKey: [employeePath] });
      queryClient.refetchQueries({ queryKey: [roadmapPath] });
      showSuccesAlert();
    },
    onError: (error) => {
      console.error(error);
      showErrorAlert();
    },
  });

  const generateRecommendations = useMutation<IRoadmapResponse>({
    mutationFn: () => fetchRoadmapRecommendations(idMentee, access_token),
    onSuccess: (response) => {
      setRoadmapData((prev) => {
        return {
          ...structuredClone(prev),
          ...response.data,
        };
      });
    },
  });

  const changeObjectivesList = (updatedObjectivesData: IRoadmapObjective[]) => {
    setRoadmapData((prev) => ({
      ...prev,
      objetivos: updatedObjectivesData,
    }));
  };

  const changeSummary = (summary: string) => {
    setRoadmapData((prev) => ({
      ...prev,
      resumen: summary,
    }));
  };

  const changeObjectiveData = (objectiveIndex: number, updatedData: Partial<IRoadmapObjective>) => {
    const updatedObjectivesData = roadmapData.objetivos;
    updatedObjectivesData[objectiveIndex] = {
      ...updatedObjectivesData[objectiveIndex],
      ...updatedData,
    };
    changeObjectivesList(updatedObjectivesData);
  };

  const changeActivityData = (
    objectiveIndex: number,
    activityIndex: number,
    updatedData: Partial<IRoadmapActivity>
  ) => {
    const updatedObjectivesData = roadmapData.objetivos;
    updatedObjectivesData[objectiveIndex].actividades[activityIndex] = {
      ...updatedObjectivesData[objectiveIndex].actividades[activityIndex],
      ...updatedData,
    };
    changeObjectivesList(updatedObjectivesData);
  };

  const deleteObjective = (objectiveIndex: number) => {
    const updatedObjectivesData = roadmapData.objetivos;
    updatedObjectivesData.splice(objectiveIndex, 1);
    changeObjectivesList(updatedObjectivesData);
  };

  const deleteActivity = (objectiveIndex: number, activityIndex: number) => {
    const updatedObjectivesData = roadmapData.objetivos;
    updatedObjectivesData[objectiveIndex].actividades.splice(activityIndex, 1);
    changeObjectivesList(updatedObjectivesData);
  };

  const addObjective = () => {
    const updatedObjectivesData = roadmapData.objetivos;
    updatedObjectivesData.push({
      nombre: '',
      descripcion: '',
      tiempo_estimado: '',
      dedicacion_semanal: '',
      estado: RoadmapStatus.PENDING,
      actividades: [],
    });
    changeObjectivesList(updatedObjectivesData);
  };

  const addActivity = (objectiveIndex: number) => {
    const updatedObjectivesData = roadmapData.objetivos;
    updatedObjectivesData[objectiveIndex].actividades.push({
      nombre: '',
      descripcion: '',
      url: '',
      plataforma: '',
      tipo: '',
      tiempo_estimado: '',
      dedicacion_semanal: '',
      estado: RoadmapStatus.PENDING,
    });
    setRoadmapData((prev) => ({
      ...prev,
      objetivos: updatedObjectivesData,
    }));
  };

  const handleRecommendationsSubmit = () => {
    submitRecommendations.mutate();
  };

  const handleRecommendationsGeneration = () => {
    generateRecommendations.mutate();
  };

  if (!roadmapData) return <FullscreenLoading />;

  if (isError) return <ErrorAlert />;

  return (
    <>
      <PageHeader>Roadmap del Mentee</PageHeader>
      <Stack direction='row' gap={2} mb={2} minHeight={48}>
        <LoadingButton
          variant='contained'
          size='large'
          startIcon={<PsychologyIcon />}
          loading={generateRecommendations.isPending}
          disabled={submitRecommendations.isPending}
          loadingPosition='start'
          onClick={handleRecommendationsGeneration}
        >
          Generar recomendaciones
        </LoadingButton>
        {generateRecommendations.isError && (
          <Alert variant='filled' severity='error'>
            {generateRecommendations.error?.message}
          </Alert>
        )}
      </Stack>
      <RecommendationsProgress
        show={generateRecommendations.isPending}
        expectedDuration={expectedDurationInSeconds}
        shouldEndProgress={generateRecommendations.isSuccess}
      />
      <Paper elevation={0} sx={{ p: 2, borderRadius: 2 }}>
        <Box>
          <Paper elevation={2} sx={{ p: 2.5, mb: 3 }}>
            <Typography fontWeight='bold' gutterBottom>
              Resumen
            </Typography>
            <MarkdownEditor
              value={roadmapData.resumen}
              onChange={(newValue) => {
                changeSummary(newValue);
              }}
              placeholder='Escribe aquí el resumen del Roadmap del Mentee'
            />
          </Paper>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))',
              gap: 3,
            }}
          >
            {roadmapData.objetivos.map((item, index) => {
              return (
                <Paper elevation={2} key={index}>
                  <Stack direction='row' alignItems='center' px={3} py={2}>
                    <Typography textTransform='uppercase' fontWeight='bold'>
                      Objetivo {index + 1}
                    </Typography>
                    <IconButton
                      title='Eliminar objetivo'
                      onClick={() => {
                        deleteObjective(index);
                      }}
                      sx={{ ml: 'auto' }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                  <Divider />
                  <Stack gap={3} px={2} py={3}>
                    <TextField
                      label='Nombre'
                      value={item.nombre}
                      onChange={(e) => {
                        changeObjectiveData(index, { nombre: e.target.value });
                      }}
                      fullWidth
                    />
                    <TextField
                      label='Descripción'
                      multiline
                      value={item.descripcion}
                      onChange={(e) => {
                        changeObjectiveData(index, { descripcion: e.target.value });
                      }}
                      fullWidth
                    />
                    <Autocomplete
                      disablePortal
                      options={activityStatus}
                      onChange={(event, newInputValue) => {
                        changeObjectiveData(index, { estado: newInputValue as RoadmapStatus });
                      }}
                      onInputChange={(event, newInputValue) => {
                        changeObjectiveData(index, { estado: newInputValue as RoadmapStatus });
                      }}
                      value={item.estado}
                      fullWidth
                      renderInput={(params) => <TextField {...params} label='Estado' />}
                    />
                    <TextField
                      label='Tiempo estimado'
                      value={item.tiempo_estimado}
                      fullWidth
                      onChange={(e) => {
                        changeObjectiveData(index, { tiempo_estimado: e.target.value });
                      }}
                    />
                    <TextField
                      label='Dedicación Semanal'
                      value={item.dedicacion_semanal}
                      variant='outlined'
                      fullWidth
                      onChange={(e) => {
                        changeObjectiveData(index, { dedicacion_semanal: e.target.value });
                      }}
                    />
                  </Stack>
                  <Divider>ACTIVIDADES</Divider>
                  <List>
                    {item.actividades &&
                      item.actividades.map((obj, aIndex) => (
                        <ListItem key={aIndex}>
                          <Accordion sx={{ width: '100%' }}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={`panel-${index}-${aIndex}-content`}
                              id={`panel-${index}-${aIndex}-header`}
                            >
                              <Typography textTransform='uppercase' fontWeight='bold'>
                                Actividad {aIndex + 1}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Stack gap={3} alignItems='center'>
                                <TextField
                                  label='Título'
                                  value={obj.nombre}
                                  fullWidth
                                  onChange={(e) => {
                                    changeActivityData(index, aIndex, {
                                      nombre: e.target.value,
                                    });
                                  }}
                                />
                                <TextField
                                  label='Descripción'
                                  value={obj.descripcion}
                                  fullWidth
                                  multiline
                                  onChange={(e) => {
                                    changeActivityData(index, aIndex, {
                                      descripcion: e.target.value,
                                    });
                                  }}
                                />
                                <TextField
                                  label='URL'
                                  value={obj.url}
                                  fullWidth
                                  onChange={(e) => {
                                    changeActivityData(index, aIndex, { url: e.target.value });
                                  }}
                                />
                                <Autocomplete
                                  id='platform-input'
                                  freeSolo
                                  fullWidth
                                  value={obj.plataforma}
                                  onChange={(event, newInputValue) => {
                                    changeActivityData(index, aIndex, {
                                      plataforma: newInputValue,
                                    });
                                  }}
                                  onInputChange={(event, newInputValue) => {
                                    changeActivityData(index, aIndex, {
                                      plataforma: newInputValue,
                                    });
                                  }}
                                  options={activityPlatforms}
                                  renderInput={(params) => (
                                    <TextField {...params} label='Plataforma' />
                                  )}
                                />
                                <Autocomplete
                                  freeSolo
                                  fullWidth
                                  value={obj.tipo}
                                  onChange={(event, newInputValue) => {
                                    changeActivityData(index, aIndex, { tipo: newInputValue });
                                  }}
                                  onInputChange={(event, newInputValue) => {
                                    changeActivityData(index, aIndex, { tipo: newInputValue });
                                  }}
                                  options={activityTypes}
                                  renderInput={(params) => <TextField {...params} label='Tipo' />}
                                />
                                <TextField
                                  label='Tiempo estimado'
                                  value={obj.tiempo_estimado}
                                  fullWidth
                                  onChange={(e) => {
                                    changeActivityData(index, aIndex, {
                                      tiempo_estimado: e.target.value,
                                    });
                                  }}
                                />
                                <TextField
                                  label='Dedicación Semanal'
                                  value={obj.dedicacion_semanal}
                                  fullWidth
                                  onChange={(e) => {
                                    changeActivityData(index, aIndex, {
                                      dedicacion_semanal: e.target.value,
                                    });
                                  }}
                                />
                                <Autocomplete
                                  disablePortal
                                  options={activityStatus}
                                  value={obj.estado}
                                  onChange={(event, newInputValue) => {
                                    changeActivityData(index, aIndex, {
                                      estado: newInputValue as RoadmapStatus,
                                    });
                                  }}
                                  onInputChange={(event, newInputValue) => {
                                    changeActivityData(index, aIndex, {
                                      estado: newInputValue as RoadmapStatus,
                                    });
                                  }}
                                  sx={{ width: '100%' }}
                                  renderInput={(params) => <TextField {...params} label='Estado' />}
                                />
                                <IconButton
                                  title='Eliminar actividad'
                                  onClick={() => {
                                    deleteActivity(index, aIndex);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Stack>
                            </AccordionDetails>
                          </Accordion>
                        </ListItem>
                      ))}
                  </List>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100px',
                    }}
                  >
                    <IconButton
                      title='Agregar actividad'
                      onClick={() => {
                        addActivity(index);
                      }}
                    >
                      <QueueIcon sx={{ fontSize: '30px' }} />
                    </IconButton>
                  </Box>
                </Paper>
              );
            })}
            <Paper
              elevation={2}
              sx={{
                minHeight: '300px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <IconButton
                title='Agregar objetivo'
                onClick={() => {
                  addObjective();
                }}
              >
                <QueueIcon sx={{ fontSize: '50px' }} />
              </IconButton>
            </Paper>
          </Box>
          <Stack direction='row' gap={2} justifyContent='center' mt={2}>
            <Button
              component={RouterLink}
              to={backUri}
              size='large'
              startIcon={<ArrowBackIcon />}
              disabled={submitRecommendations.isPending || generateRecommendations.isPending}
            >
              Volver
            </Button>
            <LoadingButton
              variant='contained'
              size='large'
              startIcon={<SaveIcon />}
              loading={submitRecommendations.isPending}
              disabled={generateRecommendations.isPending}
              loadingPosition='start'
              onClick={handleRecommendationsSubmit}
            >
              Guardar
            </LoadingButton>
          </Stack>
        </Box>
      </Paper>
    </>
  );
}

export default RoadmapManager;
