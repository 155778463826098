import React, { FC, useEffect, useState } from 'react';
import { IPreguntas, IRespuestas } from 'components/models/SkillsInterfaces';

import { Box, Checkbox, Radio, TextField, Typography } from '@mui/material';
import { OptionsInterface } from '../OptionsInterface';

import TextEditor from '../../../../ui/textEditor';

interface Props {
  questions: IPreguntas;
  setAdd: (e: IPreguntas) => void;
  options?: OptionsInterface;
}

const AnswersComp: FC<Props> = ({ questions, setAdd }) => {
  const [answersTemplate, setAnswersTemplate] = useState<IPreguntas>(Object);

  useEffect(() => {
    setAnswersTemplate(questions);
  }, [questions]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'row wrap',
        flexDirection: questions.nombre === 'Preguntas Tipo' ? 'column' : 'row',
      }}
    >
      {questions.respuestas.map((resp: IRespuestas) => {
        return (
          <Box
            key={`respuestas${resp.nombre}`}
            sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          >
            {questions.tipo === 'textArea' && (
              <>
                <Typography component='div' sx={{ marginTop: 1.3 }} gutterBottom>
                  {resp.nombre}
                </Typography>
                <TextEditor
                  value={resp.answer}
                  onChange={(value) => {
                    questions.respuestas = questions.respuestas.map((res: IRespuestas) => {
                      if (res.nombre === resp.nombre) {
                        res.answer = value;
                      }
                      return res;
                    });
                  }}
                />
              </>
            )}
            {questions.tipo === 'check' && (
              <>
                <Typography component='div' sx={{ marginTop: 1.3 }} gutterBottom>
                  {resp.nombre}
                </Typography>
              </>
            )}
            {questions.tipo === 'text' && (
              <>
                <Typography component='div' sx={{ marginTop: 1.3 }} gutterBottom>
                  {resp.nombre}
                </Typography>
                <TextField
                  onChange={(event: any) => {
                    questions.respuestas = questions.respuestas.map((res: IRespuestas) => {
                      if (res.nombre === resp.nombre) {
                        res.answer = event.target.value;
                      }
                      return res;
                    });
                  }}
                >
                  {resp.answer}
                </TextField>
              </>
            )}
            {questions.tipo === 'radio' && questions.nombre !== 'Preguntas Tipo' && (
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Radio
                  key={`${questions.nombre}${resp.nombre}`}
                  checked={resp.valor === true}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const answers = questions.respuestas.map((re: IRespuestas) => {
                      re.valor = event.target.value === re.nombre;
                      return re;
                    });
                    setAdd({ ...answersTemplate, respuestas: answers });
                    setAnswersTemplate({
                      ...answersTemplate,
                      respuestas: answers,
                    });
                  }}
                  value={resp.nombre}
                  name={questions.nombre}
                />
                <Typography component='div' sx={{ marginTop: 1.3 }} gutterBottom>
                  {resp.nombre}
                </Typography>
              </Box>
            )}
            {questions.tipo === 'checkbox' && questions.nombre !== 'Preguntas Tipo' && (
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Checkbox
                  key={`${questions.nombre}${resp.nombre}`}
                  checked={resp.valor === true}
                  // sx={{ color: '#101010a1' }}
                  value={resp.nombre}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const answers = questions.respuestas.map((re: IRespuestas) => {
                      if (event.target.value === re.nombre) {
                        re.valor = !re.valor;
                      }
                      return re;
                    });
                    setAdd({ ...answersTemplate, respuestas: answers });
                    setAnswersTemplate({
                      ...answersTemplate,
                      respuestas: answers,
                    });
                  }}
                />
                <Typography component='div' sx={{ marginTop: 1.3 }} gutterBottom>
                  {resp.nombre}
                </Typography>
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default AnswersComp;
