import { WorkflowInterface } from 'components/models/WorkFlowInterfaces';
import { Check, X } from 'react-bootstrap-icons';
import React, { FC, useContext } from 'react';
import { StyledIcon, StyledTd, StyledLink, StyledIconSpan } from './style';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import getAxios from 'apiRestConnector';
import { AuthContext } from 'services/authContext/AuthContextProvider';

import { DateTime } from 'luxon';
import { Envelope } from 'react-bootstrap-icons';
import SwalAlert from 'components/ui/alerts/SwalAlert';

interface Props {
  contents: Array<Contents>;
  refrescaCandidatos: boolean;
  setRefrescaCandidatos: (r: boolean) => void;
  invitacionesCandidatos: string[];
  setInvitacionesCandidatos: (i: string[]) => void;
}

interface Contents {
  name: any;
  phone: any;
  options: any;
  state: any;
  workflow: any;
  date: any;
  personalInfo: any;
}

const TableRowInvitations: FC<Props> = ({
  contents,
  refrescaCandidatos,
  setRefrescaCandidatos,
  invitacionesCandidatos,
  setInvitacionesCandidatos,
}) => {
  const { storeAuth } = useContext(AuthContext)!;
  const { access_token } = storeAuth.user;
  const { REACT_APP_BACKEND_URL } = process.env;

  const rowsColor = (content: Contents) => {
    const contratado = content.state.workflow.some((w: WorkflowInterface) => {
      if (content.state.data === 'Contratado') {
        return true;
      } else {
        return false;
      }
    });

    if (contratado === true) {
      return '#d8ffb7';
    }

    if (content.personalInfo.state === 'activo') {
      const restaFecha = Math.round(
        Math.abs(
          parseInt(DateTime.now().valueOf()) -
            parseInt(DateTime.fromISO(content.date.data).valueOf())
        ) /
          (1000 * 60 * 60 * 24)
      );
      if (restaFecha >= 4 && restaFecha <= 7) {
        return '#FFF1C1';
      } else if (restaFecha > 7) {
        return '#FFE1DC';
      } else {
        return 'white';
      }
    } else {
      return content.state.workflow.some((workf: any) => {
        return workf.motivo.some((motivo: any) => {
          if (
            (motivo.nombre === 'No Contactar' || motivo.nombre === 'Actitud Incompatible') &&
            motivo.selected === true
          ) {
            return true;
          } else {
            return false;
          }
        });
      })
        ? '#C9CCD2'
        : 'white';
    }
  };

  const isRechazado = (workflow: WorkflowInterface[]) => {
    let isRechazado = false;

    workflow.forEach((estado) => {
      estado.motivo &&
        estado.motivo.forEach((motivoElement) => {
          if (motivoElement.selected) isRechazado = true;
        });
    });
    return isRechazado;
  };

  const handleEndPoint = (obj: Object) => {
    SwalAlert({
      title: 'Enviando invitaciones',
      text: 'Espere un momento',
    });

    getAxios
      .instance('')
      .put(`${REACT_APP_BACKEND_URL}/invitaciones`, obj, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then(() => {
        SwalAlert({
          title: 'Envio exitoso',
          icon: 'success',
          confirmButtonText: 'Entendido',
          showConfirmButton: true,
        });

        setRefrescaCandidatos(true);
      })
      .catch((error) => {
        SwalAlert({
          title: 'Error en la consulta',
          icon: 'error',
          confirmButtonText: 'Entendido',
          showConfirmButton: true,
        });
      });
  };

  const invitacionSimple = (telefono: string, datos: string[], nombre: string) => {
    let res = '';
    SwalAlert({
      title: 'Invitar postulante/s',
      text: 'Seleccione una plantilla',
      input: 'select',
      inputOptions: { '1': 'Invitación exclusiva' },
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      showConfirmButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        res = result.value as string;

        const correo = datos.filter((d: any) => d.nombre === 'Correo').map((c: any) => c.valor);

        const obj = {
          template: res,
          datos: [
            {
              telefono: telefono,
              correo: correo,
              nombre: nombre,
            },
          ],
        };

        handleEndPoint(obj);
      }
    });
  };

  const changeCheck = (telefono: string) => {
    if (invitacionesCandidatos.includes(telefono)) {
      const indice = invitacionesCandidatos.findIndex((x: string) => x === telefono);

      invitacionesCandidatos.splice(indice, 1);

      setInvitacionesCandidatos([...invitacionesCandidatos]);
    } else {
      invitacionesCandidatos.push(telefono);
      setInvitacionesCandidatos([...invitacionesCandidatos]);
    }
  };

  return (
    <>
      {contents.length > 0 &&
        contents.map((content, i) => (
          <tr style={{ background: rowsColor(content) }} key={`tableRow${i}`}>
            <StyledTd>
              <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: '2vw' }}>
                <Form.Check
                  checked={invitacionesCandidatos.includes(content.personalInfo.fono)}
                  onChange={() => changeCheck(content.personalInfo.fono)}
                ></Form.Check>
                <Envelope
                  style={{ cursor: 'pointer', marginTop: '4px', marginLeft: '2vw' }}
                  onClick={() =>
                    invitacionSimple(
                      content.personalInfo.fono,
                      content.personalInfo.data,
                      content.name.data
                    )
                  }
                />
              </div>
            </StyledTd>
            <StyledTd>
              <StyledLink to={`${content.name.href}`}>{content.name.data}</StyledLink>
            </StyledTd>
            <StyledTd>
              <StyledLink to={`${content.name.href}`}>
                {content.personalInfo.data.map((datos: any) => {
                  if (datos.nombre === 'Nivel') {
                    if (Array.isArray(datos.valor)) return datos.valor.join(' & ');
                    else return datos.valor;
                  } else {
                    return false;
                  }
                })}
              </StyledLink>
            </StyledTd>
            <StyledTd>
              <StyledLink to={`${content.name.href}`}>
                {isRechazado(content.state.workflow) ? (
                  <X color='red' size={25} />
                ) : (
                  <Check color='#00af5a' size={25} />
                )}
                {content.state.data}
              </StyledLink>
            </StyledTd>
            <StyledTd>
              <StyledLink to={`${content.name.href}`}>
                {content.date.invitacion === undefined
                  ? ''
                  : DateTime.fromISO(content.date.invitacion).toLocaleString()}
              </StyledLink>
            </StyledTd>
            <StyledTd>
              <StyledLink to={`${content.name.href}`}>
                <div>{DateTime.fromISO(content.date.data).toRelative()}</div>
              </StyledLink>
            </StyledTd>
            <StyledTd>
              <StyledLink to={`${content.name.href}`}>
                {/* PRIMEAR ETAPA */}
                <div>
                  {content.state.workflow[0].fecha
                    ? DateTime.fromISO(content.state.workflow[0].fecha).toLocaleString()
                    : DateTime.fromISO(content.date.data).toLocaleString()}
                </div>
              </StyledLink>
            </StyledTd>
            <StyledTd>
              {content.options.map((option: any) => {
                if (option.href) {
                  return (
                    <StyledIcon key={`option${option.href}`}>
                      <Link to={option.href}>{option.icon}</Link>
                    </StyledIcon>
                  );
                } else {
                  return (
                    <StyledIconSpan key={`option${option.href}`} onClick={option.action}>
                      {option.icon}
                    </StyledIconSpan>
                  );
                }
              })}
            </StyledTd>
          </tr>
        ))}
    </>
  );
};

export default TableRowInvitations;
