import React from 'react';
import { Box, Pagination } from '@mui/material';
import { useCandidateFinder } from '../CandidateFinderContext';

const PaginationControl: React.FC = () => {
  const { currentPage, totalPages, handlePageChange } = useCandidateFinder();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        color='primary'
      />
    </Box>
  );
};

export default PaginationControl;
