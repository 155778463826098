import React from 'react';
import { Container, Typography } from '@mui/material';
import { CandidateFinderProvider } from './CandidateFinderContext';
import SearchForm from './components/SearchForm';
import ResultsTable from './components/ResultsTable';
import PaginationControl from './components/PaginationControl';

const CandidateFinderContent: React.FC = () => {
  return (
    <Container maxWidth='lg' sx={{ py: 4 }}>
      <Typography
        variant='h4'
        align='center'
        gutterBottom
        sx={{
          textAlign: 'center',
          background: 'linear-gradient(to right, rgb(3, 147, 204), rgb(242, 103, 36)) text',
          color: 'transparent',
        }}
      >
        Búsqueda de candidatos con IA
      </Typography>

      <SearchForm />
      <ResultsTable />
      <PaginationControl />
    </Container>
  );
};

const CandidateFinder: React.FC = () => (
  <CandidateFinderProvider>
    <CandidateFinderContent />
  </CandidateFinderProvider>
);

export default CandidateFinder;
