import React, { FC, useEffect, useState } from 'react';
import {
  StyledContainer,
  StyledInputArea,
  StyledProfile,
  StyledInput,
  ProfileImageContainer,
  ProfileImage,
} from './style';
import { Button, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SwalAlert from 'components/ui/alerts/SwalAlert';

interface Props {
  setFile: (file: any) => void;
  file: any;
  profileUrl: string;
}

const MAX_SIZE_IN_MB = 5;
const ONE_MB_IN_BYTES = 1048576;
const MAX_SIZE_IN_BYTES: number = MAX_SIZE_IN_MB * ONE_MB_IN_BYTES;

const FormInputFile: FC<Props> = ({ file, setFile, profileUrl }) => {
  const [profileImg, setProfileImg] = useState<string>('');

  const verifyFileSizeAndType = (selectedFile: any) => {
    if (selectedFile.length > 0) {
      file && setFile(null);

      if (selectedFile[0].size > MAX_SIZE_IN_BYTES) {
        SwalAlert({
          icon: 'warning',
          text: `La foto no debe pesar más de ${MAX_SIZE_IN_MB} MB`,
          confirmButtonText: 'Entendido',
          showConfirmButton: true,
        });
        return false;
      } else {
        if (selectedFile[0].type !== 'image/png' && selectedFile[0].type !== 'image/jpeg') {
          SwalAlert({
            icon: 'warning',
            text: `La foto debe estar en formato .png o .jpg`,
            confirmButtonText: 'Entendido',
            showConfirmButton: true,
          });
          return false;
        }
      }
      return true;
    }
  };

  const saveVerifiedImage = (files) => {
    if (verifyFileSizeAndType(files)) {
      const selectedFile = files[0];
      setFile(selectedFile);
    } else {
      resetImage();
    }
  };

  const onPasteCapture = (e: any) => {
    const files = e.clipboardData.files;
    if (files.length > 0) {
      saveVerifiedImage(files);
    }
  };

  const onChange = (e: any) => {
    e.preventDefault();
    const files = e.target.files;
    if (files.length > 0) {
      saveVerifiedImage(files);
    }
  };

  function resetImage() {
    const fileInput = document.getElementById('input-img') as HTMLInputElement;
    fileInput.value = '';
    setFile(undefined);
    setProfileImg(undefined);
  }

  useEffect(() => {
    setProfileImg(profileUrl);
  }, [profileUrl]);

  return (
    <StyledContainer onPasteCapture={onPasteCapture}>
      <StyledInputArea>
        <StyledInput>
          <input id='input-img' type='file' accept='image/png, image/jpeg' onChange={onChange} />
          <CloudUploadIcon color='action' />
        </StyledInput>
        <Typography component='span' variant='caption' color='text.secondary'>
          Cargue o pegue la foto
        </Typography>
      </StyledInputArea>

      <StyledProfile>
        {!file && !profileImg ? (
          <AccountBoxIcon sx={{ fontSize: 200, color: 'divider' }} />
        ) : file || (profileImg && !file) ? (
          <ProfileImageContainer>
            <ProfileImage src={file ? URL.createObjectURL(file) : profileImg} alt='profile image' />
            <Button
              onClick={resetImage}
              color='error'
              sx={{ marginTop: '5px' }}
              variant='outlined'
              startIcon={<ClearIcon />}
              title='Eliminar foto actual'
            >
              Eliminar
            </Button>
          </ProfileImageContainer>
        ) : (
          ''
        )}
      </StyledProfile>
    </StyledContainer>
  );
};

export default FormInputFile;
