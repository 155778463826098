import { useContext, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import getAxios from 'apiRestConnector';
import { AuthContext } from 'services/authContext/AuthContextProvider';

interface UseShareLinkProps {
  phone: number;
}

interface ShareLinkResponse {
  uuid: string;
  phone: string;
  expirationDate: string;
  createdAt: string;
  updatedAt: string;
}

export const useShareLink = ({ phone }: UseShareLinkProps) => {
  const { storeAuth } = useContext(AuthContext)!;
  const { access_token } = storeAuth.user;
  const axiosInstance = getAxios.instance(access_token);

  const [isShareActive, setIsShareActive] = useState(false);

  const shareLinkQuery = useQuery({
    queryKey: ['shareLink', phone],
    queryFn: async () => {
      const { data } = await axiosInstance.get<ShareLinkResponse>(`/personas/public/link/${phone}`);
      return data;
    },
    enabled: false
  });

  const getShareLink = async () => {
    try {
      const result = await shareLinkQuery.refetch();
      if (result.data) {
        const baseUrl = window.location.origin;
        const shareUrl = `${baseUrl}/publicview/${result.data.uuid}`;
        setIsShareActive(!isShareActive);
        return shareUrl;
      }
    } catch (error) {
      console.error('Error al obtener el enlace de compartir:', error);
    }
  };

  const handleCopyToClipboard = async () => {
    try {
      const shareUrl = await getShareLink();
      if (shareUrl) {
        await navigator.clipboard.writeText(shareUrl);
      }
    } catch (err) {
      console.error('Error al copiar al portapapeles:', err);
    }
  };

  return {
    shareLink: shareLinkQuery.data ? `${window.location.origin}/publicview/${shareLinkQuery.data.uuid}` : '',
    isShareActive,
    getShareLink,
    handleCopyToClipboard,
    isLoading: shareLinkQuery.isLoading,
    error: shareLinkQuery.error
  };
}; 