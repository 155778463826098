import React, { FC } from 'react';

import DOMPurify from 'dompurify';
import { CompetenciasInterface } from 'components/models/SoftSkillsInterfaces';
import {
  ButtonTitle,
  ColorLi,
  CompetenciasButton,
  CompetenciasContainer,
  CompetenciasInfo,
  CompetenciasUL,
  StyledVirtuesDefectsContainer,
} from '../style';

interface Props {
  softSkills: CompetenciasInterface[];
}

const renderCompetenciasItems = (softSkillValor: string, index: number) => {
  return (
    <ColorLi key={index}>
      <CompetenciasInfo dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(softSkillValor) }} />
    </ColorLi>
  );
};

const renderContent = (valorArray: string[]) => {
  return valorArray.length === 1 ? (
    <CompetenciasInfo dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(valorArray[0]) }} />
  ) : (
    <CompetenciasUL>{valorArray.map(renderCompetenciasItems)}</CompetenciasUL>
  );
};

const isEmptyContent = (content: string | string[]): boolean => {
  if (Array.isArray(content)) {
    return content.every(isEmptyContent);
  }

  const textContent = content.replace(/<[^>]*>/g, '').trim();
  return !textContent;
};

const renderCompetenciasContainer = (softSkill: CompetenciasInterface, index: number) => {
  const { nombre, valor } = softSkill;
  const valorArray = Array.isArray(valor) ? valor : [valor];

  if (isEmptyContent(valorArray)) return null;

  const content = renderContent(valorArray);

  return (
    <CompetenciasContainer key={`${nombre}-${index}`}>
      <CompetenciasButton>
        <ButtonTitle>{nombre}</ButtonTitle>
      </CompetenciasButton>
      {content}
    </CompetenciasContainer>
  );
};

const NewSoftSkillsComp: FC<Props> = ({ softSkills }) => {
  return (
    <StyledVirtuesDefectsContainer>
      {softSkills?.map(renderCompetenciasContainer)}
    </StyledVirtuesDefectsContainer>
  );
};

export default NewSoftSkillsComp;
