import { QueryFunctionContext } from '@tanstack/react-query';
import { ApiResponse, RowData } from '../../components/pages/candidateFinder/types';
import getAxios from '../../apiRestConnector';

const APP_HUNTER_AI_URL = process.env.REACT_APP_PYTHON_AI;
const REACT_APP_PYTHON_AI_API_KEY = process.env.REACT_APP_PYTHON_AI_API_KEY;

export const fetchCandidates = async ({
  queryKey,
}: QueryFunctionContext<[string, string]>): Promise<{
  candidates: Omit<RowData, 'contact'>[];
  jsonTagsDataSearch: ApiResponse['json_tags_data_search'] | null;
}> => {
  const [, text_search] = queryKey;

  const response = await getAxios.instance('', APP_HUNTER_AI_URL).post<ApiResponse>(
    `${APP_HUNTER_AI_URL}/cv/search/ai`,
    {
      text_search,
    },
    {
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, OPTIONS',
        'api-key-auth': REACT_APP_PYTHON_AI_API_KEY,
      },
    }
  );

  if (!response.data?.result_search?.length) {
    return {
      candidates: [],
      jsonTagsDataSearch: null,
    };
  }

  return {
    candidates: response.data.result_search.map((item) => {
      return {
        match: item.match.toString(),
        score: item.score.toString(),
        proporcion: item.proporcion.toString(),
        pais: item.pais,
        nombre: item.nombre,
        email: item.email,
        perfil: item.perfil,
        cv: item.cv,
        linkedin: item.linkedin,
        telefono: item.telefono,
      };
    }),
    jsonTagsDataSearch: response.data.json_tags_data_search,
  };
};
