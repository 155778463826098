import getAxios from "apiRestConnector";
import { RequestInterface } from "components/models/RequestInterfaces";
import { useContext, useEffect, useState } from "react";
import { useHistory, useRouteMatch, useParams } from "react-router-dom";
import { AuthContext } from "services/authContext/AuthContextProvider";
import { ParamType, TimeSlot } from "./Request";



const DEFAULT_DESCRIPTION =
  '<p><strong>Misión del cargo</strong></p><ul><li><p></p></li></ul><p><strong>Principales funciones</strong></p><ul><li><p></p></li></ul><p><strong>Logros esperados</strong></p><ul><li><p></p></li></ul><p><strong>Stack o herramientas</strong></p><ul><li><p></p></li></ul><p><strong>Habilidades blandas</strong></p><ul><li><p></p></li></ul>';

export const useRequest = () => {
  const { requestId } = useParams<ParamType>();
  const [filterRequestHasta, setFilterRequestHasta] = useState<Date>(new Date());
  const [filterRequestInicio, setFilterRequestInicio] = useState<Date>(new Date());
  const { storeAuth } = useContext(AuthContext)!;
  const { access_token } = storeAuth.user;
  const [roles, setRoles] = useState<string[]>(Array);
  const [modalidades, setModalidades] = useState<string[]>(Array);
  const [niveles, setNiveles] = useState<string[]>(Array);
  const [stack, setStack] = useState<string[]>(Array);
  const [externalAssociations, setExternalAssociations] = useState<string[]>([]);
  const [blockedHoursPerDay, setBlockedHoursPerDay] = useState<{ [key: string]: TimeSlot[] }>({});
  const [scoreNotaDeCorte, setScoreNotaDeCorte] = useState<number>(0);
  const [solicitud, setSolicitud] = useState({
    solicitud: [
      {
        nombre: '',
        valor: '',
      },
    ],
    stack: [
      {
        nombre: '',
        valor: '',
      },
    ],
    salario: [
      {
        nombre: '',
        valor: '',
      },
    ],
    externalAssociations: [
      {
        nombre: '',
        valor: '',
      },
    ],
  });

  const [template, setTemplate] = useState<RequestInterface>({
    descripcion: DEFAULT_DESCRIPTION,
    empresa: '',
    estado: 'Abierto',
    fechaEstimadaFin: new Date().toString(),
    fechaFin: '',
    fechaInicio: new Date().toString(),
    id: Date.now(),
    nivel: '',
    modalidad: '',
    plazo: '',
    prioridad: '',
    rol: [],
    salario: [{ min: '0', max: '0', moneda: '' }],
    responsable: '',
    equipo: '',
    solicitante: '',
    stack: [],
    managerEmail: '',
    managerPhone: '',
    responsibleEmail: '',
    responsiblePhone: '',
    externalAssociations: [],
    score_nota_de_corte: 0,
  });





    useEffect(() => {
    getAxios
      .instance(access_token)
      .get('/templates/skills/')
      .then((result: any) => result.data)
      .then((templatesData: any) => {
        const roles = templatesData.datos.find(
          (template: any) => template.nombre.toLowerCase() === 'rol'
        );
        setRoles(roles?.valor ?? []);

        const modalidades = templatesData.datos.find(
          (template: any) => template.nombre.toLowerCase() === 'modalidad'
        );
        setModalidades(modalidades?.valor ?? []);

        const niveles = templatesData.datos.find(
          (template: any) => template.nombre.toLowerCase() === 'nivel'
        );
        setNiveles(niveles?.valor ?? []);
      })
      .catch((error) => {
        console.error('error:', error.message);
      });

    getAxios
      .instance(access_token)
      .get('/templates/solicitud')
      .then((result: any) => result.data)
      .then((templatesData: any) => {
        setSolicitud(templatesData);

        templatesData.solicitud.forEach((res: any) => {
          if (res.nombre === 'stack') {
            setStack(res.valor);
          } else if (res.nombre === 'externalAssociations') {
            setExternalAssociations(res.valor);
          }
        });
      })
      .catch((error) => {
        console.error('error:', error.message);
      });

    if (requestId !== undefined) {
      getAxios
        .instance(access_token)
        .get(`/solicitudes/${requestId}`)
        .then((result: any) => result.data)
        .then((templatesData: RequestInterface) => {
          setFilterRequestHasta(new Date(templatesData.fechaEstimadaFin));
          setFilterRequestInicio(new Date(templatesData.fechaInicio));
          setTemplate(templatesData);
          setBlockedHoursPerDay(templatesData.blockedHoursPerDay ?? {});
          setScoreNotaDeCorte(templatesData.score_nota_de_corte ?? 0);
        })
        .catch((error) => {
          console.error('error:', error.message);
        });
    }
  },  [access_token, requestId]);

  useEffect(() => {
    if (template.plazo && filterRequestInicio) {
      const plazo = parseInt(template.plazo);
      if (!isNaN(plazo)) {
        const nuevaFechaFin = new Date(filterRequestInicio);
        nuevaFechaFin.setDate(nuevaFechaFin.getDate() + plazo);
        setFilterRequestHasta(nuevaFechaFin);
      }
    }
  }, [template.plazo, filterRequestInicio]);

  return {
    roles,
    modalidades,
    niveles,
    stack,
    externalAssociations,
    blockedHoursPerDay,
    scoreNotaDeCorte,
    filterRequestHasta,
    filterRequestInicio,
    template,
    solicitud,
    
    setRoles,
    setModalidades,
    setNiveles,
    setStack,
    setExternalAssociations,
    setBlockedHoursPerDay,
    setScoreNotaDeCorte,
    setFilterRequestHasta,
    setFilterRequestInicio,
    setTemplate,
    setSolicitud
  }
}