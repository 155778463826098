import useBeforeUnload from 'hooks/useBeforeUnload';
import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import useSaveShortcut from '../../../hooks/useSaveShortcut';
import { useShareLink } from 'hooks/useShareLink';

import getAxios from 'apiRestConnector';
import { findLast } from 'lodash';

import SoftSkillsComponent from 'components/pages/newCandidate/softSkillsComponent/SoftSkillsComponent';
import SummaryComponent from 'components/pages/newCandidate/summaryComponent/SummaryComponent';
import WorkflowComponent from 'components/pages/newCandidate/workflowComponent/WorkflowComponent';

import FormInputFile from 'components/ui/forms/formInputFile/FormInputFile';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import PersonalInfoComp from './personalInfoComp/PersonalInfoComp';
import RequestComponent from './requestComponent/RequestComponent';

import ArrowBackIcon from '@mui/icons-material/ArrowBack'; // Agregar este import
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ShareIcon from '@mui/icons-material/Share';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { CandidateInterface } from 'components/models/CandidateInterfaces';
import { DatosInterface } from 'components/models/PersonalInformationInterfaces';
import { SkillsInterface } from 'components/models/SkillsInterfaces';
import { CompetenciasInterface } from 'components/models/SoftSkillsInterfaces';
import { WorkflowInterface } from 'components/models/WorkFlowInterfaces';
import SwalAlert from 'components/ui/alerts/SwalAlert';
import { MuiTelInput } from 'mui-tel-input';
import { DivGlowEffect } from 'styles/glowingComponent';
import Swal from 'sweetalert2';
import { verifyPhone } from '../../../helpers/verifyPhone';
import FullscreenLoading from '../../ui/loading/FullscreenLoading';
import ContactModal from '../../ui/phone/ContactModal';
import SkillCompMenu from './skillsComponent/SkillCompMenu';
import WorkflowEvents from './workflowEvent/WorkflowEvent';

const tooltipSaveShortcutMessage = (() => {
  let shortcutKey = 'Control';
  const isMac = navigator.userAgent.indexOf('Mac') !== -1;
  if (isMac) shortcutKey = '⌘';
  return `También puedes guardar con ${shortcutKey}+S`;
})();

interface ParamType {
  phone: string;
}

const NewCandidate = () => {
  const history = useHistory();
  const { phone } = useParams<ParamType>();
  const { storeAuth } = useContext(AuthContext)!;
  const userName = storeAuth.user.name;
  const { access_token } = storeAuth.user;
  const { REACT_APP_BACKEND_URL } = process.env;
  const [template, setTemplate] = useState<CandidateInterface>(Object);
  const [nombre, setNombre] = useState('');
  const [email, setEmail] = useState('');
  const [telefono, setTelefono] = useState<string>('');
  const [zerosAdded, setZerosAdded] = useState<number>(0);
  const [tagged, setTagged] = useState<boolean>(false);
  const [openContactModal, setOpenContactModal] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [profileUrl, setProfileUrl] = useState('');
  const [file, setFile] = useState<any>(null);
  const [editPhone, setEditPhone] = useState<boolean>(false);
  const [solicitudCandidate, setSolicitudCandidate] = useState<number[]>([]);
  const [estadoActualTemplate, setEstadoActualTemplate] = useState(0);
  const [listadoNombres, setListadoNombres] = useState<string[]>([]);
  const [nameSelected, setNameSelected] = useState<string>('');
  const [isEdit, setIsEdit] = useState<boolean>();
  const [validPhone, setValidPhone] = useState<boolean>(false);
  const [skillsTemplate, setSkillsTemplate] = useState<CandidateInterface>(Object);
  const [didLoad, setDidLoad] = useState<boolean>(false);
  const [skillsLoaded, setSkillsLoaded] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const isNewCandidate = useRouteMatch('*/new');
  const isExistingCandidate = !isNewCandidate;
  const setShouldConfirmExit = useBeforeUnload();
  const [isEventsActive, setIsEventsActive] = useState(false);

  // Usar useMemo para obtener el número de teléfono en formato correcto
  const phoneNumber = useMemo(() => {
    const cleaned = telefono.replace(/[^0-9]+/g, '');
    return cleaned ? parseInt(cleaned) : 0;
  }, [telefono]);

  // Usar el hook useShareLink con el phoneNumber calculado
  const { 
    shareLink, 
    isShareActive, 
    getShareLink, 
    handleCopyToClipboard,
    isLoading: isLoadingShareLink 
  } = useShareLink({ 
    phone: phoneNumber
  });

  useEffect(() => {
    //El template siempre es consultado, ya que sirve para crear y editar
    //En caso de edit candidate, se envía un skillsTemplate (general) y template (candidato)
    //En caso de new candidate, se envían ambos igualados al skillsTemplate (general)

    getAxios
      .instance(access_token)
      .get('/templates/skills/')
      .then((result: any) => result.data)
      .then((templatesData: any) => {
        const resultTemp = templatesData;
        resultTemp.datos = templatesData.datos.map((data: DatosInterface) => {
          if (Array.isArray(data.valor)) {
            return { nombre: data.nombre, valor: [], privado: data.privado };
          } else {
            return data;
          }
        });
        setSkillsTemplate(resultTemp);
        setSkillsLoaded(true);
      });

    window.location.href.split('/').pop() === 'edit' ? setIsEdit(true) : setIsEdit(false);
  }, [access_token]);

  useEffect(() => {
    //Esto carga solo si las skills están cargadas (es necesaria para cargar "nuevo" candidato) y es ejecutada por primera vez

    if (!didLoad && skillsLoaded) {
      //Si es que estamos editando, recogemos en template la info candidato
      //Si es que es nuevo candidato, recogemos en template la info del template general

      if (phone !== undefined) {
        getAxios
          .instance(access_token)
          .get(`/personas/${phone}`)
          .then((result: any) => result.data)
          .then((templatesData: any) => {
            if (templatesData.zerosAdded) {
              setZerosAdded(templatesData.zerosAdded);
            }

            if (typeof templatesData.telefono === 'number') {
              templatesData.telefono = templatesData.telefono.toString();
            }

            if (templatesData.telefono.toString().length === 1) {
              const textNumber =
                '1313' + Math.floor(Math.random() * (9999999 - 1000000) + 1000000).toString();
              setTelefono(textNumber);

              SwalAlert({
                html: '<h4>Se le ha asignado un número telefónico aleatorio al candidato debido a un error en el guardado</h4><br><p>Recuerde cambiar a un número válido</p>',
                icon: 'warning',
                showConfirmButton: true,
              });
              setValidPhone(true);
            } else {
              if (verifyPhone(JSON.stringify(`+${templatesData.telefono}`)) !== false) {
                setTelefono(JSON.stringify(`+${templatesData.telefono}`));
                setValidPhone(true);
              } else {
                const textNumber =
                  '1313' + Math.floor(Math.random() * (9999999 - 1000000) + 1000000).toString();
                setTelefono(textNumber);

                SwalAlert({
                  html: '<h4>Se le ha asignado un número telefónico aleatorio al candidado debido a un error en el guardado</h4><br><p>Recuerde cambiar a un número válido</p>',
                  icon: 'warning',
                  showConfirmButton: true,
                });
                setValidPhone(true);
              }
            }
            setTemplate(templatesData);
            setNombre(templatesData.nombre);
            //setTagged(templatesData.tagged);
            const email =
              templatesData.datos.find(
                (dato: DatosInterface) => dato.nombre.toLowerCase() === 'correo'
              )?.valor ?? '';
            setEmail(email);
            // setEmail(templatesData.datos[1].valor);
            setSolicitudCandidate(templatesData.solicitudes);
            setProfileUrl(templatesData.profileImgUrl);
            document.title = `${
              templatesData.nombre ? `WiTI - ${templatesData.nombre}` : 'WiTI - LevelUp'
            }`;
          });
        setDidLoad(true);
      } else {
        const textNumber =
          '1313' + Math.floor(Math.random() * (9999999 - 1000000) + 1000000).toString();
        setTemplate(skillsTemplate);
        setValidPhone(true);
        setTelefono(textNumber);
        setDidLoad(true);
      }
    }
  }, [didLoad, phone, skillsLoaded, skillsTemplate, access_token]);

  useEffect(() => {
    //Solo si tenemos ambos template, dejamos de "cargar"

    if (Object.entries(template).length > 0 && Object.entries(skillsTemplate).length > 0) {
      setLoading(false);
    }
  }, [template, skillsTemplate]);

  useEffect(() => {
    document.title = `WiTI - LevelUp`;
  }, []);

  const handleNombre = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNombre(event.target.value);
  };

  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const numberExist = async (value: string) => {
    let response = false;

    try {
      const result = await getAxios.instance(access_token).get(`/personas/${value.trim()}`);
      const entry = result.data;
      const phoneNumber = parseInt(window.location.href.split('/')[4]);

      if (
        (Number.isNaN(phoneNumber) === false && phoneNumber !== entry.telefono) ||
        Number.isNaN(phoneNumber) === true
      ) {
        SwalAlert({
          icon: 'warning',
          text: `El numero ${entry.telefono} pertenece a candidato ${entry.nombre}. ¿Desea Modificar al candidato?.`,
          showCancelButton: true,
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancelar',
          showConfirmButton: true,
        }).then((resultado) => {
          if (resultado.isConfirmed) {
            window.open(`/candidate/${entry.telefono}/edit`);
          }
          const textNumber = `+${
            '1313' + Math.floor(Math.random() * (9999999 - 1000000) + 1000000).toString()
          }`;
          setTelefono(textNumber);
          response = true;
        });
      }
    } catch (err) {
      if (err.response && err.response.status === 404) {
        console.error('Número no encontrado');
      } else {
        console.error(err);
      }
    }
    return response;
  };

  const onBlurFono = async (e: any) => {
    const response = verifyPhone(e.target.value);
    if (response === false) {
      SwalAlert({
        title: 'Error',
        text: 'Ha ingresado un número telefónico inválido',
        icon: 'error',
        confirmButtonText: 'OK',
        showConfirmButton: true,
      });
    } else if (response === undefined) {
      const isSaved: boolean = await numberExist(e.target.value.replaceAll(/[^0-9]+/g, '').trim());

      if (isSaved) {
        setValidPhone(false);
      }
      setValidPhone(true);
    } else {
      if (response === 'TOO_SHORT') {
        SwalAlert({
          text: 'El número telefónico está incompleto',
          icon: 'warning',
          confirmButtonText: 'OK',
          showConfirmButton: true,
        });
        setValidPhone(false);
      } else if (response === 'TOO_LONG') {
        SwalAlert({
          text: 'El número telefónico supera los caracteres necesarios',
          icon: 'warning',
          confirmButtonText: 'OK',
          showConfirmButton: true,
        });
        setValidPhone(false);
      }
    }
  };

  const saveCandidateServiceClient = async (data: CandidateInterface) => {
    return await getAxios.instance(access_token).post('/personas/', data);
  };

  const actualizaCandidateServiceClient = async (data: CandidateInterface) => {
    return await getAxios.instance(access_token).put(`/personas/${phone}`, data);
  };

  const saveCandidateAssociation = async (data: CandidateInterface) => {
    const { telefono, solicitudes } = data;

    if (!solicitudes) return;

    const promises = solicitudes.map((id: number) =>
      getAxios
        .instance(access_token)
        .post('/personas/asociaciones', {
          telefono: telefono,
          id: id,
        })
        .catch((error) => {
          console.error(`Error en la solicitud con id: ${id}`, error);
        })
    );

    await Promise.allSettled(promises);
  };

  const postFile = async (phoneNumber: number | string) => {
    if (file !== null && file !== undefined) {
      const image = new FormData();
      image.append('file', file);
      const response = await fetch(`${REACT_APP_BACKEND_URL}/personas/${phoneNumber}/upload`, {
        method: 'POST',
        body: image,
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      const picture = await response.json();
      template.profileImgUrl = picture.url;
      return picture.url;
    } else if (file === undefined) {
      template.profileImgUrl = '';
      return '';
    } else {
      template.profileImgUrl = profileUrl;
      return profileUrl;
    }
  };

  function getCandidateData() {
    const data = {
      ...structuredClone(template),
    };

    if (
      data.workflow[estadoActualTemplate].fecha === null ||
      data.workflow[estadoActualTemplate].fecha === ''
    ) {
      data.workflow![estadoActualTemplate].estado = true;
      const fechaActual = new Date();
      data.workflow![estadoActualTemplate].fecha = `${fechaActual.getDate()}/${
        fechaActual.getMonth() + 1
      }/${fechaActual.getFullYear()}`;
      data.workflow![estadoActualTemplate].responsable = userName;
    }

    data.competencias = data.competencias?.map((competencia: CompetenciasInterface) => {
      if (
        competencia.nombre === 'Fortalezas' ||
        competencia.nombre === 'Debilidades' ||
        competencia.nombre === 'Hobbies'
      ) {
        return {
          nombre: competencia.nombre,
          valor: (competencia.valor as string[]).filter((valor: string) => valor !== ''),
          privado: competencia.privado,
        };
      }
      return competencia;
    });

    data.skills = data.skills?.filter((sk: SkillsInterface) => sk.estado === true);

    const convertToNumber = (value: string) => Number(value.replace(/[^0-9]+/g, ''));
    const indexesOfValuesToConvertToNumber = [
      data.datos.findIndex((dato) => dato.nombre === 'Renta Actual'),
      data.datos.findIndex((dato) => dato.nombre === 'Pretenciones de Renta'),
    ];
    for (const index of indexesOfValuesToConvertToNumber) {
      if (index !== -1) {
        data.datos[index].valor = convertToNumber(String(data.datos[index].valor));
      }
    }
    data.telefono = convertToNumber(telefono);

    const correoIndex = data.datos.findIndex((dato) => dato.nombre.toLowerCase() === 'correo');
    if (correoIndex !== -1) {
      data.datos[correoIndex].valor = email;
    }

    const nombreIndex = data.datos.findIndex((dato) => dato.nombre.toLowerCase() === 'nombre');
    if (nombreIndex !== -1) {
      data.datos[nombreIndex].valor = nombre;
    }

    data.nombre = nombre;

    data.solicitudes = solicitudCandidate;
    //validar si al menos un elemento en el array data.workflow tiene como estado true
    if (data.workflow.every((workflow: WorkflowInterface) => workflow.estado === false)) {
      data.workflow[0].estado = true;
    }

    const lastActiveStageName =
      findLast({ ...data.workflow }, (workflow: WorkflowInterface) => workflow.estado === true)
        .nombre || data.workflow[0].nombre;
    data.estado = lastActiveStageName;
    return data;
  }

  const hasRequestWithTestGorilla = async (solicitudes: number[]): Promise<boolean> => {
    try {
      for (const solicitud of solicitudes) {
        const response = await getAxios
          .instance('')
          .get(`${REACT_APP_BACKEND_URL}/solicitudes/${solicitud}`, {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          });

        const externalAssociations = response.data?.externalAssociations;
        if (
          externalAssociations &&
          externalAssociations.some(
            (assoc: { nombre: string }) => assoc.nombre.toLowerCase() === 'testgorilla'
          )
        ) {
          return true;
        }
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  async function saveCandidateData(data: CandidateInterface) {
    try {
      const profileImgUrl = await postFile(Number(telefono.replace(/[^0-9]+/g, '')));
      data.profileImgUrl = profileImgUrl;

      let urlTechTestGorilla = false;
      let enviadoEmail = false;

      const testTecnico = data.workflow.find(
        (stage) => stage.nombre.toLowerCase() === 'test tecnico'
      );

      const subEtapaTestEnviado = testTecnico?.subEtapas?.find(
        (subEtapa) => subEtapa.nombre.toLowerCase() === 'test enviado'
      );

      const inTestTech = testTecnico?.estado && subEtapaTestEnviado?.estado;
      const SendTestTech = !testTecnico?.sendTestTecnicoAt || testTecnico?.sendTestTecnicoAt === '';

      if (inTestTech && SendTestTech) {
        const hasTestGorilla = await hasRequestWithTestGorilla(data.solicitudes);

        if (hasTestGorilla) {
          enviadoEmail = true;
          testTecnico.sendTestTecnicoAt = new Date().toISOString();
        } else {
          urlTechTestGorilla = true;
        }
      }

      !inTestTech && !SendTestTech && !urlTechTestGorilla && delete testTecnico.sendTestTecnicoAt;

      if (phone === telefono.replace(/[^0-9]+/g, '') || phone === undefined) {
        await saveCandidateServiceClient(data);
        await saveCandidateAssociation(data);
      } else {
        await actualizaCandidateServiceClient(data);
      }

      return {
        success: true,
        emailTestTecnico: enviadoEmail,
        urlTechTestGorilla,
      };
    } catch (error) {
      return {
        success: false,
        error: error.message,
      };
    }
  }

  async function saveCandidate(data: CandidateInterface) {
    let candidateId = '';
    let titleSucess = '¿Deseas guardar el candidato? ';
    const messageSuccess = validateWorkflowStages(data);
    if (messageSuccess) {
      titleSucess += messageSuccess;
    }
    SwalAlert({
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      icon: 'info',
      allowOutsideClick: false,
      title: titleSucess,
      confirmButtonText: 'Confirmar',
      showLoaderOnConfirm: true,
      showConfirmButton: true,
      preConfirm: async () => {
        return await saveCandidateData(data)
          .then((response) => {
            if (response.success) {
              candidateId = data.telefono.toString();
              setFile(null);
              setProfileUrl('');
              return {
                emailTestTecnico: response.emailTestTecnico,
                urlTechTestGorilla: response.urlTechTestGorilla,
              };
            } else {
              throw new Error(response.error);
            }
          })
          .catch((error) => {
            console.error(error.message);
            Swal.showValidationMessage('Ha ocurrido un error al guardar');
          });
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        setShouldConfirmExit(false);

        const titleSucess = 'El candidato ha sido guardado con éxito';

        SwalAlert({
          showCancelButton: true,
          allowOutsideClick: false,
          icon: 'success',
          title: titleSucess,
          confirmButtonText: 'Ver lista',
          cancelButtonText: 'Seguir editando',
          showConfirmButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            history.push('/');
          } else {
            // setLoading(true);

            if (candidateId) {
              // window.location.href = `/candidate/${candidateId}/edit`;
            } else {
              const errorMessage = 'No se pudo obtener el ID del candidato';
              console.error(errorMessage);
              alert(`Error: ${errorMessage}`);
              window.location.reload();
            }
          }
        });
      }
    });
  }
  const validateWorkflowStages = (data: CandidateInterface): string => {
    let message = '';
    // obtener las etapas activas del workflow
    const activeWorkflowStages: WorkflowInterface[] = data.workflow.filter(
      (stage) => stage.estado === true
    );
    activeWorkflowStages.forEach((stage) => {
      if (stage.nombre.toLowerCase() === 'test tecnico') {
        if (data.solicitudes.length > 0) {
          const subStageTechnicalTest = stage.subEtapas?.filter(
            (subStage) => subStage.estado === true
          );
          const firstSubStageTechnicalTestName = stage.subEtapas?.[0].nombre.toLowerCase();
          if (
            subStageTechnicalTest.length === 1 &&
            subStageTechnicalTest[0].nombre.toLowerCase() === firstSubStageTechnicalTestName
          ) {
            message = `Se enviará el test técnico al candidato`;
          }
        } else {
          message =
            'Sin solicitud asociada, no se puede enviar email al candidato con test técnico';
        }
      }
    });

    return message;
  };

  const removeHtmlTags = (text: string): string => {
    return text.replace(/<[^>]*>?/gm, '');
  };

  const validateWorkflow = () => {
    if (!template.workflow || template.workflow.length === 0) {
      console.log('empty workflow');
      return true;
    }
    const stagesExceptThirdAndFirst: WorkflowInterface[] = [
      ...template.workflow.slice(1, 2), // Toma los elementos antes del índice 2
      ...template.workflow.slice(3), // Toma los elementos desde el índice 3 en adelante
    ];
    const stageWithTrueState: WorkflowInterface[] = stagesExceptThirdAndFirst.filter(
      (stage) => stage.estado === true
    );

    //Validar si un elemento feedback o estrellas de stageWithTrueState es un string vacio o estrellas igual a 0, si lo es debe retorna true
    if (
      stageWithTrueState.some(
        (stage) => removeHtmlTags(stage.feedback) === '' || stage.estrellas === 0
      )
    ) {
      return true;
    }
    return false;
  };

  const handleSubmit = async () => {
    setIsSaving(true);
    if (!nombre || nombre.length < 5) {
      await SwalAlert({
        title: 'Error',
        text: 'Nombre es obligatorio y debe tener a lo menos 5 carácteres.',
        icon: 'error',
        confirmButtonText: 'OK',
        showConfirmButton: true,
      });
    } else if (!telefono) {
      await SwalAlert({
        title: 'Error',
        text: 'Teléfono es obligatorio, campo acepta solo números.',
        icon: 'error',
        confirmButtonText: 'OK',
        showConfirmButton: true,
      });
    } else if (email.length < 0) {
      if (!email.includes('@') || !email.includes('.')) {
        await SwalAlert({
          title: 'Error',
          text: 'Email debe tener un formato válido.',
          icon: 'error',
          confirmButtonText: 'OK',
          showConfirmButton: true,
        });
      }
    } else if (solicitudCandidate.length === 0) {
      await SwalAlert({
        title: 'Error',
        text: 'Debe asociar por lo menos una solicitud al perfil.',
        icon: 'error',
        confirmButtonText: 'OK',
        showConfirmButton: true,
      });
    } else if (!validPhone) {
      await SwalAlert({
        title: 'Error',
        text: 'Debe ingresar un nmero de teléfono valido',
        icon: 'error',
        confirmButtonText: 'OK',
        showConfirmButton: true,
      });
    } else if (validateWorkflow()) {
      SwalAlert({
        title: 'Error',
        text: 'Debe Completar un comentario y valorar con estrellas en alguna de las etapas del Workflow',
        icon: 'error',
        confirmButtonText: 'OK',
        showConfirmButton: true,
      });
    } else {
      const candidateData: CandidateInterface = getCandidateData();
      await saveCandidate(candidateData);
    }
    setIsSaving(false);
  };
  useSaveShortcut(handleSubmit);

  const blurVerificaNombre = (nombre: string) => {
    if (!nombre || nombre.length < 3) {
      SwalAlert({
        title: 'Error',
        text: 'Nombre es obligatorio y debe tener a lo menos 5 carácteres.',
        icon: 'error',
        confirmButtonText: 'OK',
        showConfirmButton: true,
      });
    } else {
      getAxios
        .instance(access_token)
        .get(`/personas/verificar/${nombre.trim()}`)
        .then((result: any) => result.data)
        .then((entry) => {
          if (entry.saved === true) {
            SwalAlert({
              icon: 'warning',
              text: `Ya existe un candidato con el nombre "${nombre}". ¿Desea Modificar al candidato?.`,
              showCancelButton: true,
              confirmButtonText: 'OK',
              cancelButtonText: 'Cancelar',
              showConfirmButton: true,
            }).then((resultado) => {
              if (resultado.value) {
                window.open(`/candidate/${entry.phone}/edit`);
              } else {
              }
            });
          }
        })
        .catch((error) => {
          console.error('error capturado', error);
        });
    }
  };

  const nameList = (name: string) => {
    getAxios
      .instance(access_token)
      .get(`/personas/comparar/${name.trim()}`)
      .then((result: any) => result.data)
      .then((entry) => {
        setListadoNombres(entry);
      })
      .catch((error) => {
        console.error('error capturado', error);
      });
  };

  const handleChangeName = (event: SelectChangeEvent) => {
    setNameSelected(event.target.value);
    setNombre(event.target.value);
    blurVerificaNombre(event.target.value);
  };

  useEffect(() => {
    document.getElementById('nombre')?.focus();
  }, [nombre]);

  function phoneCharacters(phoneParam: string) {
    return parseInt(phoneParam.replace(/[^0-9]+/g, ''));
  }

  const onChangeData = useCallback((data: CandidateInterface) => {
    setTemplate(data);
  }, []);

  async function deleteCandidateServiceClient(phone: string) {
    return await getAxios.instance(access_token).delete(`/personas/${phone}`);
  }

  async function handleDeleteCandidateClick() {
    const showSuccessAlert = () => {
      setShouldConfirmExit(false);

      SwalAlert({
        title: 'Eliminado',
        text: 'El candidato ha sido eliminado',
        icon: 'success',
        confirmButtonText: 'Entendido',
        allowOutsideClick: false,
        showConfirmButton: true,
      }).then(() => {
        history.replace('/');
      });
    };

    SwalAlert({
      title: '¿Estás seguro?',
      text: 'El candidato será marcado como eliminado y no aparecerá en las bsquedas, pero su información permanecerá en el sistema.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      showConfirmButton: true,
      preConfirm: async () => {
        return await deleteCandidateServiceClient(phone)
          .then(showSuccessAlert)
          .catch((error) => {
            console.error(error.message);
            Swal.showValidationMessage('Ha ocurrido un error al eliminar');
          });
      },
      allowOutsideClick: false,
    });
  }

  const handleToggleActive = async () => {
    setIsEventsActive(!isEventsActive);
  };

  // Función para manejar el copiado con notificación
  const handleCopyToClipboardWithNotification = async () => {
    await handleCopyToClipboard();
    SwalAlert({
      title: '¡Copiado!',
      text: 'El enlace ha sido copiado al portapapeles',
      icon: 'success',
      timer: 2000,
      showConfirmButton: false
    });
  };

  if (loading) return <FullscreenLoading />;

  return (
    <Container disableGutters>
      <Stack gap={2}>
        <Card sx={{}}>
          <CardHeader
            action={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <IconButton
                  onClick={isShareActive ? handleCopyToClipboardWithNotification : getShareLink}
                  color={isShareActive ? 'primary' : 'default'}
                  size='large'
                  sx={{
                    px: '20px',
                    border: 1,
                    borderColor: isShareActive ? 'primary.main' : 'divider',
                    borderRadius: 1,
                    display: { xs: isEventsActive ? 'none' : 'flex', sm: 'flex' },
                  }}
                >
                  {isShareActive ? (
                    <>
                      <Typography
                        sx={{
                          marginRight: '10px',
                          display: { xs: 'none', sm: 'block' },
                          maxWidth: '200px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          cursor: 'pointer'
                        }}
                        onClick={handleCopyToClipboardWithNotification}
                      >
                        {shareLink}
                      </Typography>
                      <ContentCopyIcon 
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCopyToClipboardWithNotification();
                        }}
                        sx={{ cursor: 'pointer' }}
                      />
                    </>
                  ) : (
                    <>
                      <ShareIcon fontSize='large' />
                      <Typography
                        sx={{
                          marginLeft: '10px',
                          display: { xs: 'none', sm: 'block' },
                        }}
                      >
                        Compartir
                      </Typography>
                    </>
                  )}
                </IconButton>
                <IconButton
                  onClick={handleToggleActive}
                  color={isEventsActive ? 'primary' : 'default'}
                  size='large'
                  sx={{
                    px: '20px',
                    border: 1,
                    borderColor: isEventsActive ? 'primary.main' : 'divider',
                    borderRadius: 1,
                    display: { xs: isEventsActive ? 'none' : 'flex', sm: 'flex' },
                  }}
                >
                  <WhatsAppIcon fontSize='large' />
                  <Typography
                    sx={{
                      marginLeft: '10px',
                      display: { xs: 'none', sm: 'block' },
                    }}
                  >
                    Chats y Eventos
                  </Typography>
                  <Typography
                    sx={{
                      marginLeft: '10px',
                      display: { xs: 'block', sm: 'none' },
                    }}
                  >
                    Chats
                  </Typography>
                </IconButton>
              </Box>
            }
            title={
              isEventsActive ? (
                <Box
                  sx={{
                    display: { xs: 'flex', sm: 'block' },
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <IconButton
                    onClick={handleToggleActive}
                    sx={{ display: { xs: 'flex', sm: 'none' } }}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                  Chats y Eventos
                </Box>
              ) : (
                'Datos Personales'
              )
            }
          />

          <CardContent
            sx={{
              height: isEventsActive ? 'calc(100% - 64px)' : 'auto',
              padding: isEventsActive ? 0 : undefined,
              flex: isEventsActive ? 1 : 'auto',
              overflow: isEventsActive ? 'hidden' : 'visible',
            }}
          >
            {isEventsActive ? (
              <Box
                sx={{
                  display: 'flex',
                  height: '100%',
                  flexDirection: { xs: 'column', sm: 'row' },
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <WorkflowEvents
                    nombreCandidato={nombre}
                    phone_number={telefono}
                    zerosAdded={zerosAdded}
                  />
                </Box>
              </Box>
            ) : (
              <Box>
                <Box
                  sx={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem', gap: 2 }}
                >
                  <TextField
                    label='Nombre'
                    value={nombre}
                    name='nombre'
                    id='nombre'
                    placeholder='Nombre'
                    key='nombre'
                    sx={{ width: '100%', margin: 'auto' }}
                    onChange={handleNombre}
                    disabled={phone ? !editPhone : false}
                  />
                  {isNewCandidate && (
                    <Box style={{ zIndex: 99 }}>
                      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Button onClick={() => nameList(nombre)} variant='contained'>
                          Verificar
                        </Button>
                        <Select
                          labelId='demo-simple-select-autowidth-label'
                          id='demo-simple-select-autowidth'
                          value={nameSelected}
                          onChange={handleChangeName}
                          autoWidth
                          label='Nombres'
                        >
                          {listadoNombres &&
                            listadoNombres.map((n: string) => (
                              <MenuItem id={n} value={n}>
                                {n}
                              </MenuItem>
                            ))}
                        </Select>
                      </Box>
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{ display: 'flex', justifyContent: 'center', gap: 1, marginBottom: '1rem' }}
                >
                  <Tooltip title={tagged ? 'Telefono duplicado' : ''}>
                    <DivGlowEffect
                      className={tagged ? 'glow' : ''}
                      color='0 0 10px 2px rgba(255, 0, 0, 0.7)'
                      style={{ width: '100%' }}
                    >
                      <MuiTelInput
                        sx={{
                          width: '100%',
                        }}
                        error={!validPhone}
                        label='Teléfono'
                        variant='outlined'
                        disabled={phone ? !editPhone : false}
                        value={telefono}
                        onChange={(e: any) => {
                          setTelefono(e);
                        }}
                        onBlur={(e: any) => {
                          onBlurFono(e);
                        }}
                        onClick={() => {
                          setTagged(false);
                        }}
                      />
                    </DivGlowEffect>
                  </Tooltip>
                  <Button
                    variant='outlined'
                    startIcon={<WhatsAppIcon />}
                    onClick={() => setOpenContactModal(true)}
                    sx={{ minWidth: 180 }}
                    disabled={!Boolean(telefono) || !validPhone}
                  >
                    Contactar
                  </Button>
                  <ContactModal
                    open={openContactModal}
                    onClose={() => setOpenContactModal(false)}
                    name={nombre}
                    phone={telefono.replace(/[^0-9]+/g, '')}
                  />
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <TextField
                    label='Email'
                    value={email}
                    placeholder='Email'
                    key='email'
                    sx={{ width: '100%' }}
                    onChange={handleEmail}
                    disabled={phone ? !editPhone : false}
                  />
                </Box>
                {isExistingCandidate && (
                  <Box sx={{ display: 'flex', justifyContent: 'center', margin: '1rem' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={editPhone}
                          onChange={() => setEditPhone(!editPhone)}
                          value='Editar'
                        />
                      }
                      label='Editar'
                    />
                  </Box>
                )}
                <FormInputFile {...{ file, setFile, setProfileUrl, profileUrl }} />
              </Box>
            )}
          </CardContent>
        </Card>

        <Card>
          <CardHeader title='Workflow' />
          <CardContent>
            <WorkflowComponent
              dataCandidate={template}
              setTemplateFather={setTemplate}
              setEstadoActualTemplate={setEstadoActualTemplate}
            />
          </CardContent>
        </Card>

        <Card>
          <CardHeader title='Solicitudes' />
          <CardContent>
            <RequestComponent
              estado={true}
              solicitudCandidate={solicitudCandidate}
              setSolicitudCandidate={setSolicitudCandidate}
            />
          </CardContent>
        </Card>

        <Card>
          <CardHeader title='Datos básicos' />
          <CardContent>
            <PersonalInfoComp
              {...{ onChangeData }}
              solicitudCandidate={solicitudCandidate}
              dataCandidate={template}
              celular={phoneCharacters(telefono)}
              setEmail={setEmail}
              setTelefono={setTelefono}
              setNombre={setNombre}
            />
          </CardContent>
        </Card>

        <Card>
          <CardHeader title='Habilidades' />
          <CardContent>
            <SkillCompMenu
              personalData={template}
              templateData={skillsTemplate}
              options={{ component: 'candidate', isEdit }}
              lightBG={true}
            />
          </CardContent>
        </Card>

        <Card>
          <CardHeader title='Competencias' />
          <CardContent>
            <SoftSkillsComponent dataCandidate={template} />
          </CardContent>
        </Card>

        <Card>
          <CardHeader title='Síntesis diagnóstica' />
          <CardContent>
            <SummaryComponent
              dataCandidate={template}
              onChange={(newValue) => {
                setTemplate((prevTemplate) => {
                  return { ...prevTemplate, footer: newValue };
                });
              }}
            />
          </CardContent>
        </Card>

        <Stack direction='row' gap={2} py={3}>
          <Tooltip arrow title={tooltipSaveShortcutMessage}>
            <Button
              onClick={handleSubmit}
              color='success'
              variant='contained'
              size='large'
              disabled={isSaving}
              startIcon={<SaveIcon />}
              fullWidth
              sx={{ maxWidth: 300 }}
            >
              Guardar
            </Button>
          </Tooltip>
          {isExistingCandidate && (
            <Button
              onClick={handleDeleteCandidateClick}
              color='error'
              variant='outlined'
              size='large'
              disabled={isSaving}
              startIcon={<DeleteIcon />}
            >
              Eliminar
            </Button>
          )}
        </Stack>
      </Stack>
    </Container>
  );
};

export default NewCandidate;
