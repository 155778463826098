import React, { FC, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Radar } from 'react-chartjs-2';
import Tooltip from '../tooltip/Tooltip';
import {
  StyledDetailsRadarContainer,
  StyledDetailsSkillsTitlesSub,
} from 'components/pages/detailsCandidate/skillsComponent/style';
import { innerSkillsInterface, IPreguntas, IRespuestas } from 'components/models/SkillsInterfaces';
import { StyledDiv } from './style';

ChartJS.register(...registerables);

interface Props {
  name: string;
  tipo: string;
  skillsName?: string[];
  scores?: number[];
  years?: number[];
  reference: number;
  skills: innerSkillsInterface[];
  radarGraphCuantity?: number;
}

const FormGraphic: FC<Props> = ({ name, skillsName, scores = [], skills, radarGraphCuantity }) => {
  const truncateLabel = (label: string, maxLength: number) => {
    return label.length > maxLength ? label.substring(0, maxLength) + '...' : label;
  };
  const ref = useRef() as any;
  const maxScore = 10;

  const data = {
    labels: skillsName,
    datasets: [
      {
        label: `${name} (notas)`,
        backgroundColor: 'rgba(119, 131, 244, 0.24)',
        borderColor: 'rgba(119, 131, 244, 0.24)',
        pointBorderColor: '#fff',
        pointBackgroundColor: '#7783F4',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(235, 161, 52, 1)',
        data: scores.map((score: number) => (score > maxScore ? maxScore : score)),
        fill: true,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 20,
        right: 20,
        top: 20,
        bottom: 20,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: (context) => {
          let currentTooltip = document.getElementById('chartjs-tooltip');
          const tooltipEl = document.createElement('div');

          const { dataPoints } = context.tooltip;

          let index = 0;
          if (dataPoints !== undefined && dataPoints.length > 0 && dataPoints.length <= 2) {
            index = dataPoints[0].dataIndex;
          } else if (dataPoints !== undefined && dataPoints.length > 2) {
            index = 100;
          }
          if (dataPoints !== undefined && dataPoints[0].datasetIndex === 1) {
            index = -1;
          }

          const filteredQuestions = (preguntas: IPreguntas[]) => {
            if (Array.isArray(preguntas)) {
              const filtered = preguntas.filter((pregunta: IPreguntas) => {
                if (pregunta.tipo === 'text') {
                  return (
                    pregunta.respuestas.filter(({ valor }: IRespuestas) => valor !== '').length > 0
                  );
                } else if (pregunta.tipo === 'number') {
                  return (
                    pregunta.respuestas.filter(({ valor }: IRespuestas) => valor !== 0).length > 0
                  );
                } else {
                  return (
                    pregunta.respuestas.filter(({ valor }: IRespuestas) => valor !== false).length >
                    0
                  );
                }
              });
              return filtered;
            }
          };

          // Crear el tooltip

          if (index !== -1) {
            if (index === 100) {
              tooltipEl.appendChild(document.createTextNode(name));
            } else {
              const filtered = filteredQuestions(skills[index].preguntas);

              const tooltipContent = <Tooltip skills={skills} index={index} filtered={filtered} />;

              // Utilizar createRoot para renderizar el contenido del tooltip
              const root = createRoot(tooltipEl);
              root.render(tooltipContent);
            }
          }

          //center screen position
          const centerX = window.innerWidth / 2;
          const centerY = window.innerHeight / 2;

          tooltipEl.style.opacity = index === -1 ? '0' : '1';
          tooltipEl.style.position = 'fixed'; // Changed from 'absolute' to 'fixed'

          // Asegurarse de que el tooltip se haya renderizado y tenga un tamaño
          const position = ref.current.canvas.getBoundingClientRect();

          tooltipEl.style.left = `${centerX - position.width / 1.5}px`;
          tooltipEl.style.top = `${centerY - position.height / 2.5}px`;

          tooltipEl.style.fontSize = '1rem';
          tooltipEl.style.padding = '1rem';
          tooltipEl.style.backgroundColor = '#213457';
          tooltipEl.style.borderColor = 'rgba(49, 207, 242, 0.32)';
          tooltipEl.style.border = '1.5px solid rgba(49, 207, 242, 0.32)';
          tooltipEl.style.color = 'white';
          tooltipEl.style.borderRadius = '5px';
          tooltipEl.style.listStyle = 'none';
          tooltipEl.style.pointerEvents = 'none';
          tooltipEl.style.flexWrap = 'nowrap';
          // Hide if no tooltip

          if (currentTooltip) {
            currentTooltip.parentNode?.removeChild(currentTooltip);
            tooltipEl.parentNode?.removeChild(tooltipEl);
          }
          if (context.tooltip.opacity === 0) {
            tooltipEl.style.opacity = '0';
            return;
          }

          // Almacenar una referencia al tooltip actual
          currentTooltip = tooltipEl;

          // Agregar el nuevo tooltip al cuerpo del documento
          tooltipEl.id = 'chartjs-tooltip';
          document.body.appendChild(tooltipEl);
        },
      },
    },
    scales: {
      r: {
        min: 0,
        max: maxScore,
        beginAtZero: true,
        angleLines: {
          display: false,
        },
        ticks: {
          display: false,
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.4)',
        },
        pointLabels: {
          color: '#fff',
          font: {
            size: 14,
          },
          callback: function (value) {
            // Truncar la etiqueta si es necesario
            return truncateLabel(value, 10); // Ajusta el valor 10 según sea necesario
          },
        },
      },
    },
  };
  return (
    <StyledDetailsRadarContainer count={radarGraphCuantity}>
      <StyledDetailsSkillsTitlesSub>{name}</StyledDetailsSkillsTitlesSub>

      <StyledDiv>
        <Radar ref={ref} data={data} options={options} />
      </StyledDiv>
    </StyledDetailsRadarContainer>
  );
};

export default FormGraphic;
