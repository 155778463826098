import { EventSourceMessage, fetchEventSource } from '@microsoft/fetch-event-source';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import getAxios from 'apiRestConnector';
import { useContext, useEffect, useRef } from 'react';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import { useWorkflowEventContext } from '../context/WorkflowEventProvider';
import { WorkflowEvent } from '../types';
import { disableBot, enableBot, fetchBotStatus, sendGreetingTemplate } from './api';

const BACK_END_URL = process.env.REACT_APP_BACKEND_URL;

const fetchWorkflowEvents = async (
  phoneNumber: string,
  accessToken: string
): Promise<WorkflowEvent[]> => {
  const { data } = await getAxios
    .instance(accessToken)
    .get<WorkflowEvent[]>(`/workflow-event/${phoneNumber}`);

  return data;
};

const sendMessage = async (phone_number: string, text: string, accessToken: string) => {
  const { data } = await getAxios
    .instance(accessToken)
    .post(`/whatsapp/messages/send`, { text, phone_number });

  return data;
};

const subscribe = async (
  phoneNumber: string,
  accessToken: string,
  onMessage: (event: WorkflowEvent) => void,
  onError: (error: Error) => void,
  signal: AbortSignal
) => {
  try {
    await fetchEventSource(`${BACK_END_URL}/workflow-event/subscribe/${phoneNumber}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      signal,
      onmessage: (event: EventSourceMessage) => {
        if (event.event === 'message') {
          try {
            const data: WorkflowEvent = JSON.parse(event.data);
            onMessage(data);
          } catch (_) {}
        }
      },
      onopen: async (response) => {
        if (response.ok && response.status === 200) {
          console.log('SSE connection established');
        } else {
          throw new Error(`Failed to connect to SSE: ${response.status}`);
        }
      },
      onerror: (err) => {
        onError(new Error(`Max retries reached for SSE connection: ${err}`));
      },
    });
  } catch (error) {
    onError(error as Error);
  }
};

export const useWorkflowEvents = (phoneNumber: string) => {
  const { storeAuth } = useContext(AuthContext);
  const { messageInput, setMessageInput, clearMessageInput } = useWorkflowEventContext();
  const { access_token } = storeAuth.user;
  const abortControllerRef = useRef<AbortController>();

  const queryClient = useQueryClient();

  const { data, isLoading, error } = useQuery({
     
    queryKey: ['workflow-events', phoneNumber],
    queryFn: () => fetchWorkflowEvents(phoneNumber, access_token),
    staleTime: 30000,
  });

  const { data: botStatus, refetch: refetchBotStatus } = useQuery({
    queryKey: ['bot-status', phoneNumber],
    queryFn: () => fetchBotStatus(phoneNumber),
    staleTime: 30000,
  });

  useEffect(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();

    const handleMessage = (event: WorkflowEvent) => {
      queryClient.setQueryData<WorkflowEvent[]>(
        ['workflow-events', phoneNumber],
        (oldData = []) => [...oldData, event]
      );
    };

    const handleError = (error: Error) => {
      console.error('SSE Connection error:', error);
    };

    subscribe(
      phoneNumber,
      access_token,
      handleMessage,
      handleError,
      abortControllerRef.current.signal
    );

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [phoneNumber, access_token, queryClient]);

  const sendMessageMutation = useMutation({
    mutationFn: () => {
      if (messageInput.trim() === '') return;

      clearMessageInput();

      return sendMessage(phoneNumber, messageInput, access_token);
    },
    onSuccess: () => {
      refetchBotStatus();
    },
  });

  const enableBotMutation = useMutation({
    mutationFn: () => {
      return enableBot(phoneNumber);
    },
    onSuccess: () => {
      refetchBotStatus();
    },
  });

  const disableBotMutation = useMutation({
    mutationFn: () => {
      return disableBot(phoneNumber);
    },
    onSuccess: () => {
      refetchBotStatus();
    },
  });

  const sendGreetingMutation = useMutation({
    mutationFn: (name: string) => {
      return sendGreetingTemplate(phoneNumber, name);
    },
    onSuccess: () => {
      refetchBotStatus();
    },
  });

  return {
    events: data,
    isLoading,
    error,
    phoneNumber,
    messageInput,
    setMessageInput,
    clearMessageInput,
    botEnabled: botStatus?.enabled,
    enableBot: enableBotMutation.mutate,
    disableBot: disableBotMutation.mutate,
    sendMessage: sendMessageMutation.mutate,
    sendGreeting: sendGreetingMutation.mutate,
  };
};
