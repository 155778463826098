import React, { useEffect, useCallback, useState, useRef, useContext } from 'react';
import peer from '../lib/peer';
import { useSocket } from '../../../../context/SocketProvider';
import { Box, SelectChangeEvent, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  container,
  meetingBox,
  muteRemoteWithPulse,
  streamsBox,
  titleMeeting,
  videoBox,
  wrapper,
} from './style';

import StreamPlayer from 'components/ui/streamPlayer/StreamPlayer';
import StreamActions from 'components/ui/streamActions/StreamActions';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import { useHistory, useParams } from 'react-router-dom';
import ROL from 'utils/Roles';
import { UserInterface } from 'components/models/UserInterfaces';
import { Socket } from 'socket.io-client';
import axios from 'axios';
import MicOffIcon from '@mui/icons-material/MicOff';
import RoomInfo from '../RoomInfo/RoomInfo';
import { KIND_OF_DEVICE_NAVIGATOR } from 'utils/KindOfDeviceNavigator.enum';
import SwalAlert from 'components/ui/alerts/SwalAlert';

interface RoomParams {
  idMentee: string;
  typeOfInterview: string;
}

interface User {
  uid: string;
  user: UserInterface;
}

const RoomPage = () => {
  const [isVisibleRoomInfo, setIsVisibleRoomInfo] = useState(true);

  const { idMentee, typeOfInterview } = useParams<RoomParams>();
  const { socket, shouldConnect, setShouldConnect } = useSocket();
  const [myStream, setMyStream] = useState<MediaStream | null>(null);
  const [remoteStream, setRemoteStream] = useState<MediaStream | null>(null);
  const [isCameraLocalOn, setIsCameraLocalOn] = useState(true);
  const [isCameraRemoteOn, setIsCameraRemoteOn] = useState(true);
  const [isMicLocalOn, setIsMicLocalOn] = useState(true);
  const [isMicRemoteOn, setIsMicRemoteOn] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const { storeAuth } = useContext(AuthContext);
  let userActive = storeAuth?.user;
  const [isOnCall, setIsOnCall] = useState(false);

  // const [typeOfUser, setTypeOfUser] = useState<string>('Interviewed');
  const typeOfUser = useRef<string>('Interviewed');
  const isRecording = useRef(false);

  const [room, setRoom] = useState([]);
  const [host, setHost] = useState<User | null>(null);
  const [participant1, setParticipant1] = useState<User | null>(null);

  const [localSocketId, setLocalSocketId] = useState(null);
  const [remoteSocketId, setRemoteSocketId] = useState(null);
  const [audioDevices, setAudioDevices] = useState<{
    input: MediaDeviceInfo[];
    output: MediaDeviceInfo[];
  }>({ input: [], output: [] });
  const [videoDevices, setVideoDevices] = useState<MediaDeviceInfo[]>([]);
  const [audioDeviceSelected, setAudioDeviceSelected] = useState<{
    input: MediaTrackSettings | null;
    output: MediaTrackSettings | null;
  }>({ input: null, output: null });
  const [videoDeviceSelected, setVideoDeviceSelected] = useState<MediaTrackSettings | null>(null);

  const theme = useTheme();

  const toggleMicHandler = useCallback(
    async ({ micState }: { micState: boolean }) => {
      if (remoteStream) {
        remoteStream.getAudioTracks().forEach((track) => {
          track.enabled = micState;
        });
        setIsMicRemoteOn(micState);
      }
    },
    [remoteStream, setIsMicRemoteOn]
  );

  const toggleMic = useCallback(async () => {
    if (myStream) {
      const audioEnabled = myStream.getAudioTracks().every((track) => track.enabled);
      myStream.getAudioTracks().forEach((track) => {
        track.enabled = !audioEnabled;
      });
      setIsMicLocalOn(!audioEnabled);
      socket?.emit('user_toggle_mic_meeting', { to: remoteSocketId, micState: !audioEnabled });
    }
  }, [myStream, socket, remoteSocketId]);

  const toggleCameraHandler = useCallback(
    async ({ cameraState }: { cameraState: boolean }) => {
      if (remoteStream) {
        remoteStream.getVideoTracks().forEach((track) => {
          track.enabled = cameraState;
        });
        setIsCameraRemoteOn(cameraState);
      }
    },
    [remoteStream, setIsCameraRemoteOn]
  );

  const toggleVideo = useCallback(async () => {
    if (myStream) {
      const videoTrackOnStream = myStream
        .getVideoTracks()
        .find((track) => track?.getSettings()?.deviceId === videoDeviceSelected?.deviceId);

      if (!videoTrackOnStream) {
        // Enable camera and add video track to stream
        const cameraSelected = {
          deviceId: { exact: videoDeviceSelected?.deviceId },
        };
        const constraints: MediaStreamConstraints = {
          video: videoDeviceSelected ? cameraSelected : true, // ? True is the default camera if no camera is selected
        };
        const tempStream = await navigator.mediaDevices.getUserMedia(constraints);
        const videoTrackOnTempStream = tempStream.getVideoTracks().at(0);
        myStream.addTrack(videoTrackOnTempStream);
        setIsCameraLocalOn(true);
        socket?.emit('user_toggle_camera_meeting', {
          to: remoteSocketId,
          cameraState: true,
        });
        return;
      }

      // Disable camera and remove video track from stream
      videoTrackOnStream.stop();
      myStream.removeTrack(videoTrackOnStream);
      setIsCameraLocalOn(false);
      socket?.emit('user_toggle_camera_meeting', {
        to: remoteSocketId,
        cameraState: false,
      });
    }
  }, [myStream, socket, remoteSocketId, videoDeviceSelected]);

  const sendStreams = useCallback(() => {
    if (myStream) {
      try {
        const senders = peer.peer.getSenders();
        myStream.getTracks().forEach((track) => {
          if (!senders.some((sender) => sender.track && sender.track.kind === track.kind)) {
            peer.peer.addTrack(track, myStream);
          }
        });
      } catch (error) {
        console.error('Error al enviar las pistas:', error);
      }
    }
  }, [myStream]);

  const handleRoomJoin = useCallback(
    async ({ usersMap }) => {
      const room = Array.from(usersMap[idMentee + '_' + typeOfInterview]);

      const host = room[0] as User;
      const participant = room.length > 1 ? (room[1] as User) : null;

      if (userActive.name === host.user.name) {
        setLocalSocketId(host.uid);
        setHost(host);
        if (participant) {
          setRemoteSocketId(participant.uid);
          setParticipant1(participant);
        }
        // setTypeOfUser('Interviewer');
        typeOfUser.current = 'Interviewer';
      } else if (participant && userActive.name === participant.user.name) {
        setLocalSocketId(participant.uid);
        setParticipant1(participant);
        setHost(host);
        setRemoteSocketId(host.uid);
        // setTypeOfUser('Interviewed');
        typeOfUser.current = 'Interviewed';
      }

      socket.emit('set_host_meeting', { from: socket.id, user: userActive });
      setRoom(room);
    },
    [setHost, socket, idMentee, userActive, typeOfInterview]
  );

  const handleUserJoined = useCallback(
    async ({ id }) => {
      setRemoteSocketId(id);
    },
    [setRemoteSocketId]
  );

  const startRecursiveRecording = useCallback(
    async (streamToSend: MediaStream, socket: Socket) => {
      if (isRecording.current) return;
      isRecording.current = true;
      const mediaRecorder = new MediaRecorder(streamToSend);
      mediaRecorder.ondataavailable = async (event) => {
        if (event.data.size > 0) {
          const blob = event.data;
          const arrayBuffer = await blob.arrayBuffer(); // Convertir Blob a ArrayBuffer
          const uint8Array = new Uint8Array(arrayBuffer);
          const base64Audio = btoa(
            uint8Array.reduce((data, byte) => data + String.fromCharCode(byte), '')
          );
          axios
            .post(
              'https://levelupagentpython.azurewebsites.net/process_audio',
              {
                audio: base64Audio, // Enviar ArrayBuffer
                typeOfUser: typeOfUser.current,
                name: userActive?.name,
                room: idMentee + '_' + typeOfInterview,
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                },
                responseType: 'json',
              }
            )
            .then((response) => {
              console.log('Data recorded and sent');
              console.log('Datos enviados correctamente:', response.data);
            })
            .catch((error) => {
              console.error('Error al enviar los datos:', error);
            });
        }
      };
      mediaRecorder.onstop = () => {
        isRecording.current = false;
        startRecursiveRecording(streamToSend, socket);
      };
      mediaRecorder.start();
      setTimeout(() => {
        mediaRecorder.stop();
      }, 10000);
    },
    [typeOfUser, userActive, idMentee, typeOfInterview]
  );

  const handleCallUser = useCallback(async () => {
    if (shouldConnect) {
      // if(typeOfUser === 'Interviewer'){
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        // ? Requesting audio and video permissions.
        let stream: MediaStream | null = null;
        try {
          stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: true,
          });
        } catch (error) {
          // TODO: Add error handler to support cases where the user denies the permissions...
          stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false,
          });
        } finally {
          await handleDefineAudioDevicesOnUI(stream);
          await handleDefineVideoDevicesOnUI(stream);
          if (stream && peer.peer.connectionState !== 'closed') {
            try {
              setMyStream(stream);
              const offer = await peer.getOffer();
              peer.peer.setLocalDescription(new RTCSessionDescription(offer));

              socket?.emit('user_call_meeting', { to: remoteSocketId, offer });
            } catch (error) {
              console.error('Error al emitir la llamada:', error);
            }
          }
          const streamToSend = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false,
          });
          startRecursiveRecording(streamToSend, socket);
        }
      } else {
        alert('getUserMedia no es soportado en este navegador.');
      }
    }
  }, [socket, remoteSocketId, setMyStream, startRecursiveRecording, shouldConnect]);

  const handleIncomingCall = useCallback(
    async ({ from, offer }) => {
      try {
        setRemoteSocketId(from);

        await peer.peer.setRemoteDescription(new RTCSessionDescription(offer));
        const answer = await peer.peer.createAnswer();
        sendStreams();

        await peer.peer.setLocalDescription(new RTCSessionDescription(answer));

        socket?.emit('call_accepted_meeting', { to: from, ans: answer });
      } catch (error) {
        console.error('Error al manejar la llamada entrante:', error);
      }
    },
    [socket, sendStreams]
  );

  const handleCallAccepted = useCallback(
    async ({ ans }: { from: string; ans: RTCSessionDescriptionInit }) => {
      if (ans && ans.type && ans.sdp) {
        try {
          if (peer.peer.signalingState !== 'stable') {
            await peer.peer.setRemoteDescription(new RTCSessionDescription(ans));
          }
        } catch (error) {
          console.error('Error setting remote description:', error);
        }
      } else {
        console.error('Answer is undefined, or missing type or sdp');
      }
    },
    []
  );

  const handleNegoNeeded = useCallback(async () => {
    try {
      const offer = await peer.getOffer();
      socket?.emit('peer_nego_needed_meeting', { offer, to: remoteSocketId });
    } catch (error) {
      console.error('Error al manejar la llamada entrante:', error);
    }
  }, [remoteSocketId, socket]);

  const handleNegoNeedIncomming = useCallback(
    async ({ from, offer }) => {
      try {
        const ans = await peer.getAnswer(offer);
        socket?.emit('peer_nego_done_meeting', { to: from, ans });
      } catch (error) {
        console.error('Error al manejar la llamada entrante:', error);
      }
    },
    [socket]
  );

  const handleNegoNeedFinal = useCallback(async ({ ans }) => {
    try {
      await peer.setLocalDescription(ans);
    } catch (error) {
      console.error('Error al establecer la descripción local:', error);
    }
  }, []);

  const handleEndCall = useCallback(async () => {
    setIsOnCall(false);
    socket?.emit('end_call_meeting', { to: remoteSocketId, message: 'La reunión ha terminado' });
    if (myStream) {
      myStream.getTracks().forEach((track) => {
        track.stop();
      });
      setMyStream(null);
    }
    if (remoteStream) {
      remoteStream.getTracks().forEach((track) => {
        track.stop();
      });
      setRemoteStream(null);
    }
    if (socket) {
      socket?.disconnect();
    }
    SwalAlert({
      icon: 'info',
      title: 'La reunión ha terminado',
      showConfirmButton: false,
      toast: true,
      position: 'center',
      iconColor: 'white',
      timerProgressBar: true,
      timer: 3000,
    }).then(() => {
      if (userActive.email === undefined) {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          navigator.mediaDevices
            .getUserMedia({ audio: true, video: true })
            .then((stream) => {
              stream.getTracks().forEach((track) => {
                track.stop();
                stream.removeTrack(track);
              });
            })
            .catch((error) => {
              console.error('Error al detener los medios del navegador:', error);
            });
        }

        history.push('/');
        window.location.reload();
      }
      const userRole = userActive?.role?.[0];
      if (userRole === ROL.MENTOR || userRole === ROL.ADMINISTRADOR) {
        history.push(`/mentored/${idMentee}`);
      } else if (userRole === ROL.KAM) {
        history.push(`/kam/${idMentee}`);
      } else {
        history.push('/');
      }

      window.location.reload();
    });
  }, [myStream, remoteStream, socket, remoteSocketId, history, idMentee, userActive]);

  const handleGetUsers = useCallback(
    async ({ from, usersByRoom }) => {
      console.log('usersByRoom ', usersByRoom);
      const localUser = usersByRoom[idMentee + '_' + typeOfInterview][0] as User;
      const remoteUser = usersByRoom[idMentee + '_' + typeOfInterview][1] as User;

      if (userActive.name === localUser.user.name) {
        setHost(localUser);
        if (remoteUser) {
          setParticipant1(remoteUser);
        }
      } else if (remoteUser && userActive.name === remoteUser.user.name) {
        setParticipant1(localUser);
        setHost(remoteUser);
      }
    },
    [setHost, setParticipant1, userActive, idMentee, typeOfInterview]
  );

  const handleChangeAudioDevice = async (event: SelectChangeEvent) => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const audioDeviceId = event.target.value;
      const audioConstraints: MediaStreamConstraints = {
        audio: {
          deviceId: {
            exact: audioDeviceId,
          },
        },
        video: {
          deviceId: {
            exact: videoDeviceSelected.deviceId,
          },
        },
      };

      const stream = await navigator.mediaDevices.getUserMedia(audioConstraints);
      const newAudioTrack = stream.getAudioTracks()[0];

      // Reemplazar la pista de audio en el stream existente
      if (myStream && myStream.getAudioTracks().length > 0) {
        myStream.getAudioTracks()[0].stop(); // Detener la pista de audio actual
        myStream.removeTrack(myStream.getAudioTracks()[0]);
        myStream.addTrack(newAudioTrack);
      }

      // Actualizar la pista en todos los senders de la conexión peer
      peer.peer.getSenders().forEach((sender) => {
        if (sender.track && sender.track.kind === 'audio') {
          sender.replaceTrack(newAudioTrack);
        }
      });

      // Actualizar el estado si es necesario
      setMyStream(myStream);
      await handleDefineAudioDevicesOnUI(stream);
    }
  };

  const handleLoadListOfDevices = useCallback(async () => {
    try {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia && myStream) {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const audioDevicesInputFound = devices.filter(
          (device) => device.kind === KIND_OF_DEVICE_NAVIGATOR.AUDIO_INPUT
        );
        const audioDevicesOutputFound = devices.filter(
          (device) => device.kind === KIND_OF_DEVICE_NAVIGATOR.AUDIO_OUTPUT
        );
        const videoDevicesFound = devices.filter(
          (device) => device.kind === KIND_OF_DEVICE_NAVIGATOR.VIDEO_INPUT
        );
        setAudioDevices({ input: audioDevicesInputFound, output: audioDevicesOutputFound });
        setVideoDevices(videoDevicesFound);
      }
    } catch (error) {
      console.error('Error al enumerar los dispositivos:', error);
    }
  }, [setAudioDevices, setVideoDevices, myStream]);

  const handleDefineAudioDevicesOnUI = async (stream?: MediaStream) => {
    if (!stream) return;
    const audioInputTrack = stream.getAudioTracks().at(0);
    const audioInputTrackSettings = audioInputTrack.getSettings();
    setAudioDeviceSelected({ input: audioInputTrackSettings, output: null });
  };

  const handleDefineVideoDevicesOnUI = async (stream?: MediaStream) => {
    if (!stream) return;
    const videoInputTrack = stream.getVideoTracks().at(0);
    const videoInputTrackSettings = videoInputTrack.getSettings();
    setVideoDeviceSelected(videoInputTrackSettings);
  };

  const handleChangeVideoDevice = async (event: SelectChangeEvent) => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const videoDeviceId = event.target.value;
      const videoConstraints: MediaStreamConstraints = {
        video: { deviceId: videoDeviceId ? { exact: videoDeviceId } : undefined },
      };
      const stream = await navigator.mediaDevices.getUserMedia(videoConstraints);
      setMyStream(stream);
      await handleDefineVideoDevicesOnUI(stream);
    }
  };

  useEffect(() => {
    const participant: User = {
      uid: socket ? socket.id : 'No id',
      user: {
        name: userActive.name ? userActive.name : 'Invitado',
        email: userActive.email ? userActive.email : 'invitado@mail.com',
        _id: userActive._id ? userActive._id : '1234',
        company: userActive.company ? userActive.company : 'No company',
        imageUrl: userActive.imageUrl ? userActive.imageUrl : 'No image',
        role: userActive.role ? userActive.role : [],
        status: userActive.status ? userActive.status : 'No status',
        likeCandidates: userActive.likeCandidates ? userActive.likeCandidates : [],
        access_token: userActive.access_token ? userActive.access_token : 'No access token',
        subscribed: userActive.subscribed ? userActive.subscribed : false,
        menteeId: userActive.menteeId ? userActive.menteeId : 'No mentee id',
      },
    };
    userActive = participant.user;

    const message = {
      room: idMentee.toString() + '_' + typeOfInterview.toString(),
      user: participant.user,
    };
    socket?.emit('room_join_meeting', message);

    // socket?.emit('room_join', { userActive, room: `${idMentee}` });
  }, [socket, idMentee, typeOfInterview, userActive]);

  useEffect(() => {
    if (socket && localSocketId && remoteSocketId) {
      console.log('get_users');
      socket.emit('get_users_meeting', socket.id);
    }
  }, [room, localSocketId, remoteSocketId, socket]);

  useEffect(() => {
    handleCallUser();
  }, [handleCallUser]);

  useEffect(() => {
    setShouldConnect(true);

    return () => setShouldConnect(false);
  }, [setShouldConnect]);

  useEffect(() => {
    peer.peer.addEventListener('track', async (ev) => {
      const remoteStream = ev.streams;
      setRemoteStream(remoteStream[0]);
    });
    return () => {
      peer.peer.removeEventListener('track', async (ev) => {
        const remoteStream = ev.streams;
        setRemoteStream(remoteStream[0]);
      });
    };
  }, []);
  useEffect(() => {
    try {
      if (myStream) {
        sendStreams();
      }
    } catch (error) {
      console.error('Error al enviar las pistas:', error);
    }
  }, [sendStreams, myStream]);

  useEffect(() => {
    if (remoteStream) {
      setIsLoading(false);
    }
  }, [isLoading, remoteStream]);

  useEffect(() => {
    peer.peer.addEventListener('negotiationneeded', handleNegoNeeded);
    return () => {
      peer.peer.removeEventListener('negotiationneeded', handleNegoNeeded);
    };
  }, [handleNegoNeeded]);

  useEffect(() => {
    if (remoteStream && myStream) {
      setIsOnCall(true);
    }
  }, [remoteStream, myStream]);

  useEffect(() => {
    socket?.on('get_users_meeting', handleGetUsers);
    socket?.on('room_join_meeting', handleRoomJoin);
    socket?.on('user_joined_meeting', handleUserJoined);
    socket?.on('incomming_call_meeting', handleIncomingCall);
    socket?.on('call_accepted_meeting', handleCallAccepted);
    socket?.on('peer_nego_needed_meeting', handleNegoNeedIncomming);
    socket?.on('peer_nego_final_meeting', handleNegoNeedFinal);
    socket?.on('end_call_meeting', handleEndCall);
    socket?.on('user_toggle_camera_meeting', toggleCameraHandler);
    socket?.on('user_toggle_mic_meeting', toggleMicHandler);

    return () => {
      socket?.off('get_users_meeting', handleGetUsers);
      socket?.off('room_join_meeting', handleRoomJoin);
      socket?.off('user_joined_meeting', handleUserJoined);
      socket?.off('incomming_call_meeting', handleIncomingCall);
      socket?.off('call_accepted_meeting', handleCallAccepted);
      socket?.off('peer_nego_needed_meeting', handleNegoNeedIncomming);
      socket?.off('peer_nego_final_meeting', handleNegoNeedFinal);
      socket?.off('end_call_meeting', handleEndCall);
      socket?.off('user_toggle_camera_meeting', toggleCameraHandler);
      socket?.off('user_toggle_mic_meeting', toggleMicHandler);
    };
  }, [
    socket,
    remoteStream,
    handleUserJoined,
    handleIncomingCall,
    handleCallAccepted,
    handleNegoNeedIncomming,
    handleNegoNeedFinal,
    handleEndCall,
    toggleCameraHandler,
    toggleMicHandler,
    handleRoomJoin,
    handleGetUsers,
  ]);

  useEffect(() => {
    handleLoadListOfDevices();
  }, [handleLoadListOfDevices]);

  return (
    <>
      {isVisibleRoomInfo && (
        <RoomInfo isVisible={isVisibleRoomInfo} setIsVisible={setIsVisibleRoomInfo} />
      )}
      {!isVisibleRoomInfo && (
        <Box sx={wrapper}>
          <Box sx={container}>
            <Box sx={{ ...meetingBox }}>
              <Box sx={titleMeeting}>
                <Typography variant='h5'>Entrevista Tipo {typeOfInterview}</Typography>
              </Box>
              <Box
                sx={{
                  ...streamsBox,
                  [theme.breakpoints.up('md')]: {
                    flexDirection: 'row',
                  },
                }}
              >
                <Box sx={{ ...videoBox }}>
                  <StreamPlayer
                    stream={myStream}
                    isMicOff={true}
                    isCameraOn={isCameraLocalOn}
                    user={host?.user}
                  />
                  {isOnCall && (
                    <Box
                      sx={{
                        display: isMicLocalOn ? 'none' : 'flex',
                        ...muteRemoteWithPulse,
                      }}
                    >
                      <MicOffIcon />
                    </Box>
                  )}
                </Box>
                <Box sx={{ ...videoBox }}>
                  <StreamPlayer
                    stream={remoteStream}
                    isMicOff={!isMicRemoteOn}
                    isCameraOn={isCameraRemoteOn}
                    user={participant1?.user}
                  />
                  {isOnCall && (
                    <Box
                      sx={{
                        display: isMicRemoteOn ? 'none' : 'flex',
                        ...muteRemoteWithPulse,
                      }}
                    >
                      <MicOffIcon />
                    </Box>
                  )}
                </Box>
              </Box>
              <StreamActions
                isCameraOn={isCameraLocalOn}
                toggleVideo={toggleVideo}
                isMicOn={isMicLocalOn}
                toggleMic={toggleMic}
                handleEndCall={handleEndCall}
                audioDevices={audioDevices}
                videoDevices={videoDevices}
                audioDeviceSelected={audioDeviceSelected}
                handleChangeAudioDevice={handleChangeAudioDevice}
                videoDeviceSelected={videoDeviceSelected}
                handleChangeVideoDevice={handleChangeVideoDevice}
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default RoomPage;
