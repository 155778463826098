import { IPreguntas } from 'components/models/SkillsInterfaces';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(...registerables, ChartDataLabels);

interface BarChartProps {
  preguntas: IPreguntas[];
}

const BarChart: FC<BarChartProps> = React.memo(({ preguntas }) => {
  const [, setDeviceType] = useState('');

  useEffect(() => {
    const checkDeviceType = () => {
      const width = window.innerWidth;

      if (width <= 540) {
        setDeviceType('small');
      } else if (width > 540 && width <= 1024) {
        setDeviceType('medium');
      } else {
        setDeviceType('large');
      }
    };

    checkDeviceType();
    window.addEventListener('resize', checkDeviceType);

    return () => {
      window.removeEventListener('resize', checkDeviceType);
    };
  }, []);

  const getBarData = useCallback((preguntas: IPreguntas): number => {
    // Calcular el puntaje máximo dependiendo del tipo de pregunta
    let puntajeMaximo = 0;
    if (preguntas.tipo === 'checkbox') {
      puntajeMaximo = preguntas.respuestas.reduce(
        (acumulado, respuesta) => acumulado + respuesta.puntaje,
        0
      );
    } else if (preguntas.tipo === 'radio') {
      puntajeMaximo = Math.max(...preguntas.respuestas.map((respuesta) => respuesta.puntaje));
    } else {
      return 0; // Si el tipo de pregunta no es checkbox o radio, retornar 0
    }

    const sumaPuntajes = preguntas.respuestas
      .filter((respuesta) => respuesta.valor === true) // Filtrar solo respuestas con valor=true
      .reduce((acumulado, respuesta) => acumulado + respuesta.puntaje, 0); // Sumar sus puntajes

    const percentage = puntajeMaximo !== 0 ? (sumaPuntajes / puntajeMaximo) * 100 : 0;
    return Math.min(100, Math.max(0, Math.round(percentage)));
  }, []);

  const getBarCustomDataLabel = useCallback((preguntas: IPreguntas): string => {
    const nombres = [];
    preguntas.respuestas.forEach((respuesta) => {
      if (respuesta.valor) {
        nombres.push(respuesta.nombre);
      }
    });
    return nombres.join(', ');
  }, []);

  const getBarColor = useCallback(
    (pregunta: IPreguntas): string => {
      const colorList = [
        'rgba(246, 65, 66, 0.8)',
        'rgba(246, 199, 78, 0.8)',
        'rgba(144, 190, 109, 0.8)',
      ];
      return colorList[getBarData(pregunta) <= 40 ? 0 : getBarData(pregunta) <= 90 ? 1 : 2];
    },
    [getBarData]
  );

  const data = useMemo(
    () => ({
      labels: preguntas.map((pregunta) => pregunta.nombre),
      datasets: [
        {
          data: preguntas.map((pregunta) => getBarData(pregunta)),
          dataLabels: preguntas.map((pregunta) => getBarCustomDataLabel(pregunta)),
          backgroundColor: preguntas.map((pregunta) => getBarColor(pregunta)),
        },
      ],
    }),
    [preguntas, getBarData, getBarCustomDataLabel, getBarColor]
  );

  const options = useMemo(
    () => ({
      plugins: {
        datalabels: {
          color: 'white',
          display: true,
          anchor: 'center' as const,
          align: 'center' as const,
          formatter: (value, context) => {
            return context.dataset.dataLabels[context.dataIndex];
          },
          rotation: 0,
          font: {
            size: 20,
          },
        },
        legend: {
          display: false,
        },
        title: {
          display: false,
          text: 'Chart.js Bar Chart',
          color: 'white',
        },
      },
      scales: {
        y: {
          min: 0,
          max: 100,
          ticks: {
            color: 'white',
            callback: (value: any) => `${value}%`,
          },
        },
        x: {
          ticks: {
            color: 'white',
          },
        },
      },
    }),
    []
  );

  return <Bar data={data} height={400} width={600} options={options} />;
});

export default BarChart;
