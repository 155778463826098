import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar, { AppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Link, { LinkProps } from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import { CSSObject, styled, Theme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import witiIsotipo from 'assets/witi-isotipo.svg';
import React, { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import ROL from 'utils/Roles';
import useLocalStorageState from '../../../hooks/useLocalStorageState';
import { getFilteredMenuLinksByRole } from './menu-links';
import { EventsNotifications } from './Notifications/EventsNotifications';
import Notifications from './Notifications/Notifications';
import UserProfileCard from './UserProfileCard/UserProfileCard';

const drawerWidth = 280;

const drawerStyle = {
  borderRight: '1px solid #303D5C',
  backgroundImage:
    'linear-gradient(320deg, hsl(219deg 32% 9%) 0%, hsl(223deg 41% 15%) 50%, hsl(222deg 49% 22%) 100%)',
};

const listItemButtonStyle = {
  '&:hover': {
    backgroundColor: 'rgba(105,140,210,0.1)',
  },
};

const appBarStyle = {
  backdropFilter: 'blur(10px) saturate(180%)',
  backgroundColor: 'rgba(17, 25, 40, 0.75)',
  backgroundImage: 'none',
  boxShadow: 'none',
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  ...drawerStyle,
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  ...drawerStyle,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface DesktopAppBarProps extends AppBarProps {
  open?: boolean;
}

const DesktopAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<DesktopAppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DesktopDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  })
);

const ListItemTooltip = styled(({ className, children, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {children}
  </Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.background.paper,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    color: theme.palette.secondary.light,
    backgroundColor: theme.palette.background.paper,
  },
}));

type MenuBarProps = {
  children: React.ReactNode;
  role: ROL;
  roles: ROL[];
};

export default function MenuBar({ children, role, roles }: MenuBarProps) {
  const location = useLocation();
  const [desktopOpen, setDesktopOpen] = useLocalStorageState({
    storageKey: 'levelup.desktopMenuBarOpen',
    initialState: true,
  });
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDesktopDrawerOpen = () => {
    setDesktopOpen(true);
  };

  const handleDesktopDrawerClose = () => {
    setDesktopOpen(false);
  };

  const handleMobileDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMobileDrawerClose = () => {
    setMobileOpen(false);
  };

  const menuLinks = getFilteredMenuLinksByRole(role);

  const mobileContent = (
    <>
      <List>
        {menuLinks.map((menuLink) => {
          const isSelected = menuLink.path === location.pathname;
          return (
            <ListItem key={menuLink.path} disablePadding>
              {menuLink.link ? (
                <ListItemButton
                  component='a'
                  key={menuLink.link}
                  id={menuLink.link}
                  href={menuLink.link}
                  target='_blank'
                  rel='noopener noreferrer'
                  sx={listItemButtonStyle}
                >
                  <ListItemIcon sx={{ color: isSelected ? 'primary.main' : 'secondary.main' }}>
                    {menuLink.icon}
                  </ListItemIcon>
                  <ListItemText>
                    <Typography color={isSelected ? 'primary' : 'secondary'}>
                      {menuLink.name}
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              ) : (
                <ListItemButton
                  component={RouterLink}
                  to={menuLink.path}
                  key={menuLink.path}
                  id={menuLink.path}
                  selected={isSelected}
                  onClick={handleMobileDrawerClose}
                  sx={listItemButtonStyle}
                >
                  <ListItemIcon sx={{ color: isSelected ? 'primary.main' : 'secondary.main' }}>
                    {menuLink.icon}
                  </ListItemIcon>
                  <ListItemText>
                    <Typography color={isSelected ? 'primary' : 'secondary'}>
                      {menuLink.name}
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              )}
            </ListItem>
          );
        })}
      </List>
    </>
  );

  const desktopContent = (
    <>
      <List>
        {menuLinks.map((menuLink) => {
          const isSelected = menuLink.path === location.pathname;

          return (
            <ListItem key={menuLink.path} disablePadding sx={{ display: 'block' }}>
              <ListItemTooltip
                title={menuLink.name}
                placement='right'
                disableHoverListener={desktopOpen}
                disableFocusListener={desktopOpen}
                disableInteractive={desktopOpen}
                disableTouchListener={desktopOpen}
                arrow
              >
                {menuLink.link ? (
                  <ListItemButton
                    component='a'
                    href={menuLink.link}
                    target='_blank'
                    rel='noopener noreferrer'
                    sx={{
                      minHeight: 48,
                      justifyContent: desktopOpen ? 'initial' : 'center',
                      px: 2.5,
                      ...listItemButtonStyle,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: desktopOpen ? 3 : 'auto',
                        justifyContent: 'center',
                        color: isSelected ? 'primary.main' : 'secondary.main',
                      }}
                    >
                      {menuLink.icon}
                    </ListItemIcon>
                    <ListItemText sx={{ opacity: desktopOpen ? 1 : 0 }}>
                      <Typography
                        color={isSelected ? 'primary' : 'secondary'}
                        sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                      >
                        {menuLink.name}
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                ) : (
                  <ListItemButton
                    component={RouterLink}
                    to={menuLink.path}
                    selected={isSelected}
                    sx={{
                      minHeight: 48,
                      justifyContent: desktopOpen ? 'initial' : 'center',
                      px: 2.5,
                      ...listItemButtonStyle,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: desktopOpen ? 3 : 'auto',
                        justifyContent: 'center',
                        color: isSelected ? 'primary.main' : 'secondary.main',
                      }}
                    >
                      {menuLink.icon}
                    </ListItemIcon>
                    <ListItemText sx={{ opacity: desktopOpen ? 1 : 0 }}>
                      <Typography
                        color={isSelected ? 'primary' : 'secondary'}
                        sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                      >
                        {menuLink.name}
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                )}
              </ListItemTooltip>
            </ListItem>
          );
        })}
      </List>
    </>
  );

  const LogoLink = (props: LinkProps) => (
    <Link
      component={RouterLink}
      to='/'
      {...props}
      sx={{
        display: 'block',
        px: 1.5,
        py: 1,
        color: 'inherit',
        textDecoration: 'inherit',
        ...props.sx,
      }}
    >
      <Stack direction='row' gap={1} alignItems='center'>
        <Box component='img' src={witiIsotipo} width={30} />
        <Typography variant='h5' fontWeight='bold'>
          LevelUp
        </Typography>
      </Stack>
    </Link>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position='fixed'
        sx={{
          display: { xs: 'block', lg: 'none' },
          width: { lg: `calc(100% - ${drawerWidth}px)` },
          ml: { lg: `${drawerWidth}px` },
          ...appBarStyle,
        }}
      >
        <Toolbar>
          <IconButton
            edge='start'
            onClick={handleMobileDrawerToggle}
            sx={{ mr: 2, display: { lg: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Stack direction='row' gap={1} alignItems='center' ml='auto'>
            <Notifications role={role} />
            <EventsNotifications />
            <UserProfileCard role={role} roles={roles} />
          </Stack>
        </Toolbar>
      </AppBar>
      <DesktopAppBar
        position='fixed'
        open={desktopOpen}
        sx={{
          display: { xs: 'none', lg: 'block' },
          ...appBarStyle,
        }}
      >
        <Toolbar>
          <IconButton
            color='secondary'
            edge='start'
            onClick={handleDesktopDrawerOpen}
            sx={{
              mr: 5,
              ...(desktopOpen && { display: 'none' }),
            }}
          >
            <MenuIcon titleAccess='Expandir menú' />
          </IconButton>
          <LogoLink sx={{ display: desktopOpen ? 'none' : 'block', ml: -2 }} />
          <Stack direction='row' gap={1} alignItems='center' ml='auto'>
            <Notifications role={role} />
            <EventsNotifications />
            <UserProfileCard role={role} roles={roles} />
          </Stack>
        </Toolbar>
      </DesktopAppBar>
      <Drawer
        variant='temporary'
        open={mobileOpen}
        onClose={handleMobileDrawerToggle}
        ModalProps={{
          keepMounted: true, // Mejor rendimiento de apertura en dispositivos móviles.
        }}
        sx={{
          display: { xs: 'block', lg: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, ...drawerStyle },
        }}
      >
        <Toolbar>
          <LogoLink onClick={handleMobileDrawerClose} sx={{ ml: -1.5 }} />
        </Toolbar>
        <Divider />
        {mobileContent}
      </Drawer>
      <DesktopDrawer
        variant='permanent'
        open={desktopOpen}
        sx={{ display: { xs: 'none', lg: 'block' } }}
      >
        <DrawerHeader>
          <LogoLink />
          <IconButton color='secondary' onClick={handleDesktopDrawerClose}>
            <ChevronLeftIcon titleAccess='Encoger menú' />
          </IconButton>
        </DrawerHeader>
        <Divider />
        {desktopContent}
      </DesktopDrawer>
      <Box
        component='main'
        sx={{ flexGrow: 1, width: { xs: '100%', lg: `calc(100% - ${drawerWidth}px)` } }}
      >
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
}
