import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { defaultValues, FormData, RowData, ApiResponse } from './types';
import { fetchCandidates } from '../../../services/candidateFinder/candidateFinder.service';
import { CACHE_TIME } from '../../../helpers/timesValues';
import { flagFunction } from '../../../helpers/flags';

interface CandidateFinderContextProps {
  data: RowData[];
  currentPage: number;
  totalPages: number;
  order: 'asc' | 'desc';
  orderBy: keyof RowData;
  isLoading: boolean;
  error: Error;
  jsonTagsDataSearch: ApiResponse['json_tags_data_search'] | null;
  handleRequest: (payload: FormData) => void;
  handleRequestSort: (property: keyof RowData) => void;
  handlePageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
}

const CandidateFinderContext = createContext<CandidateFinderContextProps | undefined>(undefined);

const itemsPerPage = 5;

export const CandidateFinderProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<keyof RowData | null>(null);
  const [params, setParams] = useState<FormData>(defaultValues);

  const {
    data: apiResponse,
    isLoading,
    error,
  } = useQuery({
    queryKey: ['candidates', params.searchQuery],
    queryFn: fetchCandidates,
    enabled: !!params.searchQuery?.length,
    staleTime: 300000,
    gcTime: CACHE_TIME,
  });

  const jsonTagsDataSearch = useMemo(() => apiResponse?.jsonTagsDataSearch || null, [apiResponse]);

  // Filter candidates based on match and score
  const filteredData = useMemo(() => {
    const candidates = apiResponse?.candidates || [];
    return candidates.filter((candidate) => {
      const matchValue = parseFloat(candidate.match);
      const scoreValue = parseFloat(candidate.score);
      return matchValue >= params.match && scoreValue >= params.score;
    });
  }, [apiResponse, params.match, params.score]);

  const sortedData = useMemo(
    () =>
      [...filteredData].sort((a, b) => {
        if (a[orderBy] < b[orderBy]) {
          return order === 'asc' ? -1 : 1;
        }
        if (a[orderBy] > b[orderBy]) {
          return order === 'asc' ? 1 : -1;
        }
        return 0;
      }),
    [filteredData, order, orderBy]
  );

  const data = useMemo(() => {
    const data = sortedData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return data.map((candidate) => {
      const countryCodeIso2 = flagFunction(candidate.telefono.toString());
      return {
        ...candidate,
        contact: {
          name: candidate.nombre,
          phone: candidate.telefono.toString(),
          countryCode: countryCodeIso2,
        },
      };
    });
  }, [sortedData, currentPage]);

  const totalPages = Math.ceil(sortedData.length / itemsPerPage);

  const handleRequestSort = (property: keyof RowData) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handlePageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const handleRequest = (payload: FormData) => {
    setParams(payload);
    setOrderBy(null);
    setCurrentPage(1);
  };

  return (
    <CandidateFinderContext.Provider
      value={{
        data,
        currentPage,
        totalPages,
        order,
        orderBy,
        isLoading,
        error,
        jsonTagsDataSearch,
        handleRequest,
        handleRequestSort,
        handlePageChange,
      }}
    >
      {children}
    </CandidateFinderContext.Provider>
  );
};

export const useCandidateFinder = () => {
  const context = useContext(CandidateFinderContext);
  if (!context) {
    throw new Error('useCandidateFinder must be used within a CandidateFinderProvider');
  }
  return context;
};
